import * as constants from 'constants';

export const fetchCustomMaps = () => ({
    type: constants.FETCH_CUSTOM_MAPS,
});

export function fetchCustomMapsSuccess(customMaps) {
    return {
        type: constants.FETCH_CUSTOM_MAPS_SUCCESS,
        payload: customMaps,
    };
}

export function fetchCustomMapsFailure(error) {
    return {
        type: constants.FETCH_CUSTOM_MAPS_FAILURE,
        payload: error,
    };
}

export const getCustomMap = (id, isDuplicate) => ({
    type: constants.GET_CUSTOM_MAP,
    id,
    isDuplicate,
});

export function getCustomMapSuccess(customMap) {
    return {
        type: constants.GET_CUSTOM_MAP_SUCCESS,
        customMap,
    };
}

export function getCustomMapFailure(error) {
    return {
        type: constants.GET_CUSTOM_MAP_FAILURE,
        error,
    };
}

export function resetCustomMap() {
    return {
        type: constants.RESET_CUSTOM_MAP,
    };
}

export const saveCustomMap = customMap => ({
    type: constants.SAVE_CUSTOM_MAP,
    customMap,
});

export function saveCustomMapSuccess(customMap) {
    return {
        type: constants.SAVE_CUSTOM_MAP_SUCCESS,
        customMap,
    };
}

export function saveCustomMapFailure(error) {
    return {
        type: constants.SAVE_CUSTOM_MAP_FAILURE,
        payload: error,
    };
}
export const deleteCustomMap = id => ({
    type: constants.DELETE_CUSTOM_MAP,
    id,
});

export function deleteCustomMapSuccess(id) {
    return {
        type: constants.DELETE_CUSTOM_MAP_SUCCESS,
        id,
    };
}

export function deleteCustomMapFailure(error) {
    return {
        type: constants.DELETE_CUSTOM_MAP_FAILURE,
        payload: error,
    };
}

export function removePropertyFromAllMaps(propertyId) {
    return {
        type: constants.REMOVE_PROPERTY_FROM_ALL_MAPS,
        propertyId,
    };
}

export function updateRelevance(relevance, propertyId, showRelevance) {
    return {
        type: constants.UPDATE_RELEVANCE,
        relevance,
        propertyId,
        showRelevance,
    };
}
export function setCustomerComment(comment, propertyId) {
    return {
        type: constants.SET_CUSTOMER_COMMENT,
        comment,
        propertyId,
    };
}
export function requestCustomMapFilter(name, value) {
    return {
        type: constants.CUSTOM_MAP_FILTER_REQUEST,
        name,
        value,
    };
}
export function applyCustomMapFilter(name, value, filtered) {
    return {
        type: constants.CUSTOM_MAP_FILTER_APPLY,
        name,
        value,
        filtered,
    };
}
export function filterCustomMapProperties(filtered) {
    return {
        type: constants.FILTER_CUSTOM_MAP_PROPERTIES,
        filtered,
    };
}
export function resetCustomMapFilter() {
    return {
        type: constants.RESET_CUSTOM_MAP_FILTERS,
    };
}
export function toggleMessagesFullscreen() {
    return {
        type: constants.TOGGLE_MESSAGES_FULLSCREEN,
    };
}
