import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ContactModal from 'components/contactForm/ContactModal';

const StyledWrapper = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
`;
const StyledFloatingButton = styled.div`
    width: 42px;
    height: 42px;
    background: ${({ theme }) => theme.gold};
    color: #fff;
    font-size: 32px;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
    box-shadow: 0px 0px 14px 2px rgb(165 165 165 / 50%);
    cursor: pointer;
    user-select: none;
    transition: all 0.15s ease-in-out;
    transform: rotate(${({ open }) => (open ? 135 : 0)}deg);
`;

const StyledLinksWrapper = styled.div`
    position: relative;
`;
const StyledLink = styled.div`
    background: #ffffff;
    padding: 5px 20px 5px 10px;
    border: 1px soloid #cb9a22;
    border-radius: 25px;
    box-shadow: 0px 0px 14px 2px rgb(0 0 0 / 30%);
    cursor: pointer;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.grey} !important;
    transition: all 0.2s ease-in-out;
    position: absolute;
    white-space: nowrap;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transform: scale(0) translateY(0px);

    &:hover {
        color: ${({ theme }) => theme.grey} !important;
        background: #efefef;
        text-decoration: none !important;
    }
    &:visited {
    }

    & span {
        margin-right: 5px;
        color: ${({ theme }) => theme.gold};
    }

    &:nth-child(1) {
        transform: scale(1) translateY(-${({ show }) => (show ? 145 : 0)}px);
    }

    &:nth-child(2) {
        transition-delay: 50ms;
        transition-property: transform opacity;
        transform: translateY(-${({ show }) => (show ? 95 : 0)}px);
    }

    &:nth-child(3) {
        transition-delay: 100ms;
        transition-property: transform opacity;
        transform: translateY(-${({ show }) => (show ? 45 : 0)}px);
    }
`;

export const FloatingButton = () => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpen(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <StyledWrapper ref={ref}>
            <StyledLinksWrapper onClick={() => setOpen(false)}>
                <StyledLink
                    show={open}
                    as="a"
                    href="/welcome"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="material-icons-outlined">handshake</span>
                    Welcome
                </StyledLink>
                <StyledLink
                    show={open}
                    as="a"
                    href="https://forms.ejrealty.net/JerusalemRealty/form/PropertyRequiredFormDisattachedForm/formperma/CWHu6AxUL_qDOqdlLvZ_iqQYEcueceOoS6Hoj-v_tkQ"
                    target="_blank"
                    rel="noopener noreferrer">
                    <span className="material-icons-outlined">description</span>
                    Property Required Form
                </StyledLink>
                <ContactModal
                    trigger={
                        <StyledLink show={open}>
                            <span className="material-icons-outlined">
                                mail
                            </span>
                            Contact us
                        </StyledLink>
                    }
                />
                {/* <StyledLink show={open}>
                    <span className="material-icons-outlined">whatsapp</span>
                    Whatsapp
                </StyledLink> */}
            </StyledLinksWrapper>
            <StyledFloatingButton onClick={() => setOpen(!open)} open={open}>
                +
            </StyledFloatingButton>
        </StyledWrapper>
    );
};
