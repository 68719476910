import { combineReducers } from 'redux';
// import { routeParamsReducer }   'connected-react-router-params'
import { connectRouter } from 'connected-react-router';
import users from './users';
import property from './property';
import properties from './properties';
import building from './building';
import buildings from './buildings';
import customer from './customer';
import customers from './customers';
import item from './item';
import items from './items';
import neighborhoods from './neighborhoods';
import neighborhood from './neighborhood';
import places from './places';
import place from './place';
import articles from './articles';
import article from './article';
import articleTags from './articleTags';
import testimonial from './testimonial';
import testimonials from './testimonials';
import visibilityFilter from './visibilityFilter';
import currencies from './currencies';
import featured from './featured';
import app from './app';
import map from './map';
import customMap from './customMap';
import customMaps from './customMaps';
import rsa from './rsa';
import rsas from './rsas';
import qa from './qa';
import { history } from '../history';

const reducers = () =>
    combineReducers({
        router: connectRouter(history),
        app,
        map,
        users,
        property,
        properties,
        building,
        buildings,
        customer,
        customers,
        item,
        items,
        neighborhood,
        neighborhoods,
        article,
        articles,
        articleTags,
        place,
        places,
        testimonials,
        testimonial,
        visibilityFilter,
        featured,
        currencies,
        customMap,
        customMaps,
        rsa,
        rsas,
        qa,
    });

export default reducers;
