import * as constants from 'constants';

const properties = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_PROPERTIES_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_PROPERTY_SUCCESS:
            const properties = newState.map(p =>
                p.id === action.property.id ? action.property : p
            );

            return properties;

        case constants.DELETE_PROPERTY_SUCCESS:
            return newState.filter(p => p.id !== action.id);

        default:
            return newState;
    }
};

export default properties;
