import { setMapButtonsState } from 'actions';
import { BUTTONS_STATE } from 'constants';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapContext from '../MapContext';
import Header from './header';
import Route from './route';

let directionsDisplay = null;

const DistanceCalculator = props => {
    const { mapsApi } = useContext(MapContext);
    const [open, setOpen] = useState(true);
    const [minimize, setMinimize] = useState(false);
    const { origin, destination } = useSelector(state => state.map);

    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            if (directionsDisplay) directionsDisplay.setMap(null);
        };
    }, []);

    useEffect(() => {
        if (!directionsDisplay && mapsApi)
            directionsDisplay = new mapsApi.DirectionsRenderer();
        if ((origin || destination) && !open) {
            setOpen(true);
        }
    }, [mapsApi, origin, destination]);

    const handleClose = () => {
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
    };

    return (
        <>
            <Header
                open={open}
                setOpen={setOpen}
                minimize={minimize}
                setMinimize={setMinimize}
                directionsDisplay={directionsDisplay}
            />
            {!minimize ? (
                <Route {...props} directionsDisplay={directionsDisplay} />
            ) : undefined}
        </>
    );
};

export default DistanceCalculator;
