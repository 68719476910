import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import { hot } from 'react-hot-loader/root';
// import { setConfig } from 'react-hot-loader';
import React, { Suspense } from 'react';
// import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './store';
import { history } from './store/history';
import { ConnectedRouter } from 'connected-react-router';
import ScrollTop from 'elements/ScrollTop';
import { ThemeProvider } from 'styled-components';
import HttpsRedirect from 'react-https-redirect';

export const theme = {
    bodyText: '#393939',
    primary: '#CB9A22',
    secondary: '#19A99D',
    teal: '#19A99D',
    gold: '#CB9A22',
    grey: '#707070',
    lightGrey: '#AAA390',
    beige: '#F7F4EC',
    red: '#CD404A',
    cane: '#ebeae7',
    offWhite: '#fcfbf8',
    offWhite2: '#f7f6f2',
    titlesColor: '#666666',
};

const store = configureStore();

const AppWrapper = () => (
    <Suspense fallback={<div>Loading....</div>}>
        <HttpsRedirect>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <ScrollTop />
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </ConnectedRouter>
            </Provider>
        </HttpsRedirect>
    </Suspense>
);

// const root = createRoot(document.getElementById('root'));
// root.render(<AppWrapper />);
ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
