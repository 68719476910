import {
    all,
    select,
    takeLatest,
    fork,
    take,
    put,
    call,
} from 'redux-saga/effects';
import Axios from 'axios';
import {
    ROOT_URL,
    FETCH_PROPERTIES,
    FETCH_PROPERTY,
    SAVE_PROPERTY,
    DELETE_PROPERTY,
    UPDATE_FEATURED_PROPERTY,
    SET_VISIBILITY_FILTER,
} from 'constants';

import {
    fetchPropertiesSuccess,
    fetchPropertiesFailure,
    fetchPropertySuccess,
    fetchPropertyFailure,
    savePropertySuccess,
    savePropertyFailure,
    deletePropertySuccess,
    deletePropertyFailure,
    updateFeaturedPropertySuccess,
    updateFeaturedPropertyFailure,
    setBusy,
    setIdle,
} from 'actions';
import { history } from '../history';
import { getFilteredObjects } from './filter';
import { getFeatured } from './featured';
import { FETCHING_PROPERTIES } from '../../constants';
import { push } from 'connected-react-router';

/***********************************
 * Get properties
 **********************************/

function* takeOneAndBlock(pattern, worker, ...args) {
    const task = yield fork(function* () {
        while (true) {
            const action = yield take(pattern);
            yield call(worker, ...args, action);
        }
    });
    return task;
}

function* getProperties() {
    const current = yield select(state => state.properties);
    const { fetchingProperties } = yield select(state => state.app);
    const { query, pathname } = yield select(state => state.router.location);
    // if (!fetchingProperties) {
    if (current.length) {
        yield put(fetchPropertiesSuccess(current));
        yield put({
            type: SET_VISIBILITY_FILTER,
            filtered: yield getFilteredObjects(query),
        });
    } else {
        yield put({
            type: FETCHING_PROPERTIES,
            fetching: true,
        });
        try {
            const response = yield Axios.get(`${ROOT_URL}/properties`);

            yield put(fetchPropertiesSuccess(response.data));
            yield put({
                type: FETCHING_PROPERTIES,
                fetching: false,
            });
            yield put({
                type: SET_VISIBILITY_FILTER,
                filtered: yield getFilteredObjects(query),
            });
        } catch (e) {
            console.log(e);
            yield put(fetchPropertiesFailure(e));
        }
    }
    // }
}

/***********************************
 * Get one property
 **********************************/
function* getProperty({ id, isDuplicate }) {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/property/${id}`);
        const fetchedProp = isDuplicate
            ? { ...response.data, id: undefined, propId: undefined }
            : response.data;
        yield put(fetchPropertySuccess(fetchedProp));
    } catch (e) {
        console.log(e);
        yield put(fetchPropertyFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 * Save property
 **********************************/
function* saveProperty({ property }) {
    yield put(setBusy());
    try {
        const response = yield Axios.post(
            `${ROOT_URL}/property/save`,
            property
        );
        yield put(savePropertySuccess(response.data));
        yield call(getProperty, { id: response.data.id });
        yield put(push(`/admin/properties/edit/${response.data.id}`));
        // history.goBack();
    } catch (e) {
        alert(e);
        yield put(savePropertyFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 * Delete property
 **********************************/
function* deleteProperty({ id }) {
    yield put(setBusy());
    try {
        const response = yield Axios.delete(`${ROOT_URL}/property/${id}`);
        yield put(deletePropertySuccess(id));
        yield put(push('/admin/properties/'));
    } catch (e) {
        console.log(e);
        yield put(deletePropertyFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 * Update Featured property
 **********************************/
function* updateFeaturedProperty({ id, location }) {
    yield put(setBusy());
    try {
        const response = yield Axios.post(
            `${ROOT_URL}/featured/${id}/${location}`
        );
        yield put(updateFeaturedPropertySuccess(response.data));
        yield call(getFeatured);
    } catch (e) {
        console.log(e);
        yield put(updateFeaturedPropertyFailure(e));
    }
    yield put(setIdle());
}

export default function* () {
    yield all([
        takeOneAndBlock(FETCH_PROPERTIES, getProperties),
        takeLatest(FETCH_PROPERTY, getProperty),
        takeLatest(SAVE_PROPERTY, saveProperty),
        takeLatest(DELETE_PROPERTY, deleteProperty),
        takeLatest(UPDATE_FEATURED_PROPERTY, updateFeaturedProperty),
    ]);
}
