import * as constants from 'constants';

const customer = (state, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.FETCH_CUSTOMER_SUCCESS:
            newState = action.customer;
            return newState;

        case constants.SAVE_CUSTOMER_SUCCESS:
            newState = action.customer;
        case constants.DELETE_CUSTOMER_SUCCESS:
        case constants.RESET_CUSTOMER:
            newState = {};

        default:
            return state || {};
    }
};

export default customer;
