import React from 'react';
import Select from 'react-select';

function SelectComp(props) {
    const getDropIndicator = () => (
        <i
            className="material-icons-outlined"
            style={{ color: '#AAA390', fontSize: 19, paddingRight: 5 }}>
            keyboard_arrow_down
        </i>
    );

    return (
        <Select
            {...props}
            components={{
                DropdownIndicator: getDropIndicator,
                IndicatorSeparator: undefined,
            }}
        />
    );
}

Select.propTypes = {};

export default SelectComp;
