import React, { useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import { Flex, Box } from 'reflexbox';

export const Wrapper = styled.div`
    position: absolute;
    top: 15px;
    transition: all 250ms linear;
    right: ${({ recenter, messages }) =>
        messages ? 110 : recenter ? 110 : 60}px;

    z-index: 1;
    border-radius: 3px;
    box-shadow: 0px 2px 4px #00000033;
    background: #fff;

    ${({ theme, open }) =>
        open
            ? css`
                  border: 1px solid #d0d0d0;
              `
            : css`
                  background: #fff;
              `}
`;

export const Toggle = styled(Box)`
    border-radius: 50px;
    &:hover {
        background: #e8e8e8;
    }
`;

export const Direction = ({ mode, time, selected, ...rest }) => {
    const themeContext = useContext(ThemeContext);

    return (
        <Flex
            alignItems="center"
            bg={selected ? themeContext.gold : 'transparent'}
            color={selected ? '#FFF' : 'inherit'}
            p="3px 10px"
            style={{ borderRadius: '25px', cursor: 'pointer' }}
            {...rest}>
            <Box
                className="material-icons"
                fontSize="20px"
                mr={1}>{`directions_${mode}`}</Box>
            <Box fontWeight="bold">{time}</Box>
        </Flex>
    );
};
