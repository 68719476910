import React from 'react';
import { Flex, Box } from 'reflexbox';
import { BaseSearchComponent } from '../PlacesSearch';
import { Styles } from './LookupStyles';
import Result from './result';
import { selectDistancePoint, swapDistanceLocations } from 'actions';
import { useDispatch, useSelector } from 'react-redux';

const Route = props => {
    const { directionsDisplay } = props;
    const { origin, destination } = useSelector(state => state.map);
    const dispatch = useDispatch();

    const getLocation = option => {
        let location = null;

        if (!option) return;

        if (option.geometry) {
            //this is a google place
            location = {
                id: option.reference,
                name: option.name,
                point: option.geometry.location,
            };
        }
        if (option.location) {
            //this is place
            location = {
                ...option,
                name: option.title,

                point: {
                    lat: option.location.latitude,
                    lng: option.location.longitude,
                },
            };
        }
        if (option.geoPoint) {
            //this is property
            location = {
                ...option,
                name: option.title,

                point: {
                    lat: option.geoPoint.latitude,
                    lng: option.geoPoint.longitude,
                },
            };
        }
        return location;
    };

    const handleOrigin = option => {
        dispatch(selectDistancePoint('origin', getLocation(option)));
    };

    const handleDestination = option => {
        dispatch(selectDistancePoint('destination', getLocation(option)));
    };

    return (
        <>
            <Flex my={3} px={3} alignItems="center">
                <Flex flexDirection="column" alignItems="center" mt={10}>
                    <Box
                        className="material-icons"
                        fontSize="14px"
                        color="#707070">
                        trip_origin
                    </Box>
                    <Box
                        className="material-icons"
                        fontSize="30px"
                        color="#D0D0D0">
                        more_vert
                    </Box>
                    <Box
                        className="material-icons"
                        fontSize="16px"
                        color="#707070">
                        fmd_good
                    </Box>
                </Flex>
                <Box flexGrow={1}>
                    <Box mb={3}>
                        <BaseSearchComponent
                            styles={Styles}
                            value={origin}
                            placeholder="Choose starting point"
                            onSelect={handleOrigin}
                        />
                    </Box>
                    <Box>
                        <BaseSearchComponent
                            styles={Styles}
                            value={destination}
                            placeholder="Choose destintion"
                            onSelect={handleDestination}
                        />
                    </Box>
                </Box>
                <Box
                    className="material-icons"
                    style={{ cursor: 'pointer' }}
                    onClick={() => dispatch(swapDistanceLocations())}>
                    swap_calls
                </Box>
            </Flex>
            {origin && destination ? (
                <Result directionsDisplay={directionsDisplay} />
            ) : undefined}
        </>
    );
};

export default Route;
