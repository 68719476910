import React, { useState } from 'react';
import RCSlider from 'rc-slider';
import { Box, Flex } from 'reflexbox';
import { Btn } from './Toolbar';
import 'rc-slider/assets/index.css';
import { Tooltip } from 'react-tippy';
import ToolbarButton from './ToolbarButton';

export default function Slider(props) {
    return (
        <Box>
            <Tooltip
                trigger="click"
                arrow
                position="left"
                theme="light"
                interactive
                html={
                    <>
                        <Flex
                            justifyContent={'space-between'}
                            width={200}
                            height={40}
                            padding={3}>
                            <Box>{props.label}</Box>
                            <Box>{props.value}</Box>
                        </Flex>
                        <Box padding={2} width="180px" mx="auto">
                            <RCSlider {...props} />
                        </Box>
                    </>
                }>
                <ToolbarButton>{props.icon}</ToolbarButton>
            </Tooltip>
        </Box>
    );
}
