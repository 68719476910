import React from 'react';
import { useSelector } from 'react-redux';
import Symbols from './Symbols';

const format = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const Price = props => {
    const { amount, currency } = props;

    const globalCurrency = useSelector(
        state => state.currencies.currency || 'USD'
    );
    // const currencies = useSelector(state => state.currencies);

    if (!currency) {
        return <span>{amount}</span>;
    } else {
        const price = window.fx(amount).from(currency).to(globalCurrency);
        const symbol = Symbols[globalCurrency];

        let p = Math.floor(price);

        if (p.toString().slice(-2) == '99') {
            p = Math.ceil(p / 100) * 100;
        }

        return (
            <span className="price-currency">
                {symbol}
                {format(p)}
            </span>
        );
    }
};

export default Price;
