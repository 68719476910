import {
    messagesReply,
    setMapButtonsState,
    toggleMessagesFullscreen,
    markMessagesAsRead,
} from 'actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'reflexbox';
import { BUTTONS_STATE } from 'constants';
import { DynamicHeightInput } from '../../common/DynamicHeightInput';
import MessagesDisplay from './MessagesDisplay';

function MessagesViewOnMap({ height }) {
    const {
        customMap: {
            object: { messages = [] },
            messagesFullscreen,
            ...rest
        },
        users: { user },
    } = useSelector(state => state);

    const ref = React.useRef();

    const dispatch = useDispatch();

    const sendMessage = message => {
        dispatch(messagesReply(message));
    };

    const handleClose = () => {
        if (messagesFullscreen) {
            dispatch(toggleMessagesFullscreen());
        }
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
    };
    const toggleFullScreen = () => {
        dispatch(toggleMessagesFullscreen());
    };

    const unreadCount = messages.filter(m => !m.isRead && m.isOutgoing).length;

    useEffect(() => {
        if (unreadCount > 0 && !user?.isAdmin) {
            dispatch(markMessagesAsRead(true)); //prevent reload
        }
    }, [unreadCount]);

    return (
        <Flex
            flexDirection="column"
            maxWidth={messagesFullscreen ? '100%' : '400px'}
            minWidth={500}
            height={messagesFullscreen ? 'calc(100vh - 90px)' : height}>
            <Flex mb={25} p={20} justifyContent="flex-end">
                <Box
                    fontSize="20px"
                    lineHeight="26px"
                    color="#707070"
                    mr="auto"
                    fontWeight="500">
                    Messages
                </Box>
                <Box
                    className="material-icons"
                    color="#707070"
                    fontSize="20px"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={toggleFullScreen}>
                    open_in_full
                </Box>
                <Box
                    className="material-icons"
                    color="#707070"
                    style={{ cursor: 'pointer' }}
                    onClick={handleClose}>
                    close
                </Box>
            </Flex>
            <Box flexGrow={1} px={20} overflow="auto" ref={ref} minWidth={300}>
                <MessagesDisplay parentRef={ref} />
            </Box>
            <DynamicHeightInput
                placeholder="Reply to Tarek..."
                onSend={sendMessage}
            />
        </Flex>
    );
}

export default MessagesViewOnMap;
