import * as constants from 'constants';

const item = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.SAVE_ITEM_SUCCESS:
            return newState;

        case constants.FETCH_ITEM_SUCCESS:
            newState = action.payload;
            return newState;

        case constants.FETCH_SIMILAR_ITEMS:
            newState.similarItems = action.similarItems;
        default:
            return state || {};
    }
};

export default item;
