export const styles = [
    {
        featureType: 'all',
        elementType: 'all',
        stylers: [
            {
                saturation: '32',
            },
            {
                lightness: '-3',
            },
            {
                visibility: 'on',
            },
            {
                weight: '1.18',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'all',
        stylers: [
            {
                saturation: '-70',
            },
            {
                lightness: '14',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                saturation: '100',
            },
            {
                lightness: '-14',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [
            {
                saturation: '-48',
            },
            {
                lightness: '27',
            },
            {
                gamma: '0.91',
            },
        ],
    },
];
