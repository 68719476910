import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';

const Styled = styled(Flex)`
    background: ${({ selected }) => (selected ? '#5C5C5C' : 'transparent')};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    user-select: none;

    &:hover {
        background: ${({ disabled }) => (disabled ? 'transparent' : '#cccccc')};
    }
    & > div {
        color: ${({ selected }) => (selected ? '#FFFFFF' : '#5C5C5C')};
    }
`;

const ToolbarButton = ({ children, ...props }) => {
    return (
        <Styled {...props}>
            <Box className="material-icons" fontSize={20} width="20px">
                {children}
            </Box>
        </Styled>
    );
};

export default ToolbarButton;
