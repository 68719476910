import * as constants from 'constants';
import { sortBy } from 'lodash';

const initialState = {
    list: [],
    selected: undefined,
    edit: false,
};
const qa = (state = initialState, action) => {
    let newState = state;

    switch (action.type) {
        case constants.FETCH_QAS_SUCCESS:
            newState.list = sortBy(action.qa, 'order');
            //initialy select first by default
            if (!newState.selected) {
                newState.selected = newState.list[0];
            }
            return newState;
        case constants.SAVE_QA_SUCCESS:
            const qa = action.qa;
            const found = newState.list.findIndex(q => q.id === qa.id);
            if (found >= 0) {
                newState.list[found] = qa;
            } else {
                newState.list.push(qa);
            }
            newState.selected = qa;
            newState.edit = false;
            return newState;
        case constants.DELETE_QA_SUCCESS:
            newState.list.filter(q => q.id !== action.id);
            newState.edit = false;
            newState.selected = newState.list[0];
            return newState;
        case constants.ADD_QA:
            newState.selected = action.qa;
            newState.edit = true;
            return newState;
        case constants.EDIT_QA:
            newState.edit = true;
            return newState;

        case constants.CANCEL_EDIT_QA:
            if (!newState.selected.id) {
                newState.selected = newState.list[0];
            }
            newState.edit = false;
            return newState;

        case constants.SELECT_QA:
            newState.selected = action.qa;
            return newState;
        default:
            return newState;
    }
};

export default qa;
