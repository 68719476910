import React, { useState } from 'react';
import { Box, Flex } from 'reflexbox';
import Slider from './Slider';
import { OPTIONS } from './constants';
import { ColorPicker } from './ColorPicker';
import { Btn } from './Toolbar';

const FormatToolbar = props => {
    const { format, onChangeFormat } = props;

    const handleChange = (property, value) => {
        onChangeFormat({ ...format, [property]: value });
    };

    const handleFillChange = (color, opacity) => {
        onChangeFormat({
            ...format,
            [OPTIONS.FILL_COLOR]: color,
            [OPTIONS.FILL_OPACITY]: opacity,
        });
    };

    const handleStrokeColorChange = (color, opacity) => {
        onChangeFormat({
            ...format,
            [OPTIONS.STROKE_COLOR]: color,
            [OPTIONS.STROKE_OPACITY]: opacity,
        });
    };

    return (
        <Flex flexDirection={'column'}>
            <ColorPicker
                color={format[OPTIONS.FILL_COLOR]}
                opacity={format[OPTIONS.FILL_OPACITY]}
                onChange={handleFillChange}
                icon={
                    <Box
                        width="20px"
                        height="20px"
                        bg={format[OPTIONS.FILL_COLOR]}
                        style={{ border: '0.5px solid #ccc' }}
                    />
                }
            />
            <Slider
                title="Fill opacity"
                icon="opacity"
                label="Fill opacity"
                min={0}
                max={1}
                step={0.1}
                value={format[OPTIONS.FILL_OPACITY]}
                onChange={e => handleChange(OPTIONS.FILL_OPACITY, e)}
            />
            <ColorPicker
                color={format[OPTIONS.STROKE_COLOR]}
                opacity={format[OPTIONS.STROKE_OPACITY]}
                onChange={handleStrokeColorChange}
                icon="border_color"
            />
            <Slider
                title="Border width"
                icon="line_weight"
                label="Border width"
                min={1}
                max={10}
                onChange={e => handleChange(OPTIONS.STROKE_WEIGHT, e)}
                value={format[OPTIONS.STROKE_WEIGHT]}
            />
        </Flex>
    );
};

export default FormatToolbar;
