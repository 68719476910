import { all, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, FETCH_ITEMS, FETCH_ITEM } from 'constants';
import {
    fetchItemsSuccess,
    fetchItemsFailure,
    fetchItemSuccess,
    fetchItemFailure,
} from 'actions';

/***********************************
 * Get items
 **********************************/
function* getItems() {
    try {
        const response = yield Axios.get(`${ROOT_URL}/item`);

        yield put(fetchItemsSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchItemsFailure(e));
    }
}

/***********************************
 * Get one item
 **********************************/
function* getItem({ id }) {
    try {
        const response = yield Axios.get(`${ROOT_URL}/item/${id}`);

        yield put(fetchItemSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchItemFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_ITEMS, getItems),
        takeLatest(FETCH_ITEM, getItem),
    ]);
}
