import { all, takeLatest, select, put } from 'redux-saga/effects';
import {
    FETCH_CUSTOMERS,
    FETCH_CUSTOMER,
    SAVE_CUSTOMER,
    DELETE_CUSTOMER,
    ROOT_URL,
} from 'constants';
import {
    fetchCustomersSuccess,
    fetchCustomersFailure,
    fetchCustomerSuccess,
    fetchCustomerFailure,
    saveCustomerSuccess,
    saveCustomerFailure,
    deleteCustomerSuccess,
    deleteCustomerFailure,
    setBusy,
    setIdle,
} from 'actions';
import { sortBy } from 'lodash';
import Axios from 'axios';
import { history } from '../history';
import { push } from 'connected-react-router';

/**
 *
 *
 *  Fetch all testimonials
 *
 *
 */
function* getCustomers() {
    // const current = yield select(state => state.customers);
    // if (current.length) {
    //     yield put(fetchCustomersSuccess(current));
    // } else {
    try {
        const response = yield Axios.get(`${ROOT_URL}/customer`);

        const asc = sortBy(response.data, [t => t.createdDate]);
        const sorted = asc.reverse();

        yield put(fetchCustomersSuccess(sorted));
    } catch (e) {
        console.error(e);
        yield put(fetchCustomersFailure(e));
    }
    // }
}

/**
 *
 *
 *  Fetch one customer
 *
 *
 */
function* getCustomer({ id }) {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/customer/${id}`);
        yield put(fetchCustomerSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchCustomerFailure(e));
    }
    yield put(setIdle());
}

/**
 *
 *
 *  Save customer
 *
 *
 */
function* saveCustomer({ customer }) {
    yield put(setBusy());

    try {
        const response = yield Axios.post(
            `${ROOT_URL}/customer/save`,
            customer
        );
        yield put(saveCustomerSuccess(response.data));
        yield put(push('/admin/customers'));
    } catch (e) {
        console.log(e);
        yield put(saveCustomerFailure(e));
    }
    yield put(setIdle());
}
/**
 *
 *
 *  Delete customer
 *
 *
 */
function* deleteCustomer({ id }) {
    yield put(setBusy());
    try {
        const response = yield Axios.delete(`${ROOT_URL}/customer/${id}`);
        yield put(deleteCustomerSuccess(id));
        history.goBack();
    } catch (e) {
        console.log(e);
        yield put(deleteCustomerFailure(e));
    }
    yield put(setIdle());
}

export default function* () {
    yield all([
        takeLatest(FETCH_CUSTOMERS, getCustomers),
        takeLatest(FETCH_CUSTOMER, getCustomer),
        takeLatest(SAVE_CUSTOMER, saveCustomer),
        takeLatest(DELETE_CUSTOMER, deleteCustomer),
    ]);
}
