import * as constants from '../constants';

export const fetchItems = () => ({
    type: constants.FETCH_ITEMS,
});

export const fetchItemsSuccess = items => ({
    type: constants.FETCH_ITEMS_SUCCESS,
    items: items,
});

export const fetchItemsFailure = error => ({
    type: constants.FETCH_ITEMS_FAILURE,
    payload: error,
});

// export const fetchItem = () => ({
//     type: constants.FETCH_ITEM,
// });

// export const fetchItemSuccess = item => ({
//     type: constants.FETCH_ITEM_SUCCESS,
//     item: item,
// });

// export const fetchItemFailure = error => ({
//     type: constants.FETCH_ITEM_FAILURE,
//     payload: error,
// });

// export function fetchSimilarItems(item) {
//     return (dispatch, getState) => {
//         debugger;
//         let items = getState().items;
//         let similarItems = items.filter(p => {
//             return (p.personEmail = item.personEmail);
//         });

//         return dispatch({
//             type: constants.FETCH_SIMILAR_ITEMS,
//             similarItems: similarItems,
//         });
//     };
// }
