import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toggleMap, setMapMarker, togglePins } from 'actions';
import { Flex } from 'reflexbox';

const Close = styled(Flex)`
    position: absolute;
    top: 10px;
    right: 10px;
    background: #fff;
    height: 41px;
    width: 42px;
    border-radius: 3px;
    z-index: 1;
    font-size: 30px;
    line-height: 30px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.gold};
    box-shadow: 0px 4px 12px #00000033;
    border-radius: 50px;
`;

function MapClose() {
    const { show } = useSelector(state => state.map);

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(setMapMarker());
        dispatch(toggleMap());
        if (!show) {
            dispatch(togglePins());
        }
    };
    return <Close onClick={handleClose}>&times;</Close>;
}

export default MapClose;
