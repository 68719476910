import * as constants from 'constants';
import { browserHistory } from 'react-router';

const buildings = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.FETCH_BUILDINGS_SUCCESS:
            newState = action.buildings;
            return newState;

        case constants.SAVE_BUILDING_SUCCESS:
            return newState;

        default:
            return state || {};
    }
};

export default buildings;
