import * as constants from 'constants';

const items = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.FETCH_ITEMS_SUCCESS:
            newState = action.items;
            return newState;

        case constants.SAVE_ITEM_SUCCESS:
            newState.items = action.items;
            return newState;

        default:
            return state || {};
    }
};

export default items;
