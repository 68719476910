import * as constants from 'constants';

const articles = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_ARTICLES_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_ARTICLE_SUCCESS:
            const article = action.article;
            const found = newState.find(p => p.id === article.id);
            if (found) {
                return newState.map(p => (p.id === article.id ? article : p));
            } else {
                return newState.concat(article);
            }
        case constants.DELETE_ARTICLE_SUCCESS:
            return newState.filter(p => p.id !== action.id);
        default:
            return newState;
    }
};

export default articles;
