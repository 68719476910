import React from 'react';
import { MarkerWrapper, PinTitle } from './StyledComponents';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setInfoWindow } from '../../actions';

const PinImage = styled.img`
    transition: all 250ms linear;
    &:hover {
        width: 64px;
    }
`;

const MapMarker = React.memo(props => {
    const dispatch = useDispatch();

    const { type, object = {}, ...rest } = props;

    const { highlightMarker, marker, infoWindow } = useSelector(
        state => state.map
    );

    const isHighlight =
        highlightMarker?.id === object?.id || marker?.id === object?.id;

    let iconPrefix = type;
    if (type === 'place' && object?.types) {
        iconPrefix = object?.types?.length === 1 ? object?.types[0] : 'place';
    }

    let iconUrl = type
        ? `${process.env.PUBLIC_URL}/images/mapResources/${
              isHighlight && object?.propId ? 'selected' : ''
          }${iconPrefix}_map_icon.png`
        : undefined;

    let pinTitle = '';
    if (object?.propId) {
        pinTitle = `ID ${object?.propId}`;
    } else {
        pinTitle = object?.shortTitle || object?.title;
    }

    const handleClick = e => {
        e.stopPropagation();
        if (type !== 'neighborhood') dispatch(setInfoWindow(object));
    };

    return (
        <MarkerWrapper highlight={isHighlight}>
            <>
                <PinTitle onClick={handleClick}>{pinTitle}</PinTitle>
                <PinImage
                    src={!object.drawing ? iconUrl : ''}
                    width={isHighlight ? 64 : 48}
                    height="auto"
                    style={{
                        position: 'relative',
                        transition: 'all 150ms ease-in-out',
                    }}
                    onClick={handleClick}
                />
            </>
        </MarkerWrapper>
    );
});

export default MapMarker;
