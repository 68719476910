import { all } from 'redux-saga/effects';
import userSagas from './user';
import currenciesSagas from './currencies';
import neighborhoodsSagas from './neighborhoods';
import featuredSagas from './featured';
import propertiesSagas from './properties';
import filterSagas from './filter';
import sortSagas from './sort';
import itemsSagas from './items';
import placesSaga from './places';
import articlesSaga from './articles';
import articleTagsSaga from './articleTags';
import testimonialsSaga from './testimonials';
import customersSaga from './customers';
import customMapsSaga from './customMaps';
import rsa from './rsa';
import qa from './qa';

export default function* rootSaga() {
    yield all([
        userSagas(),
        currenciesSagas(),
        neighborhoodsSagas(),
        featuredSagas(),
        propertiesSagas(),
        filterSagas(),
        sortSagas(),
        itemsSagas(),
        placesSaga(),
        articlesSaga(),
        articleTagsSaga(),
        testimonialsSaga(),
        customersSaga(),
        customMapsSaga(),
        rsa(),
        qa(),
    ]);
}
