import { SWITCH_VIEW, SORT_PROPERTIES } from 'constants';

export const sortProps = (by, direction) => ({
    type: SORT_PROPERTIES,
    by,
    direction,
});

export const switchView = view => ({
    type: SWITCH_VIEW,
    view,
});
