import React, { useState, useEffect } from 'react';
import { MarkerWrapper, TooltipClose } from './StyledComponents';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { Box, Flex } from 'reflexbox';
import Distance from './Distance';

const SearchMarkerContent = ({
    name,
    icon,
    photos,
    geometry,
    location,
    formatted_address,
    place_id,
    onClose,
}) => {
    // const { property } = useSelector(state => state);

    // const markerPoint = geometry?.location || {
    //     lat: location.latitude,
    //     lng: location.longitude,
    // };

    return (
        <Box p={2}>
            <Flex alignItems="center" minWidth={320} my={2}>
                <TooltipClose onClick={onClose}>&times;</TooltipClose>
                <Box width={50} flexShrink={0}>
                    <img src={icon} height={36} />
                </Box>
                <Box fontSize="1.2em">{name}</Box>
            </Flex>
            <Box mb={2}>{formatted_address}</Box>
            <Box mb={2}>
                <a
                    href={`https://www.google.com/maps/place/?q=place_id:${place_id}`}
                    target="_blank">
                    View on google map
                </a>
            </Box>
            {/*property.geoPoint && markerPoint ? (
                <Distance
                    name="this property"
                    from={{
                        lat: property.geoPoint.latitude,
                        lng: property.geoPoint.longitude,
                    }}
                    to={markerPoint}
                />
                ) : undefined*/}
        </Box>
    );
};

function SearchMarker({ map, maps }) {
    const searchMarker = useSelector(state => state.map.marker);
    const [infoWindowInstance, setInfoWindowInstance] = useState(null);

    useEffect(() => {
        if (infoWindowInstance) infoWindowInstance.destroy();
    }, []);

    useEffect(() => {
        if (
            map &&
            searchMarker &&
            (searchMarker.geometry || searchMarker.location)
        ) {
            if (infoWindowInstance) infoWindowInstance.show();
            const point = searchMarker.geometry
                ? searchMarker.geometry.location
                : {
                      lat: searchMarker.location.latitude,
                      lng: searchMarker.location.longitude,
                  };
            map.panTo(point);
        }
    }, [map, searchMarker]);

    if (!searchMarker) return null;

    const close = e => {
        e.stopPropagation();
        if (infoWindowInstance) infoWindowInstance.hide();
    };

    const open = e => {
        e.stopPropagation();
        if (infoWindowInstance) infoWindowInstance.show();
    };

    if (!searchMarker) return null;

    return (
        <>
            <Tippy
                content={
                    <SearchMarkerContent {...searchMarker} onClose={close} />
                }
                interactive={true}
                maxWidth="100%"
                theme="light"
                showOnCreate
                hideOnClick={false}
                onShow={instance => setInfoWindowInstance(instance)}
                trigger="click">
                <MarkerWrapper onClick={open}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/mapResources/pin.png`}
                        width={24}
                        height="auto"
                        style={{ position: 'relative' }}
                    />
                </MarkerWrapper>
            </Tippy>
        </>
    );
}

export default SearchMarker;
