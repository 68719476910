import { all, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { sortBy } from 'lodash';
import {
    ROOT_URL,
    FETCH_NEIGHBORHOODS,
    SAVE_NEIGHBORHOOD,
    GET_NEIGHBORHOOD,
} from 'constants';
import {
    fetchNeighborhoodsSuccess,
    fetchNeighborhoodsFailure,
    saveNeighborhoodSuccess,
    saveNeighborhoodFailure,
    getNeighborhoodSuccess,
    getNeighborhoodFailure,
} from 'actions';

/***********************************
 *
 * Get neighborhoods
 *
 **********************************/
function* getNeighborhoods() {
    const current = yield select(state => state.neighborhoods);
    if (current.length) {
        yield put(fetchNeighborhoodsSuccess(current));
    } else {
        try {
            const response = yield Axios.get(`${ROOT_URL}/neighborhood`);
            const list = sortBy(response.data, 'order');
            yield put(fetchNeighborhoodsSuccess(list));
        } catch (e) {
            console.log(e);
            yield put(fetchNeighborhoodsFailure(e));
        }
    }
}

/***********************************
 *
 * Get neighborhood
 *
 **********************************/
function* getNeighborhood({ id }) {
    try {
        const { neighborhoods } = yield select(state => state);
        let found = neighborhoods.find(n => `${n.id}` === `${id}`);

        if (!found) {
            const response = yield Axios.get(`${ROOT_URL}/neighborhood/${id}`);
            found = response.data;
        }

        yield put(getNeighborhoodSuccess(found));
    } catch (e) {
        console.log(e);
        yield put(getNeighborhoodFailure(e));
    }
}

/***********************************
 *
 * Save neighborhood
 *
 **********************************/
function* saveNeighborhood() {
    try {
        const response = yield Axios.post(`${ROOT_URL}/neighborhood/save`);

        yield put(saveNeighborhoodSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(saveNeighborhoodFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_NEIGHBORHOODS, getNeighborhoods),
        takeLatest(GET_NEIGHBORHOOD, getNeighborhood),
        takeLatest(SAVE_NEIGHBORHOOD, saveNeighborhood),
    ]);
}
