import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import styled from 'styled-components';

const StyledEditor = styled(Editor)`
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 260px;
`;

export const Code = ({ value, onChange }) => {
    return (
        <StyledEditor
            value={value}
            onValueChange={onChange}
            highlight={code => highlight(code, languages.js)}
            padding={10}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
            }}
        />
    );
};

Code.defaultProps = {
    value: '',
};
