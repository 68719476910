import styled from 'styled-components';

export const MarkerWrapper = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    user-select: none;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: ${({ highlight }) => (highlight ? 10 : 0)};
    &:hover {
        z-index: 1;
    }
`;

export const PropertyTitle = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
    display: inline-block;
    max-width: calc(100% - 20px);
    &,
    &:hover,
    &:visited {
        color: #0d6bea;
    }
`;

export const PropertyID = styled.div`
    background: #f4f4f4;
    color: #b5b5b5;
    padding: 3px 10px;
    border-radius: 25px;
    height: 18px;
    font-size: 10px;
    font-weight: 500;
    margin-left: 5px;
    &,
    &:hover {
        text-decoration: none;
    }
`;

export const TooltipClose = styled.div`
    position: absolute;
    right: 10px;
    top: 5px;
    color: #7e7e7e;
    font-size: 26px;
    cursor: pointer;
    &:hover {
        color: #000;
    }
`;

const blur = '1px';
const color = '#fff';

export const PinTitle = styled.div`
    position: absolute;
    z-index: 1;
    left: 50%;
    font-size: 13px;
    font-weight: bold;
    transform: translateX(-50%);
    margin-top: -10px;
    white-space: nowrap;
    text-shadow: 0px 1px ${blur} ${color}, 0px 2px ${blur} ${color},
        0px -1px ${blur} ${color}, 0px -2px ${blur} ${color},
        1px 0px ${blur} ${color}, 1px 1px ${blur} ${color},
        1px 2px ${blur} ${color}, -1px 1px ${blur} ${color},
        1px -1px ${blur} ${color}, -1px -1px ${blur} ${color},
        -1px 0px ${blur} ${color}, 2px 2px ${blur} ${color},
        -2px 2px ${blur} ${color}, 2px -2px ${blur} ${color},
        -2px -2px ${blur} ${color}, -2px 0px ${blur} ${color},
        2px 0px ${blur} ${color}, -1px 2px ${blur} ${color},
        1px -2px ${blur} ${color}, -1px -2px ${blur} ${color},
        2px 1px ${blur} ${color}, -2px 1px ${blur} ${color},
        2px -1px ${blur} ${color}, -2px -1px ${blur} ${color};
`;

export const CalculcateWrapper = styled.div`
    position: absolute;
    background: #f9f6f0;
    border: 1px solid #d9caa4;
    border-radius: 5px;
    padding: 12px 20px;
    top: 10px;
    right: 44px;
    box-shadow: 0px 3px 6px #00000029;
    path {
        fill: #ca9922;
    }
`;

export const FromToButton = styled.div`
    font-size: 11px;
    height: 28px;
    line-height: 16px;
    border: 1px solid #acacac;
    border-radius: 25px;
    padding: 5px 20px;
    white-space: nowrap;
    &:hover {
        background: #fff;
    }
`;

export const Tag = styled.div`
    font-size: 10px;
    font-weight: bold;
    color: #86836f;
    padding: 2px 8px;
    background: #f5efe4;
    border-radius: 20px;
`;
export const RecenterButton = styled.div`
    background: white;
    height: 41px;
    width: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    right: 70px;
    margin-top: 10px;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    cursor: pointer;
    &:hover {
        color: black;
    }
`;
