import {
    TOGGLE_MAP,
    SET_MAP_MARKER,
    SET_MAP_ZOOM,
    SET_MAP_CENTER,
    HIGHLIGHT_MARKER,
    SELECT_DISTANCE_POINT,
    RESET_DISTANCE_CALCULATE,
    SWAP_DISTANCE_LOCATIONS,
    TOGGLE_PINS,
    SET_ROUTE_TYPE,
    MARK_MESSAGES_AS_READ,
    MESSAGES_REPLY,
    SET_MAP_BUTTONS_STATE,
    SET_MAP_FULLSCREEN,
    SET_MAP_INFO_WINDOW,
    SHOW_SEPARATION_LINE,
} from '../constants';

export const toggleMap = () => ({
    type: TOGGLE_MAP,
});

export const setMapZoom = zoom => ({
    type: SET_MAP_ZOOM,
    zoom,
});
export const setMapCenter = center => ({
    type: SET_MAP_CENTER,
    center,
});

export const setMapMarker = marker => ({
    type: SET_MAP_MARKER,
    marker,
});

export const highlightMarker = marker => ({
    type: HIGHLIGHT_MARKER,
    marker,
});

export const selectDistancePoint = (direction, point) => ({
    type: SELECT_DISTANCE_POINT,
    point,
    direction,
});

export const swapDistanceLocations = () => ({
    type: SWAP_DISTANCE_LOCATIONS,
});

export const resetDistanceCalculate = () => ({
    type: RESET_DISTANCE_CALCULATE,
});

export const togglePins = () => ({
    type: TOGGLE_PINS,
});

export const setRouteType = routeType => ({
    type: SET_ROUTE_TYPE,
    routeType,
});

export const markMessagesAsRead = preventReload => ({
    type: MARK_MESSAGES_AS_READ,
    preventReload: preventReload,
});

export const messagesReply = (message, propertyId) => ({
    type: MESSAGES_REPLY,
    message,
    propertyId,
});

export const setMapButtonsState = buttonsState => ({
    type: SET_MAP_BUTTONS_STATE,
    buttonsState,
});

export const setMapFullscreen = fullscreen => ({
    type: SET_MAP_FULLSCREEN,
    fullscreen,
});

export const setInfoWindow = info => ({
    type: SET_MAP_INFO_WINDOW,
    info,
});

export const toggleSeparationLine = () => ({
    type: SHOW_SEPARATION_LINE,
});
