import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { changeCurrency } from 'actions';
import SlideSwitch from '../common/SlideSwitch';

const CurrenciesSwitch = ({ dark, transparent }) => {
    const { currency } = useSelector(state => state.currencies);
    const dispatch = useDispatch();

    const switchCurrency = c => {
        dispatch(changeCurrency(c));
        if (window.location.search.indexOf('price') > 0) {
            dispatch(push(window.location.pathname + '?status=available,soon'));
        }
    };

    const options = ['USD', 'ILS'];
    return (
        <SlideSwitch
            options={options}
            value={currency}
            onChange={switchCurrency}
            dark={dark}
            transparent={transparent}
        />
    );
};

export default CurrenciesSwitch;
