import React from 'react';
import { Flex, Box } from 'reflexbox';
import { trimEnd } from 'lodash';
import RoundedButton from 'components/common/RoundedButton';
import PropAttributes from './PropAttributes';
import {
    PropertyID,
    PropertyTitle,
    TooltipClose,
    Tag,
} from '../StyledComponents';
import { selectDistancePoint, setMapButtonsState } from 'actions';
import { BUTTONS_STATE } from 'constants';
import { useDispatch, useSelector } from 'react-redux';

import { STATUSES, RELEVANCE } from 'components/customMap/constants';
import { Flagged } from 'components/common/Details';
import Relevance from './Relevance';
import PropertyPopupMessages from './PropertyPopupMessages';
import DistanceCalculator from './DistanceCalculator';
import PropertyAttribComments from './PropertyAttribComments';

const PropertyContent = props => {
    const {
        id,
        title,
        masterPhoto,
        photo,
        intro,
        description,
        summary,
        propId,
        site,
        tour,
        geoPoint,
        location,
        highlight,
        onClose,
        isShowing,
        noDistance,
        statuses,
        recommended,
        customerRelevance,
        customerComment,
        note,
        furnitureStatus,
        status, // this is for availability
        ...rest
    } = props;
    const {
        object: {
            messages,
            messagesEnabled,
            hideNotInterested,
            propertiesMeta,
            ...otherObjectProps
        },
    } = useSelector(state => state.customMap);

    const isCustomMap = otherObjectProps?.id != null;

    const propertyMessages = messages?.filter(m => m.property === id);
    const propertyMeta = propertiesMeta?.find(m => m.propertyId === id);

    const [showMessages, setShowMessages] = React.useState(
        propertyMessages?.length
    );

    const dispatch = useDispatch();

    const getDistanceObject = () => ({
        id,
        point: {
            lat: geoPoint.latitude,
            lng: geoPoint.longitude,
        },
        name: title,
        title,
        masterPhoto,
        photo,
        intro,
        description,
        propId,
        site,
        tour,
        ...rest,
    });

    const handleDistanceFrom = e => {
        e.stopPropagation();
        dispatch(selectDistancePoint('origin', getDistanceObject()));
        dispatch(setMapButtonsState(BUTTONS_STATE.DISTANCE));
        onClose(e);
    };

    const handleDistanceTo = e => {
        e.stopPropagation();
        dispatch(selectDistancePoint('destination', getDistanceObject()));
        dispatch(setMapButtonsState(BUTTONS_STATE.DISTANCE));
        onClose(e);
    };

    return (
        <Box
            minWidth={intro ? 600 : '0'}
            minHeight={250}
            style={{ position: 'relative' }}>
            <Flex>
                <Box style={{ position: 'relative' }} flexGrow={1}>
                    <Flex pl={3} pr={4}>
                        <PropertyTitle
                            as="div"
                            style={{
                                marginTop: '16px',
                                color: '#707070',
                                textDecoration: 'none',
                                marginBottom: 13,
                            }}>
                            <Box pr={2} style={{ display: 'inline-block' }}>
                                {title}
                            </Box>
                            <RoundedButton
                                href={`/properties/${id}`}
                                as="a"
                                target="_blank"
                                padding="4px 10px"
                                fontSize="10px">
                                <Flex alignItems="center">
                                    <Box>Visit Property Page</Box>
                                    <Box
                                        className="material-icons"
                                        fontSize="15px"
                                        ml={10}>
                                        arrow_right_alt
                                    </Box>
                                </Flex>
                            </RoundedButton>
                        </PropertyTitle>
                        {propId ? (
                            <Box mt={16} ml="auto" pr={2} flexShrink={0}>
                                <PropertyID>{`ID${propId}`}</PropertyID>
                            </Box>
                        ) : undefined}
                    </Flex>
                    <TooltipClose onClick={onClose}>&times;</TooltipClose>
                    <Box id="into_content" px={3}>
                        <Box style={{ position: 'relative' }}>
                            <Flex
                                mb={13}
                                mt={2}
                                ml={2}
                                style={{ position: 'absolute' }}>
                                {statuses?.length
                                    ? statuses.map((s, i) => (
                                          <Tag key={i}>
                                              {
                                                  STATUSES.find(
                                                      v => v.value === s
                                                  ).label
                                              }
                                          </Tag>
                                      ))
                                    : undefined}
                                {customerRelevance ? (
                                    <Tag>
                                        {
                                            RELEVANCE.find(
                                                v => v.id === customerRelevance
                                            ).title
                                        }
                                    </Tag>
                                ) : undefined}
                            </Flex>
                            {masterPhoto ? (
                                <a href={`/properties/${id}`} target="_blank">
                                    <Box
                                        height={174}
                                        style={{
                                            backgroundImage: `url(${trimEnd(
                                                masterPhoto
                                            )}=s640)`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            borderRadius: `10px 10px 0px 0px`,
                                        }}
                                    />
                                </a>
                            ) : undefined}
                            {isShowing && !noDistance ? (
                                <DistanceCalculator
                                    handleDistanceFrom={handleDistanceFrom}
                                    handleDistanceTo={handleDistanceTo}
                                    image={masterPhoto}
                                />
                            ) : undefined}
                        </Box>
                    </Box>
                    <Box px={3} pb={3} minWidth={250} flexGrow={1}>
                        <Box mb={3}>
                            <PropAttributes
                                {...rest}
                                status={status}
                                furnitureStatus={furnitureStatus}
                            />
                        </Box>
                        {tour ? (
                            <Box>
                                <RoundedButton
                                    href={tour}
                                    as="a"
                                    target="_blank"
                                    style={{
                                        padding: '7px 20px',
                                        margin: '6px 0 12px',
                                    }}>
                                    <Flex alignItems="center">
                                        <Box>Tour the site</Box>
                                        <Box
                                            className="material-icons"
                                            fontSize="15px"
                                            ml={1}>
                                            arrow_right_alt
                                        </Box>
                                    </Flex>
                                </RoundedButton>
                            </Box>
                        ) : undefined}
                        {isCustomMap ? (
                            <PropertyAttribComments
                                {...propertyMeta}
                                furnitureStatus={furnitureStatus}
                                availabilityStatus={status}
                            />
                        ) : undefined}
                        <Flex justifyContent="flex-end" mt={-3} mb={1}>
                            {recommended ? (
                                <Flagged ml={20}>
                                    <Box fontSize="12px" lineHeight="15px">
                                        HIGHLY
                                    </Box>
                                    <Box fontSize="12px" lineHeight="15px">
                                        RECOMMENDED
                                    </Box>
                                </Flagged>
                            ) : undefined}
                        </Flex>
                        {isCustomMap ? (
                            <div></div>
                        ) : (
                            <Flex color="#4A4A4A" mb={3}>
                                <Box
                                    fontSize="12px"
                                    lineHeight="20px"
                                    mt="2px"
                                    dangerouslySetInnerHTML={{
                                        __html: note || summary, //description,
                                    }}
                                />
                            </Flex>
                        )}
                        {isCustomMap ? (
                            <Box mb={2} style={{ position: 'relative' }}>
                                <Relevance
                                    relevance={customerRelevance}
                                    propertyId={id}
                                    hideNotInterested={hideNotInterested}
                                    onClose={onClose}
                                />
                                {messagesEnabled ? (
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            right: 0,
                                            bottom: 0,
                                        }}>
                                        <RoundedButton
                                            small
                                            onClick={() => {
                                                setShowMessages(!showMessages);
                                            }}>
                                            Messages
                                        </RoundedButton>
                                    </Box>
                                ) : undefined}
                            </Box>
                        ) : undefined}
                    </Box>
                </Box>
                {messagesEnabled && propertyMessages?.length ? (
                    <Box
                        style={{
                            position: 'absolute',
                            right: 10,
                            top: showMessages ? 20 : 45,
                            cursor: 'pointer',
                            transition: 'top 250ms ease-in-out',
                        }}>
                        <Box
                            width={20}
                            height={20}
                            fontSize="20px"
                            textAlign="center"
                            bg="#CB9A22"
                            color="#FFF"
                            className="material-icons"
                            style={{ borderRadius: 20 }}
                            onClick={() => {
                                setShowMessages(!showMessages);
                            }}>
                            {`${
                                showMessages
                                    ? 'navigate_before'
                                    : 'navigate_next'
                            }`}
                        </Box>
                    </Box>
                ) : undefined}

                {messagesEnabled ? (
                    <Box
                        style={{
                            borderLeft: showMessages
                                ? '2px solid #E6E6E6'
                                : 'none',
                            transition: 'width 250ms ease-in-out',
                        }}
                        flexShrink={0}
                        width={showMessages ? '270px' : 0}
                        overflow="hidden">
                        <PropertyPopupMessages
                            property={props}
                            show={showMessages}
                        />
                    </Box>
                ) : undefined}
            </Flex>
        </Box>
    );
};

export default PropertyContent;
