import React, { useContext, useEffect } from 'react';
import { Box, Flex } from 'reflexbox';
import { ThemeContext } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
    togglePins,
    resetDistanceCalculate,
    setMapButtonsState,
} from 'actions';
import Distance from './result';
import { Toggle } from './styled';
import { BUTTONS_STATE } from 'constants';

const Header = ({
    open,
    setOpen,
    minimize,
    setMinimize,
    directionsDisplay,
}) => {
    const themeContext = useContext(ThemeContext);
    const { show, origin, destination } = useSelector(state => state.map);
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(resetDistanceCalculate());
            if (!show) dispatch(togglePins());
            if (directionsDisplay && directionsDisplay.map)
                directionsDisplay.setMap(null);
        };
    }, []);

    const openPanel = e => {
        e.stopPropagation();
        setOpen(!open);
        if (!show) dispatch(togglePins());
    };

    const handleClose = e => {
        e.stopPropagation();
        setOpen(!open);
        setMinimize(false);
        dispatch(resetDistanceCalculate());
        dispatch(setMapButtonsState(BUTTONS_STATE.NONE));
        if (directionsDisplay && directionsDisplay.map)
            directionsDisplay.setMap(null);
        if (!show) dispatch(togglePins());
    };

    return (
        <Flex
            alignItems="center"
            style={{ cursor: 'pointer' }}
            padding={minimize ? '11px 15px' : '0'}>
            {!minimize ? (
                <Flex
                    onClick={!open ? openPanel : undefined}
                    padding={open ? '10px 20px' : '11px 15px'}>
                    <Box
                        fontWeight="600"
                        fontSize={open ? '18px' : '13px'}
                        color={'#666666'}>
                        Calculate distance
                    </Box>
                    <Box
                        className="material-icons"
                        ml={3}
                        fontSize={open ? '26px' : '18px'}
                        color={'#666666'}>
                        directions
                    </Box>
                </Flex>
            ) : (
                <Distance
                    minimize={minimize}
                    directionsDisplay={directionsDisplay}
                />
            )}
            {open ? (
                <Flex ml="auto" pr={2}>
                    {origin && destination ? (
                        <Toggle
                            className="material-icons"
                            mr={2}
                            bg="#F0F0F0"
                            onClick={() => setMinimize(!minimize)}>
                            {`expand_${minimize ? 'more' : 'less'}`}
                        </Toggle>
                    ) : undefined}
                    <Box className="material-icons" onClick={handleClose}>
                        close
                    </Box>
                </Flex>
            ) : undefined}
        </Flex>
    );
};

export default Header;
