import axios from 'axios';
import * as constants from '../constants';

export function saveItem(props) {

  const request = axios({
    method: 'post',
    data: props,
    url: `${constants.ROOT_URL}/item/save`,
  });

  return {
    type: constants.SAVE_ITEM,
    payload: request,
  };
}

export function saveItemSuccess(newItem) {
  
  return (dispatch, getState) => {

    return dispatch({
      type: constants.SAVE_ITEM_SUCCESS,
      payload: newItem,
      items: updateItems(getState().items, newItem)
      
    });
    
  }
}


function updateItems(items, newItem){
  let index = items.findIndex(item => {return item.id == newItem.id});
  if(index >= 0){
    items.splice(index, 1, newItem);
  }else{
    items.push(newItem);
  }

  return items;
}
export function saveItemFailure(error) {
  return {
    type: constants.SAVE_ITEM_FAILURE,
    payload: error
  };
}

export function fetchItem(id) {
  
  const request = axios.get(`${constants.ROOT_URL}/item/${id}`);

  return {
    type: constants.FETCH_ITEM,
    payload: request
  };

}

export function fetchItemSuccess(activeItem) {

  return (dispatch, getState) => {
    
    return dispatch({
      type: constants.FETCH_ITEM_SUCCESS,
      payload: activeItem
      
    });
  }
}

// export function fetchSimilarItems(activeItem) {

//   return (dispatch, getState) => {
    
//     if(activeItem.personEmail){
//       let items = getState().items;
//       activeItem.similar = items.length ? items.filter(item => {return item.personEmail == activeItem.personEmail}) : [];
//     }

//     return dispatch({
//       type: constants.FETCH_ITEM_SUCCESS,
//       payload: activeItem
      
//     });
//   }
// }


export function fetchItemFailure(error) {
  return {
    type: constants.FETCH_ITEM_FAILURE,
    payload: error
  };
}

export function deleteItem(id) {
  const request = axios({
    method: 'delete',
    url: `${constants.ROOT_URL}/item/${id}`,
  });
  return {
    type: constants.DELETE_ITEM,
    payload: request
  };
}

export function deleteItemSuccess(deletedItem) {
  return {
    type: constants.DELETE_ITEM_SUCCESS,
    payload: deletedItem
  };
}

export function deleteItemFailure(response) {
  return {
    type: constants.DELETE_ITEM_FAILURE,
    payload: response
  };
}