import { placeTypes } from 'components/places/constants';

import { ReactComponent as PropertyIcon } from './resources/property_icon.svg';
import { ReactComponent as BicycleIcon } from './resources/Bicycle_icon.svg';
import { ReactComponent as NeighborhoodIcon } from './resources/Neighborhood_icon.svg';
import { ReactComponent as TrafficIcon } from './resources/traffic_icon.svg';
import { ReactComponent as TramIcon } from './resources/tram_icon.svg';
import { ReactComponent as TransitIcon } from './resources/Transit_icon.svg';
import { ReactComponent as SchoolIcon } from './resources/schoolIcon.svg';
import { ReactComponent as RestaurantIcon } from './resources/restaurantIcon.svg';
import { ReactComponent as SupermarketIcon } from './resources/supermarketIcon.svg';
import { ReactComponent as ParkIcon } from './resources/parkIcon.svg';
import { ReactComponent as SportIcon } from './resources/sportIcon.svg';
import { ReactComponent as NGOIcon } from './resources/ngoIcon.svg';
import { ReactComponent as CommercialIcon } from './resources/commercialIcon.svg';
import { ReactComponent as EntertainmentIcon } from './resources/entertainmentIcon.svg';
import { allowed } from '../neighborhood/utils';

export const PROPERTY = 'property';
export const NEIGHBORHOOD = 'neighborhood';
export const SURROUNDING = 'surroundings';
export const TRANSIT = 'transit';
export const TRAM = 'tram';
export const TRAFFIC = 'traffic';
export const BICYCLE = 'bicycle';

const PLACES = {
    school: SchoolIcon,
    supermarket: SupermarketIcon,
    restaurant: RestaurantIcon,
    park: ParkIcon,
    sport: SportIcon,
    ngo: NGOIcon,
    commercial: CommercialIcon,
    entertainment: EntertainmentIcon,
};

export const nodes = [
    {
        value: PROPERTY,
        label: 'Properties',
        icon: PropertyIcon,
        link: '/properties/?status=available,soon',
    },
    { value: NEIGHBORHOOD, label: 'Neighborhoods', icon: NeighborhoodIcon },
    {
        value: [TRAM, TRANSIT, TRAFFIC, BICYCLE],
        label: 'Transportation',
        children: [
            { value: TRAM, label: 'Tram Stations', icon: TramIcon },
            { value: TRANSIT, label: 'Transit', icon: TransitIcon },
            { value: TRAFFIC, label: 'Traffic', icon: TrafficIcon },
            { value: BICYCLE, label: 'Bicycle', icon: BicycleIcon },
        ],
    },
    {
        value: Object.keys(placeTypes),
        label: 'Surroundings',
        dark: true,
        children: Object.keys(placeTypes)
            .map(k => ({
                value: k,
                label: placeTypes[k],
                icon: PLACES[k],
            }))
            .filter(p => allowed.includes(p.value)),
    },
];
