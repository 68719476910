import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, FETCH_RSAS, GET_RSA, DELETE_RSA } from 'constants';
import {
    fetchRSAs,
    fetchRSAsSuccess,
    fetchRSAsFailure,
    getRSASuccess,
    getRSAFailure,
    deleteRSASuccess,
    deleteRSAFailure,
    setBusy,
    setIdle,
} from 'actions';
import { push } from 'connected-react-router';

/***********************************
 *
 * Get RSAs
 *
 **********************************/
function* getRSAs() {
    yield put(setBusy());
    const current = yield select(state => state.RSAs);
    if (current?.length) {
        yield put(fetchRSAsSuccess(current));
    } else {
        try {
            const response = yield Axios.get(`${ROOT_URL}/rsa`);

            yield put(fetchRSAsSuccess(response.data));
        } catch (e) {
            yield put(fetchRSAsFailure(e));
        }
    }
    yield put(setIdle());
}

/***********************************
 *
 * Get RSA
 *
 **********************************/
function* getRSA({ id }) {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/rsa/${id}`);

        yield put(getRSASuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(getRSAFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 *
 * Delete RSA
 *
 **********************************/
function* deleteRSA({ id }) {
    try {
        const response = yield Axios.delete(`${ROOT_URL}/rsa/${id}`);

        yield put(deleteRSASuccess(id));
        yield put(push('/admin/rsa'));
    } catch (e) {
        console.log(e);
        yield put(deleteRSAFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_RSAS, getRSAs),
        takeLatest(GET_RSA, getRSA),
        takeLatest(DELETE_RSA, deleteRSA),
    ]);
}
