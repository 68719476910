import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Flex } from 'reflexbox';
import styled, { css } from 'styled-components';

const StyledBtn = styled(Box)`
    background: ${({ inverted }) => (inverted ? '#FFF' : '#cb9a22')};
    display: inline-block;
    color: ${({ inverted, color }) =>
        inverted
            ? color || 'rgb(147, 132, 97) !important'
            : color || '#fff !important'};
    border: ${({ border }) => border || '2px solid transparent'};
    border-color: ${({ inverted, borderColor }) =>
        inverted ? borderColor || 'rgb(147, 132, 97)' : '#fff'};
    padding: ${({ small, padding }) =>
        padding || (small ? '5px 20px' : '15px 40px')};
    font-size: ${({ small, fontSize }) =>
        fontSize || (small ? '12px' : '14px')};
    font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
    border-radius: 100px;
    cursor: pointer;
    margin: ${({ margin }) => margin};
    user-select: none;
    &:hover {
        text-decoration: none;
    }
    ${({ noHover }) =>
        !noHover &&
        css`
            &:hover {
                text-decoration: none;
                ${({ inverted, theme }) =>
                    inverted &&
                    css`
                        background: ${theme.gold};
                        border-color: ${theme.gold};
                        color: #fff !important;
                    `}
        `}

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.6;
            cursor: not-allowed;
        `}
`;

export function RoundedButton({ children, withArrow, ...props }) {
    return (
        <StyledBtn
            as={props.to || props.href ? (props.href ? 'a' : NavLink) : Box}
            {...props}>
            {withArrow ? (
                <Flex alignItems="center">
                    <Box style={{ whiteSpace: 'nowrap' }} mr={1}>
                        {children}
                    </Box>
                    <Box className="material-icons" fontSize="20px">
                        trending_flat
                    </Box>
                </Flex>
            ) : (
                children
            )}
        </StyledBtn>
    );
}

export default RoundedButton;
