import * as constants from 'constants';

const neighborhood = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.GET_NEIGHBORHOOD_SUCCESS:
            newState = action.neighborhood;
            return newState;
        case constants.RESET_NEIGHBORHOOD:
            return {};
        default:
            return newState;
    }
};

export default neighborhood;
