import * as constants from 'constants';

const articleTags = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.GET_ARTICLE_TAGS_SUCCESS:
            newState = action.tags;
            return newState;
        default:
            return newState;
    }
};

export default articleTags;
