import * as constants from 'constants';

const building = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.SAVE_BUILDING_SUCCESS:
            return newState;

        case constants.FETCH_BUILDING_SUCCESS:
            newState = action.payload;
            return newState;

        case constants.FETCH_PROPERTIES_IN_BUILDING:
            newState.propertiesInBuilding = action.propertiesInBuilding;
        default:
            return state || {};
    }
};

export default building;
