import { all, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, FETCH_QAS, DELETE_QA, SAVE_QA } from 'constants';
import {
    fetchQAsSuccess,
    fetchQAsFailure,
    saveQASuccess,
    saveQAFailure,
    deleteQASuccess,
    deleteQAFailure,
    setBusy,
    setIdle,
} from 'actions';
import { push } from 'connected-react-router';
import moment from 'moment';

/***********************************
 *
 * Get QA
 *
 **********************************/
function* getQAs() {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/qa`);
        yield put(fetchQAsSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchQAsFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 *
 * Save QA
 *
 **********************************/
function* saveQA({ qa }) {
    try {
        const response = yield Axios.post(`${ROOT_URL}/qa/save`, qa);

        yield put(saveQASuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(saveQAFailure(e));
    }
}

/***********************************
 *
 * Delete QA
 *
 **********************************/
function* deleteQA({ id }) {
    try {
        const response = yield Axios.delete(`${ROOT_URL}/qa/${id}`);

        yield put(deleteQASuccess({ id }));
    } catch (e) {
        console.log(e);
        yield put(deleteQAFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_QAS, getQAs),
        takeLatest(DELETE_QA, deleteQA),
        takeLatest(SAVE_QA, saveQA),
    ]);
}
