const quality = quality => {
    switch (quality) {
        case 1:
            return 'Reasonable Quality';
        case 2:
            return 'Good Quality';
        case 3:
            return 'Good Quality';
        case 4:
            return 'High Quality';
        case 5:
            return 'Top quality';
    }
};
export default quality;
