import React, { useEffect } from 'react';
import styled from 'styled-components';

let search = undefined;

const StyledInput = styled.input`
    width: 50%;
    height: 32px;
    font-family: 'Open Sans';
    margin: 10px;
    position: absolute;
    z-index: 1;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.grey};
    text-indent: 6px;
`;

function Searchbox({ map, mapsApi, onChange }) {
    const inputRef = React.useRef();

    useEffect(() => {
        if (inputRef && inputRef.current && mapsApi && mapsApi.places) {
            search = new mapsApi.places.SearchBox(inputRef.current);
            if (search) {
                search.addListener('places_changed', onPlacesChanged);
            }
        }
    }, [inputRef, map, mapsApi]);

    const onPlacesChanged = () => {
        const places = search.getPlaces();

        if (places.length === 0 || !places[0].geometry) {
            return;
        }

        onChange({
            latitude: places[0].geometry.location.lat(),
            longitude: places[0].geometry.location.lng(),
        });
    };

    return <StyledInput ref={inputRef} type="text" placeholder="Search..." />;
}

export default React.memo(Searchbox);
