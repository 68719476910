import React, { useState, useEffect } from 'react';
import { RELEVANCE } from 'components/customMap/constants';
import Select from 'elements/Select';
import { Flex, Box } from 'reflexbox';
import { useDispatch } from 'react-redux';
import { updateRelevance } from 'actions';

const SelectStyles = {
    valueContainer: provided => ({
        ...provided,
        padding: '0px 8px',
        height: '30px',
    }),
    control: provided => {
        return {
            ...provided,
            minHeight: 0,
            minWidth: 209,
            boxShadow: null,
            color: '#666',
            cursor: 'pointer',
            borderRadius: 30,
            height: '30px',
        };
    },
    placeholder: provided => ({
        ...provided,
        color: '#848073',
        fontSize: '12px',
        fontFamily: 'Open Sans',
    }),
    noOptionsMessage: provided => ({
        ...provided,
        textAlign: 'left',
    }),
    loadingMessage: provided => ({
        ...provided,
        textAlign: 'left',
    }),
    menu: provided => ({
        ...provided,
        marginLeft: '1px',
        width: 'calc(100% - 2px)',
        borderRadius: 10,
        overflow: 'hidden',
        textAlign: 'left',
    }),
    option: (provided, { isSelected, isFocused }) => {
        return {
            ...provided,
            background: isSelected ? '#FBC92E' : isFocused ? '#ccc' : '#FFF',
            color: isSelected || isFocused ? '#FFF' : 'inherit',
            margin: '2px 7px',
            width: 'auto',
            borderRadius: '5px',
            cursor: 'pointer',
        };
    },
    multiValue: provided => ({
        ...provided,
        backgroundColor: '#FFF',
        borderRadius: `50px`,
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: '#676767',
    }),
    multiValueRemove: provided => ({
        ...provided,
        color: '#ccc',
        cursor: 'pointer',
        ':hover': {
            color: '#333',
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: '5px',
        background: 'none',
    }),
};

function Relevance({ relevance, propertyId, hideNotInterested, onClose }) {
    const [selected, setSelected] = useState(
        relevance
            ? {
                  id: relevance,
                  title: RELEVANCE.find(o => o.id === relevance).title,
              }
            : undefined
    );

    const dispatch = useDispatch();

    const handleChange = opt => {
        setSelected(opt);
    };

    useEffect(() => {
        if (selected && selected.id !== relevance) {
            if (
                selected.id === 'notInteresting' &&
                hideNotInterested === undefined
            ) {
                const showRelevance = window.confirm(
                    'Would you like to automatically hide non-relevant properties in this map?'
                );
                dispatch(
                    updateRelevance(selected.id, propertyId, showRelevance)
                );
                if (showRelevance === true) {
                    onclose();
                }
            } else {
                dispatch(updateRelevance(selected.id, propertyId));
            }
        } else if (!selected && relevance !== undefined) {
            dispatch(updateRelevance(undefined, propertyId));
        }
    }, [selected]);

    return (
        <Flex alignItems="center">
            <Box fontSize="14px" fontWeight="bold" color="#4A4A4A" mr={3}>
                Interest in the Property:
            </Box>
            <Select
                options={RELEVANCE}
                styles={SelectStyles}
                value={selected}
                isClearable
                isSearchable={false}
                onChange={handleChange}
                getOptionLabel={o => o.title}
                getOptionValue={o => o.id}
            />
        </Flex>
    );
}

export default Relevance;
