import * as constants from 'constants';

const rsa = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.GET_RSA_SUCCESS:
            newState = action.rsa;
            return newState;
        case constants.RESET_RSA:
            return {};
        default:
            return newState;
    }
};

export default rsa;
