import * as constants from '../constants';

export const saveQA = qa => ({
    type: constants.SAVE_QA,
    qa,
});

export const selectQA = qa => ({
    type: constants.SELECT_QA,
    qa,
});

export const addQA = qa => ({
    type: constants.ADD_QA,
    qa,
});
export const editQA = (id, edit) => ({
    type: constants.EDIT_QA,
    id,
    isEdit: edit,
});
export const cancelEdit = () => ({
    type: constants.CANCEL_EDIT_QA,
});

export const saveQASuccess = qa => ({
    type: constants.SAVE_QA_SUCCESS,
    qa,
});

export const saveQAFailure = error => ({
    type: constants.SAVE_QA_FAILURE,
    error,
});

export const deleteQA = id => ({
    type: constants.DELETE_QA,
    id,
});

export const deleteQASuccess = id => ({
    type: constants.DELETE_QA_SUCCESS,
    id,
});

export const deleteQAFailure = error => ({
    type: constants.DELETE_QA_FAILURE,
    error,
});

export const fetchQAs = () => ({
    type: constants.FETCH_QAS,
});

export const fetchQAsSuccess = qa => ({
    type: constants.FETCH_QAS_SUCCESS,
    qa,
});

export const fetchQAsFailure = error => ({
    type: constants.FETCH_QAS_FAILURE,
    error,
});
