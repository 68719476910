import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import { CalculcateWrapper, FromToButton } from '../StyledComponents';
// import { ReactComponent as Distance } from 'components/map/icons/calculate_distance_icon.svg';

const Distance = () => (
    <Flex width={38} height={38} alignItems="center" justifyContent="center">
        <img
            src={`${process.env.PUBLIC_URL}/images/mapResources/mapMenu/calculate_distance_icon.png`}
            width={25}
            height="auto"
        />
    </Flex>
);

function DistanceCalculator({ handleDistanceFrom, handleDistanceTo, image }) {
    const [show, setShow] = useState(false);

    return (
        <>
            {show ? (
                <CalculcateWrapper>
                    <Flex
                        alignItems="center"
                        color="#CA9922"
                        fontSize="14px"
                        fontWeight="600"
                        pb={1}>
                        <Distance width={24} style={{ marginRight: 7 }} />
                        Calculate distance
                    </Flex>
                    <Box my={2} textAlign="center">
                        <FromToButton
                            style={{
                                cursor: 'pointer',
                                background: '#FFF',
                            }}
                            onClick={handleDistanceFrom}>
                            <span
                                style={{
                                    color: '#CA9922',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    marginRight: 5,
                                }}>
                                From
                            </span>
                            this location
                        </FromToButton>
                        <Box px={3} py="2px" fontSize="11px">
                            or
                        </Box>
                        <FromToButton
                            style={{ cursor: 'pointer' }}
                            onClick={handleDistanceTo}>
                            <span
                                style={{
                                    color: '#CA9922',
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                    marginRight: 5,
                                }}>
                                To
                            </span>
                            this location
                        </FromToButton>
                    </Box>
                </CalculcateWrapper>
            ) : undefined}
            <Box
                style={{
                    background: '#FFF',
                    borderRadius: '50%',
                    position: 'absolute',
                    right: image ? 10 : 30,
                    top: image ? 10 : -40,
                    cursor: 'pointer',
                    boxShadow: '0px 2px 4px #00000033',
                }}
                onClick={() => {
                    setShow(!show);
                }}>
                <Distance />
            </Box>
        </>
    );
}

export default DistanceCalculator;
