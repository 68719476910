import React from 'react';
import { useSelector } from 'react-redux';

function Loading() {
    const { loading } = useSelector(state => state.app);

    return loading ? (
        <div className="loading">
            <div className="overlay"></div>
            <div className="spinner" />
        </div>
    ) : null;
}

export default Loading;
