import { all, takeLatest, takeEvery, select, put } from 'redux-saga/effects';
import Axios from 'axios';
import {
    ROOT_URL,
    SET_VISIBILITY_FILTER,
    REMOVE_VISIBILITY_FILTER,
    APPEND_FILTER,
    SHOW_ALL_FILTER,
    FETCHING_FILTER_OPTIONS,
    REMOVE_FILTER,
    FILTER,
} from 'constants';
import { filterOptionsFetched } from 'actions';
import { push } from 'connected-react-router';
import pull from 'lodash/pull';
import serialize from 'utils/serialize';
import {
    getRangeOption,
    checkFieldValue,
    checkPriceRange,
    stringToArr,
    checkPublished,
} from 'utils/Filters';
import keys from 'lodash/keys';
import mapKeys from 'lodash/mapKeys';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

let globalCurrency;

export function* getFilteredObjects(params) {
    const {
        router: {
            location: { query, pathname },
        },
        properties,
        items,
        buildings,
        currencies,
    } = yield select(state => state);

    let objects = properties;

    // if (pathname.includes('properties')) objects = properties;
    // if (pathname.includes('buildings')) objects = buildings;
    // if (pathname.includes('items')) objects = items;

    // const params = decodeURIComponent(q);

    if (!params || isEmpty(params)) return objects;

    let filtered = objects;

    if (keys(params).length) {
        filtered = filter(objects, prop => {
            let isMatching = true; //start by assuming it's matching

            mapKeys(params, (val, key) => {
                let match = false;
                const value = decodeURIComponent(val);

                if (key === 'price') {
                    match = checkPriceRange(prop, value, currencies);
                } else if (key === 'published') {
                    match = checkPublished(prop, value);
                } else {
                    match = checkFieldValue(key, prop[key], value);
                }

                if (!match) {
                    isMatching = false;
                }
            });
            return isMatching;
        });
    }
    return filtered;
}

/**
 *
 *
 *  Show all
 *
 *
 */
function* showAll() {
    const url = yield select(state => state.router.location);
    const allProps = yield select(state => state.properties);

    yield put(push(`/properties`));

    yield put({
        type: SET_VISIBILITY_FILTER,
        filtered: allProps,
    });
}

/**
 *
 *
 *  Append Filter
 *
 *
 */
function* appendFilter({ filter, option }) {
    const {
        router: {
            location: { query, pathname },
        },
        properties,
        items,
        buildings,
    } = yield select(state => state);
    let currentFilters = query;
    const targetFilter = currentFilters[filter.id];

    /**
     * EXISTING FILTER
     */

    if (targetFilter) {
        if (filter.isString || filter.isDate || filter.isNumber) {
            if (option) currentFilters[filter.id] = option;

            if (filter.isString && option === '')
                delete currentFilters[filter.id];

            if (
                filter.isNumber &&
                (!option || option === '0' || option === 0)
            ) {
                delete currentFilters[filter.id];
            }
        } else if (filter.isRange) {
            currentFilters = getRangeOption(currentFilters, filter, option);
        } else {
            let arr = stringToArr(targetFilter);
            if (checkFieldValue(filter.id, option.id + '', targetFilter)) {
                //remove the value

                if (arr.length === 1) {
                    delete currentFilters[filter.id];
                } else {
                    currentFilters[filter.id] = pull(
                        arr,
                        option.id + ''
                    ).toString();
                }
            } else {
                //add the value
                arr.push(option.id);
                arr = arr.toString();
                currentFilters[filter.id] = arr;
            }
        }

        /**
         *  NEW FILTER
         */
    } else {
        if (filter.isString || filter.isDate || filter.isNumber) {
            if (option && option !== '') currentFilters[filter.id] = option;
        } else if (filter.isRange) {
            if (option !== '0,0')
                currentFilters = getRangeOption(currentFilters, filter, option);
        } else {
            currentFilters[filter.id] = option.id;
        }
    }
    yield put(push(`${pathname}${serialize(currentFilters)}`));

    yield put({
        type: SET_VISIBILITY_FILTER,
        // filter: currentFilters,
        filtered: yield getFilteredObjects(
            // yield getObjects(),
            currentFilters
        ),
    });
}

function* removeFilter({ filterId, value }) {
    const {
        router: {
            location: { query, pathname },
        },
        properties,
        items,
        buildings,
    } = yield select(state => state);

    let currentFilters = query;

    if (filterId !== 'price' && currentFilters[filterId].indexOf(',') > 0) {
        const opts = decodeURIComponent(currentFilters[filterId]).split(',');
        currentFilters[filterId] = opts.filter(a => a != value.id);
    } else {
        delete currentFilters[filterId];
    }

    yield put(push(`${pathname}${serialize(currentFilters)}`));

    yield put({
        type: SET_VISIBILITY_FILTER,
        filtered: yield getFilteredObjects(currentFilters),
    });
}

function* fetchFilterOptions({ filter, objectType }) {
    const request = yield Axios({
        method: 'get',
        url: `${ROOT_URL}/${filter.id}`,
    });
    yield put(filterOptionsFetched(request.data, filter, objectType));
}

function* doFilter() {
    const { query } = yield select(state => state.router.location);
    yield put({
        type: SET_VISIBILITY_FILTER,
        filtered: yield getFilteredObjects(query),
    });
}

export default function* () {
    yield all([
        takeLatest(APPEND_FILTER, appendFilter),
        takeLatest(REMOVE_FILTER, removeFilter),
        takeLatest(SHOW_ALL_FILTER, showAll),
        takeEvery(FETCHING_FILTER_OPTIONS, fetchFilterOptions),
        takeEvery(FILTER, doFilter),
    ]);
}
