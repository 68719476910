import * as constants from 'constants';

export function getCurrencies() {
    return {
        type: constants.GET_CURRENCIES,
    };
}

export function getCurrenciesSuccess(currencies) {
    window.fx.rates = currencies.data.rates;
    window.fx.base = currencies.data.base;

    return {
        type: constants.GET_CURRENCIES_SUCCESS,
        payload: currencies,
    };
}

export function changeCurrency(currency) {
    return {
        type: constants.CHANGE_CURRENCY,
        payload: currency,
    };
}
