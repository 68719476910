import axios from 'axios';
import * as constants from '../constants';

export function getCurrentUser() {
    return {
        type: constants.CURRENT_USER,
    };
}

export function getCurrentUserSuccess(currentUser) {
    return {
        type: constants.CURRENT_USER_SUCCESS,
        payload: currentUser,
    };
}

export function getCurrentUserFailure(error) {
    return {
        type: constants.CURRENT_USER_FAILURE,
        payload: error,
    };
}
