import * as constants from 'constants';

const rsas = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_RSAS_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_RSA_SUCCESS:
            const rsa = action.rsa;
            const found = newState.find(p => p.id === rsa.id);
            if (found) {
                return newState.map(p => (p.id === rsa.id ? rsa : p));
            } else {
                return newState.concat(rsa);
            }
        case constants.DELETE_RSA_SUCCESS:
            return newState.filter(r => r.id !== action.id);
        default:
            return newState;
    }
};

export default rsas;
