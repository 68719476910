export const ItemsFilters = [
	{
		id: "type",
		label: "Item type",
		options: [
			{id: "cars", title: "Cars"},
			{id: "furniture", title: "Furniture"},
			{id: "houseaccessories", title: "House Accessories"},
			{id: "others", title: "Others"},
		]
	},
	{
		id: "price",
		label: "Price Range",
		isRange: true,
		options: [],
		from: 0,
		to: 0,
	},
]