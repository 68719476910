import {
    GET_CURRENCIES,
    GET_CURRENCIES_SUCCESS,
    CHANGE_CURRENCY,
} from 'constants';

const getCurrencyFromCookie = () => {
    const cookieArr = document.cookie.split(';');
    let cFound = 'USD'; //default
    cookieArr.map(c => {
        const params = c.split('=');

        if (params[0] === 'currency') {
            cFound = params[1];
        }
    });

    return cFound;
};
const defaultCurrency = getCurrencyFromCookie();
const INITIAL_STATE = { currency: defaultCurrency };

export default function (state = INITIAL_STATE, action) {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case GET_CURRENCIES_SUCCESS:
            newState.rates = action.payload.data;
            return newState;
        case CHANGE_CURRENCY:
            newState.currency = action.payload;
            return newState;

        default:
            return state;
    }
}
