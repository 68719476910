import * as constants from '../constants';

export const fetchRSAs = () => ({
    type: constants.FETCH_RSAS,
});

export function fetchRSAsSuccess(places) {
    return {
        type: constants.FETCH_RSAS_SUCCESS,
        payload: places,
    };
}

export function fetchRSAsFailure(error) {
    return {
        type: constants.FETCH_RSAS_FAILURE,
        payload: error,
    };
}

export const getRSA = id => ({
    type: constants.GET_RSA,
    id,
});

export function getRSASuccess(rsa) {
    return {
        type: constants.GET_RSA_SUCCESS,
        rsa,
    };
}

export function getRSAFailure(error) {
    return {
        type: constants.GET_RSA_FAILURE,
        error,
    };
}

export const saveRSA = rsa => ({
    type: constants.SAVE_RSA,
    rsa,
});

export function saveRSASuccess(rsa) {
    return {
        type: constants.SAVE_RSA_SUCCESS,
        rsa,
    };
}

export function saveRSAFailure(error) {
    return {
        type: constants.SAVE_RSA_FAILURE,
        payload: error,
    };
}
export const deleteRSA = id => ({
    type: constants.DELETE_RSA,
    id,
});

export function deleteRSASuccess(id) {
    return {
        type: constants.DELETE_RSA_SUCCESS,
        id,
    };
}

export function deleteRSAFailure(error) {
    return {
        type: constants.DELETE_RSA_FAILURE,
        payload: error,
    };
}

export function resetRSA() {
    return {
        type: constants.RESET_RSA,
    };
}
