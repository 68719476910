import React, { useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { setInfoWindow } from '../../../actions';

moment.tz.setDefault('Asia/Jerusalem');

const MessagesDisplay = React.memo(({ onDelete, property, parentRef }) => {
    const dispatch = useDispatch();

    const {
        customMap: {
            object: { properties, messages, title },
        },
        users: { user },
    } = useSelector(state => state);

    const scrollViewRef = React.useRef();

    useEffect(() => {
        if (parentRef?.current) {
            parentRef.current.scrollTop = parentRef.current.scrollHeight;
        }
    }, [messages?.length]);

    if (!messages) return null;

    let list = messages.map((m, i) => ({ ...m, originIndex: i }));

    if (property) {
        list = list.filter(m => m.property === property.id);
    }

    const handleIDClick = propertyId => {
        const p = properties.find(p => p.id === propertyId);
        dispatch(setInfoWindow(p));
    };
    return (
        <Box style={{ position: 'relative' }}>
            {list.map((message, i) => (
                <Box
                    key={i}
                    pb={2}
                    mb={2}
                    style={{
                        borderBottom:
                            property && list.length > 1 && i !== list.length - 1
                                ? '2px solid #E6E6E6'
                                : undefined,
                    }}>
                    <Flex>
                        <Box mx={2} flexShrink={0}>
                            {message.isOutgoing ? (
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/profile/tarek_image.png`}
                                    width={32}
                                />
                            ) : (
                                <Box
                                    className="material-icons"
                                    color="#CCC"
                                    fontSize="32px">
                                    account_circle
                                </Box>
                            )}
                        </Box>
                        <Box>
                            <Flex
                                color="#9B9B9B"
                                alignItems="center"
                                mb={1}
                                mt={2}>
                                <Box
                                    fontWeight="bold"
                                    fontSize="12px"
                                    color="#5D5D5D">
                                    {message.isOutgoing ? 'Tarek' : title}
                                </Box>
                                <Box px="3px">.</Box>
                                <Flex fontSize="10px" alignItems="center">
                                    {moment
                                        .tz(
                                            message.sent,
                                            'MMM DD, YYYY HH:mm:ss A',
                                            'UTC'
                                        )
                                        .tz('Asia/Jerusalem')
                                        .format('MMM DD, HH:mm')}
                                    {message.isOutgoing && user?.isAdmin ? (
                                        <Box
                                            className="material-icons"
                                            fontSize="18px"
                                            fontWeight="bold"
                                            ml={2}
                                            color={
                                                message.isRead
                                                    ? 'green'
                                                    : '#e9e9e9'
                                            }>
                                            done
                                        </Box>
                                    ) : undefined}
                                </Flex>
                            </Flex>
                            {!property && message.property ? (
                                <Box
                                    mt={-2}
                                    my={1}
                                    bg="#F5F5F5"
                                    fontSize="10px"
                                    color="#707070"
                                    p="3px 5px"
                                    style={{ borderRadius: 20 }}>
                                    This message is related to property{' '}
                                    <Box
                                        as="span"
                                        color="#CB9A22"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() =>
                                            handleIDClick(message.property)
                                        }>
                                        ID
                                        {
                                            properties.find(
                                                p => p.id === message.property
                                            )?.propId
                                        }
                                    </Box>
                                </Box>
                            ) : (
                                <Box height="10px" />
                            )}
                        </Box>
                    </Flex>
                    <Box
                        bg={message.isOutgoing ? '#FCF7EC' : '#EAF8FF'}
                        fontWeight="500"
                        fontSize="12px"
                        p="6px 15px"
                        mr={2}
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            borderRadius: `0px 15px 15px 15px`,
                        }}
                        dangerouslySetInnerHTML={{
                            __html: message.content,
                        }}
                    />
                    {user?.isAdmin && onDelete ? (
                        <Box
                            className="material-icons"
                            fontSize={16}
                            mt="2px"
                            style={{
                                cursor: 'pointer',
                                position: 'absolute',
                                right: 10,
                            }}
                            onClick={() => onDelete(message.originIndex)}>
                            delete
                        </Box>
                    ) : undefined}
                    <div
                        style={{ float: 'left', clear: 'both' }}
                        refX={scrollViewRef}
                    />
                </Box>
            ))}
        </Box>
    );
});

export default MessagesDisplay;
