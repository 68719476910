import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomepageBannerEdit from './pages/HomepageBannerEdit';
import Draw from './elements/map/draw/index';

// const BuildingPage = React.lazy(() => import(/* webpackChunkName: 'BuildingPage' */ './pages/BuildingPage'));
// const BuildingsPage = React.lazy(() => import(/* webpackChunkName: 'BuildingsPage' */ './pages/BuildingsPage'));
// const NotFoundPage = React.lazy(() => import(/* webpackChunkName: 'NotFoundPage' */ './pages/NotFoundPage'));
// const EditBuildingPage = React.lazy(() => import(/*webpackChunkName: 'EditBuildingPage' */ './pages/EditBuildingPage'));
// const AboutusPage = React.lazy(() => import(/* webpackChunkName: 'AboutusPage' */ './pages/AboutusPage'));

const PropertyPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'PropertyPage' */ './components/property/PropertyPageComponent'
        )
);

const NeighborhoodPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'NeighborhoodPage' */ './components/neighborhood'
        )
);
const EditNeighborhood = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'EditNeighborhood' */ './components/neighborhood/new_edit'
        )
);

const NeighborhoodsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'NeighborhoodPage' */ './components/neighborhood/List'
        )
);

const ArticlePage = React.lazy(
    () => import(/* webpackChunkName: 'ArticlePage' */ './components/article')
);
const EditArticlePage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'EditArticle' */ './components/article/new_edit'
        )
);

const ArticlesPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'ArticlesPage' */ './components/article/List'
        )
);

const ArticlesTags = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'ArticlesTags' */ './components/article/ArticleTags'
        )
);

const EditPlace = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'EditPlace' */ './components/places/new_edit'
        )
);

const PlacesPage = React.lazy(
    () =>
        import(/* webpackChunkName: 'PlacesPage' */ './components/places/List')
);

const SearchPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'SearchPage' */ './components/search/SearchPageComponent'
        )
);

const HomePage = React.lazy(
    () => import(/* webpackChunkName: 'HomePage' */ 'components/homepage')
);
const TestimonialsPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'TestimonialsPage' */ './pages/TestimonialsPage'
        )
);
const CustomersPage = React.lazy(
    () =>
        import(
            /* webpackChunkName: 'CustomersPage' */ './components/aboutus/CustomersPage'
        )
);
const AdminPage = React.lazy(
    () => import(/* webpackChunkName: 'AdminPage' */ './pages/AdminPage')
);

const EditPropertyPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'EditPropertyPage' */ './components/property/new_edit/PropertyForm'
        )
);
const EditItemPage = React.lazy(
    () => import(/*webpackChunkName: 'EditItemPage' */ './pages/EditItemPage')
);
const EditTestimonialPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'EditTestimonialPage' */ './components/testimonials/TestimonialForm'
        )
);
const EditCustomerPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'EditCustomerPage' */ './components/customers/CustomerForm'
        )
);

const Featured = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'Featured' */ './components/featured/Featured'
        )
);
const ItemPage = React.lazy(
    () => import(/*webpackChunkName: 'ItemPage' */ './pages/ItemPage')
);
const Newsletter = React.lazy(
    () => import(/*webpackChunkName: 'Newsletter' */ './pages/Newsletter')
);

const EditRSA = React.lazy(
    () => import(/* webpackChunkName: 'EditRSA' */ './components/rsa/new_edit')
);

const RSAsPage = React.lazy(
    () => import(/* webpackChunkName: 'RSAsPage' */ './components/rsa/List')
);

const Auth = import('./utils/Auth');

const IntroPage = React.lazy(
    () => import(/*webpackChunkName: 'Intro' */ './pages/IntroPage')
);
const LandingPage = React.lazy(
    () => import(/*webpackChunkName: 'Landing' */ './pages/LandingPage')
);
const ProfilePage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'Profile' */ 'components/profile/ProfilePage'
        )
);
const WelcomePage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'Welcome' */ 'components/welcome/WelcomePage'
        )
);
const PricingPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'Pricing' */ 'components/aboutus/pricingPolicy'
        )
);
const InternetPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'Internet' */ 'components/aboutus/InternetSubscription'
        )
);
const TrustedByPage = React.lazy(
    () => import(/*webpackChunkName: 'TrustedBy' */ 'components/trustedBy')
);

const CustomMapList = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'customMapList' */ 'components/customMap/List'
        )
);

const CustomMapEdit = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'customMapEdit' */ 'components/customMap/Edit'
        )
);

const CustomMapView = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'customMapView' */ 'components/customMap/View'
        )
);

const StepsPage = React.lazy(
    () => import(/*webpackChunkName: 'steps' */ 'pages/steps')
);

const HowWeWorkPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'howWeWork' */ 'components/howWeWork/HowWeWork'
        )
);

const QAPage = React.lazy(
    () => import(/*webpackChunkName: 'qa' */ 'components/qa/index')
);

const OurServicesPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'ourServicesPage' */ 'pages/ourServices/index'
        )
);
const AboutCustomListingPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'aboutCustomListingPage' */ 'pages/aboutCustomListing/index'
        )
);

const WhyWorkWithUsPage = React.lazy(
    () =>
        import(
            /*webpackChunkName: 'whyWorkWithUs' */ 'pages/whyWorkWithUs/index'
        )
);

const BriefIntroPage = React.lazy(
    () => import(/*webpackChunkName: 'briefIntro' */ 'pages/briefIntro/index')
);

const TrustedByPublicPage = React.lazy(
    () =>
        import(/*webpackChunkName: 'trustedByPublic' */ 'pages/trustedBy/index')
);

export const ROUTE_CONST = {
    HOME: '/home',
    PROFILE: '/profile',
    WELCOME: '/welcome',
    STEPS: '/steps',
    HOW_WE_WORK: '/howWeWork',
    WHY_WORK_WITH_US: '/workwithus',
    BRIEF_INTRO: '/brief-intro',
    TRUSTED_BY: '/trusted-by',
    OUR_SERVICE: '/ourServices',
    ABOUT_CL: '/aboutCustomListing',
    CUSTOM_LISTING_PAGE: '/aboutCustomListing',
    PROPERTIES: '/properties',
    PROPERTY: '/properties/:id',
    NEIGHBORHOODS: '/neighborhoods',
    NEIGHBORHOOD: '/neighborhoods/:id',
    ARTICLES: '/articles',
    QA: '/qa',
    ARTICLE: '/articles/:id',
    TESTIMONIALS: '/testimonials',
    TESTIMONIALS2: '/aboutus/testimonials',
    INTERNET: '/internet',
    INTERNET2: '/aboutus/internet',
    PRICING: '/pricing',
    PRICING2: '/aboutus/pricing',
    ITEMS: '/items',
    ITEM: '/items/:id',
    CUSTOM_MAP: '/CustomListings/:id',
    DUPLICATE_PROP: '/admin/properties/edit/:id/:duplicate',
    EDIT_PROP: '/admin/properties/edit/:id',
    EDIT_ARTICLE: '/admin/articles/edit/:id',
    EDIT_NEIGHBORHOOD: '/admin/neighborhoods/edit/:id',
    EDIT_ITEM: '/admin/items/edit/:id',
    EDIT_TESTIMONIAL: '/admin/testimonials/edit/:id',
    EDIT_CUSTOMER: '/admin/customers/edit/:id',
    EDIT_PLACE: '/admin/places/edit/:id',
    DUPLICATE_PLACE: '/admin/places/edit/:id/:duplicate',
    EDIT_RSA: '/admin/rsa/edit/:id',
    DUPLICATE_CUSTOM_MAP: '/admin/CustomListings/edit/:id/:duplicate',
    EDIT_CUSTOM_MAP: '/admin/CustomListings/edit/:id',
    ADMIN_PROPS: '/admin/properties',
    ADMIN_NEIGHBORHOODS: '/admin/neighborhoods',
    ADMIN_ARTICLES: '/admin/articles',
    ADMIN_ARTICLES_TAGS: '/admin/articlesTags',
    ADMIN_SEARCH: '/admin/search',
    ADMIN_ITEMS: '/admin/items',
    ADMIN_PLACES: '/admin/places',
    ADMIN_RSA: '/admin/rsa',
    ADMIN_TESTIMONIALS: '/admin/testimonials',
    ADMIN_CUSTOMERS: '/admin/customers',
    ADMIN_FEATURED: '/admin/featured',
    ADMIN_SELECT: '/admin/select',
    ADMIN_BANNER: '/admin/homepage-banner',
    ADMIN_TRUSTED_BY: '/admin/trustedBy',
    ADMIN_CUSTOM_MAPS: '/admin/maps',
    ADMIN: '/admin',
    // WHYJR: '/whyjr',
    // LANDING: '/rental_east_jerusalem',
};

const Routes = () => {
    return (
        <Switch>
            <Route path={ROUTE_CONST.HOME} component={HomePage} />

            <Route path={'/draw'} component={Draw} />

            <Route path={ROUTE_CONST.PROFILE} component={ProfilePage} />
            <Route path={ROUTE_CONST.WELCOME} component={WelcomePage} />
            <Route path={ROUTE_CONST.PROPERTY} component={PropertyPage} />
            <Route path={ROUTE_CONST.PROPERTIES} component={SearchPage} />
            <Route path={ROUTE_CONST.ARTICLE} component={ArticlePage} />
            <Route path={ROUTE_CONST.ARTICLES} component={ArticlesPage} />
            <Route path={ROUTE_CONST.OUR_SERVICE} component={OurServicesPage} />
            <Route
                path={ROUTE_CONST.CUSTOM_LISTING_PAGE}
                component={AboutCustomListingPage}
            />
            <Route
                path={ROUTE_CONST.NEIGHBORHOOD}
                component={NeighborhoodPage}
            />
            <Route
                path={ROUTE_CONST.NEIGHBORHOODS}
                component={NeighborhoodsPage}
            />
            <Route
                path={ROUTE_CONST.TESTIMONIALS}
                component={TestimonialsPage}
            />
            <Route
                path={ROUTE_CONST.TESTIMONIALS2}
                component={TestimonialsPage}
            />
            <Route path={ROUTE_CONST.CUSTOM_MAP} component={CustomMapView} />
            <Route path={ROUTE_CONST.PRICING} component={PricingPage} />
            <Route path={ROUTE_CONST.PRICING2} component={PricingPage} />
            <Route path={ROUTE_CONST.INTERNET} component={InternetPage} />
            <Route path={ROUTE_CONST.INTERNET2} component={InternetPage} />
            <Route path={ROUTE_CONST.STEPS} component={StepsPage} />
            <Route path={ROUTE_CONST.HOW_WE_WORK} component={HowWeWorkPage} />
            <Route
                path={ROUTE_CONST.WHY_WORK_WITH_US}
                component={WhyWorkWithUsPage}
            />
            <Route path={ROUTE_CONST.BRIEF_INTRO} component={BriefIntroPage} />
            <Route
                path={ROUTE_CONST.TRUSTED_BY}
                component={TrustedByPublicPage}
            />
            <Route path={ROUTE_CONST.ITEM} component={ItemPage} />
            <Route path={ROUTE_CONST.ITEMS} component={SearchPage} />
            <Route
                path={ROUTE_CONST.DUPLICATE_PROP}
                component={EditPropertyPage}
            />
            <Route path={ROUTE_CONST.EDIT_PROP} component={EditPropertyPage} />
            <Route path={ROUTE_CONST.ADMIN_PROPS} component={SearchPage} />
            <Route
                path={ROUTE_CONST.EDIT_ARTICLE}
                component={EditArticlePage}
            />
            <Route path={ROUTE_CONST.ADMIN_ARTICLES} component={ArticlesPage} />
            <Route
                path={ROUTE_CONST.ADMIN_ARTICLES_TAGS}
                component={ArticlesTags}
            />

            <Route path={ROUTE_CONST.ADMIN_SEARCH} component={SearchPage} />
            <Route path={ROUTE_CONST.EDIT_ITEM} component={EditItemPage} />
            <Route path={ROUTE_CONST.ADMIN_ITEMS} component={SearchPage} />
            <Route
                path={ROUTE_CONST.EDIT_TESTIMONIAL}
                component={EditTestimonialPage}
            />
            <Route
                path={ROUTE_CONST.ADMIN_TESTIMONIALS}
                component={TestimonialsPage}
            />
            <Route
                path={ROUTE_CONST.EDIT_NEIGHBORHOOD}
                component={EditNeighborhood}
            />
            <Route
                path={ROUTE_CONST.ADMIN_NEIGHBORHOODS}
                component={NeighborhoodsPage}
            />
            <Route
                path={ROUTE_CONST.DUPLICATE_CUSTOM_MAP}
                component={CustomMapEdit}
            />
            <Route
                path={ROUTE_CONST.EDIT_CUSTOM_MAP}
                component={CustomMapEdit}
            />
            <Route
                path={ROUTE_CONST.ADMIN_CUSTOM_MAPS}
                component={CustomMapList}
            />
            <Route
                path={ROUTE_CONST.EDIT_CUSTOMER}
                component={EditCustomerPage}
            />
            <Route
                path={ROUTE_CONST.ADMIN_CUSTOMERS}
                component={CustomersPage}
            />
            <Route path={ROUTE_CONST.DUPLICATE_PLACE} component={EditPlace} />
            <Route path={ROUTE_CONST.EDIT_PLACE} component={EditPlace} />
            <Route path={ROUTE_CONST.ADMIN_PLACES} component={PlacesPage} />
            <Route path={ROUTE_CONST.ADMIN_FEATURED} component={Featured} />
            <Route path={ROUTE_CONST.ADMIN_SELECT} component={Newsletter} />

            <Route path={ROUTE_CONST.EDIT_RSA} component={EditRSA} />
            <Route path={ROUTE_CONST.ADMIN_RSA} component={RSAsPage} />

            <Route
                path={ROUTE_CONST.ADMIN_BANNER}
                component={HomepageBannerEdit}
            />
            <Route
                path={ROUTE_CONST.ADMIN_TRUSTED_BY}
                component={TrustedByPage}
            />
            <Route path={ROUTE_CONST.QA} component={QAPage} />
            <Route
                path={ROUTE_CONST.ADMIN}
                component={AdminPage}
                onEnter={Auth}
            />
            {/* <Route path={ROUTE_CONST.WHYJR} component={IntroPage} /> */}
            {/* <Route path={ROUTE_CONST.LANDING} component={LandingPage}></Route> */}
            <Route path="*" component={HomePage} />
        </Switch>
    );
};
export default Routes;
