import * as constants from 'constants';

const neighborhoods = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_NEIGHBORHOODS_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_NEIGHBORHOOD_SUCCESS:
            const neighborhood = action.neighborhood;
            return newState.map(n =>
                n.id === neighborhood.id ? neighborhood : n
            );
        default:
            return newState;
    }
};

export default neighborhoods;
