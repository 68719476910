import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { Checkbox } from 'components/common/Checkbox';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import { allowed } from '../neighborhood/utils';
import {
    Expandeable,
    ExpandeableAngle,
    ExpandeableContent,
    ExpandeableLabel,
    CheckItem,
    Pointer,
} from './Styled';

const Submenu = ({ value, label, children, dark, onCheckAll }) => {
    const [open, setOpen] = useState(true);
    const [checked, setChecked] = useState(false);

    return (
        <Expandeable open={open} dark={dark}>
            <Flex alignItems="center" onClick={() => setOpen(!open)} ml={-15}>
                <ExpandeableAngle
                    className="material-icons-outlined"
                    open={open}>
                    arrow_right
                </ExpandeableAngle>
                <Checkbox
                    styled
                    value={checked}
                    onChange={v => {
                        setChecked(!checked);
                        onCheckAll(v, value);
                    }}
                />
                <ExpandeableLabel>{label}</ExpandeableLabel>
            </Flex>
            <ExpandeableContent>{children}</ExpandeableContent>
        </Expandeable>
    );
};

function Checkboxtree({ nodes, checked, onCheck }) {
    const neighborhoods = useSelector(state => state.neighborhoods);
    const { path } = useRouteMatch();

    const handleCheck = (isChecked, val) => {
        const isArray = Array.isArray(val);
        let newValues = [];

        if (isChecked) {
            newValues = isArray
                ? checked.concat(val.filter(v => allowed.includes(v)))
                : [...checked, val];
        } else {
            newValues = checked.filter(l =>
                isArray ? !val.includes(l) : l !== val
            );
        }
        onCheck(newValues);
    };
    const getNodes = n => {
        return n.map(item => {
            if (item.children) {
                return (
                    <Submenu
                        value={item.value}
                        label={item.label}
                        key={item.label}
                        dark={item.dark}
                        onCheckAll={handleCheck}>
                        {getNodes(item.children)}
                    </Submenu>
                );
            } else {
                // if (
                //     item.value === 'neighborhood' &&
                //     !neighborhoods.filter(n => n.published).length
                // )
                //     return;
                return (
                    <CheckItem key={item.value}>
                        <Checkbox
                            styled
                            value={checked.includes(item.value)}
                            onChange={v => handleCheck(v, item.value)}
                        />
                        <Flex width={330} alignItems="center">
                            {item.label}
                            {item.link && path !== '/properties' ? (
                                <Pointer
                                    href={item.link}
                                    target="_blank"
                                    className="material-icons">
                                    arrow_right_alt
                                </Pointer>
                            ) : undefined}
                        </Flex>

                        <Flex ml="auto" width={40} justifyContent="center">
                            <img
                                src={`${process.env.PUBLIC_URL}/images/mapResources/${item.value}_icon.png`}
                            />
                        </Flex>
                    </CheckItem>
                );
            }
        });
    };

    return <Box maxWidth={390}>{getNodes(nodes)}</Box>;
}

export default Checkboxtree;
