import React, { useState } from 'react';
import { Box, Flex } from 'reflexbox';
import ToolbarButton from './ToolbarButton';
import { Modal } from 'react-bootstrap';
import { OPTIONS, SHAPES } from './constants';
import { Code } from '../../../components/common/Code';
import Droplist from 'elements/Droplist';
import Copy from '../../Copy';

function ActionsToolbar({ shape, onDelete, code, onChange }) {
    const [showCode, setShowCode] = useState(false);
    const [codeText, setCodeText] = useState(code);
    const [selectedShape, setSelectedShape] = useState('polygon');

    const generateCode = () => {
        if (shape) {
            let code = {
                [OPTIONS.FILL_COLOR]: shape[OPTIONS.FILL_COLOR],
                [OPTIONS.FILL_OPACITY]: shape[OPTIONS.FILL_OPACITY],
                [OPTIONS.STROKE_COLOR]: shape[OPTIONS.STROKE_COLOR],
                [OPTIONS.STROKE_OPACITY]: shape[OPTIONS.STROKE_OPACITY],
                [OPTIONS.STROKE_WEIGHT]: shape[OPTIONS.STROKE_WEIGHT],
            };

            switch (shape.type) {
                case 'polygon':
                case 'polyline':
                    const val = shape
                        .getPath()
                        .getArray()
                        .map(coo => ({ lat: coo.lat(), lng: coo.lng() }));

                    code.path = val;
                    break;
                case 'circle':
                    code.center = {
                        lat: shape.center.lat(),
                        lng: shape.center.lng(),
                    };
                    code.radius = shape.radius;
                case 'rectangle':
                    const ne = shape.getBounds().getNorthEast();
                    const sw = shape.getBounds().getSouthWest();
                    code.bounds = {
                        north: ne.lat(),
                        east: ne.lng(),
                        south: sw.lat(),
                        west: sw.lng(),
                    };
                default:
                    break;
            }
            setCodeText(JSON.stringify(code, undefined, 4));
            setShowCode(true);
        }
    };

    const close = () => {
        setShowCode(false);
    };
    const handleChange = val => {
        let string = val
            .replaceAll("'", '"')
            .replace(/(['"])?([a-z0-9A-Z_]+)(['"])?\s*:/gm, '"$2": ')
            .replace('},]', '}]');

        onChange(JSON.parse(string), selectedShape);
        setCodeText(string);
    };
    const handleDelete = () => {
        setSelectedShape('polygon');
        setCodeText('');
        onDelete();
    };

    return (
        <Flex
            p={2}
            bg="#FFFFFFDF"
            style={{
                display: 'inline-flex',
                borderRadius: 50,
                position: 'absolute',
                zIndex: 1,
                top: 10,
                left: 70,
            }}>
            {shape ? (
                <ToolbarButton mr={2} onClick={handleDelete} disabled={!shape}>
                    delete
                </ToolbarButton>
            ) : undefined}
            <ToolbarButton last onClick={generateCode} horizontal>
                code
            </ToolbarButton>
            <Modal show={showCode} onHide={close} scrollable animation={false}>
                <Modal.Header
                    style={{
                        borderBottom: '2px solid #CEC4AA',
                        background: '#FCFBF9',
                    }}>
                    <Box
                        className="material-icons"
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 15,
                            top: 15,
                        }}
                        onClick={close}>
                        close
                    </Box>
                    <Box
                        p="5px 0px"
                        width={1}
                        textAlign="center"
                        onClick={close}>
                        <Box
                            fontSize="18px"
                            lineHeight="25px"
                            fontWeight="bold"
                            color="#808080"
                            mb={14}
                            mt={25}>
                            Code
                        </Box>
                    </Box>
                </Modal.Header>
                <Modal.Body>
                    {codeText ? <Copy text={codeText} /> : undefined}
                    {!codeText ? (
                        <Droplist
                            options={SHAPES}
                            value={selectedShape}
                            onChange={setSelectedShape}
                        />
                    ) : undefined}
                    <Code value={codeText} onChange={handleChange} />
                </Modal.Body>
            </Modal>
        </Flex>
    );
}

export default ActionsToolbar;
