import * as constants from '../constants';

export const saveCustomer = customer => ({
    type: constants.SAVE_CUSTOMER,
    customer,
});

export const saveCustomerSuccess = customer => ({
    type: constants.SAVE_CUSTOMER_SUCCESS,
    customer,
});

export const saveCustomerFailure = error => ({
    type: constants.SAVE_CUSTOMER_FAILURE,
    error,
});

export const fetchCustomer = id => ({
    type: constants.FETCH_CUSTOMER,
    id,
});

export const fetchCustomerSuccess = customer => ({
    type: constants.FETCH_CUSTOMER_SUCCESS,
    customer,
});

export const fetchCustomerFailure = error => ({
    type: constants.FETCH_CUSTOMER_FAILURE,
    error,
});

export const deleteCustomer = id => ({
    type: constants.DELETE_CUSTOMER,
    id,
});

export const deleteCustomerSuccess = id => ({
    type: constants.DELETE_CUSTOMER_SUCCESS,
    id,
});

export const deleteCustomerFailure = error => ({
    type: constants.DELETE_CUSTOMER_FAILURE,
    error,
});

export const fetchCustomers = () => ({
    type: constants.FETCH_CUSTOMERS,
});

export const fetchCustomersSuccess = customers => ({
    type: constants.FETCH_CUSTOMERS_SUCCESS,
    customers,
});

export const fetchCustomersFailure = error => ({
    type: constants.FETCH_CUSTOMERS_FAILURE,
    error,
});

export const resetCustomer = () => ({
    type: constants.RESET_CUSTOMER,
});
