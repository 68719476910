const furniture = furniture => {
    switch (furniture) {
        case 'fully':
            return 'Fully Furnished';
        case 'semi':
            return 'Semi Furnished';
        case 'unfurnished':
            return 'Unfurnished';
    }
};
export default furniture;
