import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import ContactModal from '../contactForm/ContactModal';
import CurrenciesSwitch from 'components/currency/CurrenciesSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { toggleMap } from 'actions';
import { Flex, Box } from 'reflexbox';
import HeaderMenu from './HeaderMenu';
import styled from 'styled-components';
import { ROUTE_CONST } from '../../Routes';
import useScreen from 'utils/screen';

const StyledHeader = styled(Flex)`
    align-items: center;
    background: ${({ transparent }) => (transparent ? 'transparent' : '#FFF')};
    border-top: 3px solid ${({ theme }) => theme.gold};
    padding: 10px 0px;
    z-index: ${({ fullscreen }) => (fullscreen ? 1 : 11)};
    position: fixed;
    width: 100%;
    height: 75px;
    top: 0;
    box-shadow: ${({ transparent }) =>
        transparent ? 'none' : '0px 2px 6px #0000001A'};

    border-top: 4px solid
        ${({ transparent, theme }) =>
            transparent ? 'transparent' : theme.gold};

    transition: all 0.25s linear;
`;

const HeaderLink = styled(NavLink)`
    color: ${({ transparent, theme }) => (transparent ? '#FFF' : theme.grey)};
    font-size: 12px !important;
    font-weight: bold !important;
    text-decoration: none;
    &:hover {
        color: ${({ transparent, theme }) =>
            transparent ? '#FFF' : theme.gold};
    }
`;

const Header = props => {
    const {
        neighborhoods,
        users: { user },
        map: { fullscreen },
    } = useSelector(state => state);
    const isMobile = useScreen();

    const dispatch = useDispatch();

    const isNeighborhoodsEnabled =
        neighborhoods.length && neighborhoods.filter(n => n.published).length;

    const {
        location: { pathname },
    } = props;

    const isHomepage = pathname === '/';
    const [solid, setSolid] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;

            if (currentScrollY < 450 && solid) {
                setSolid(false);
            }
            if (currentScrollY > 450 && !solid) {
                setSolid(true);
            }
        };
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => window.removeEventListener('scroll', handleScroll);
    }, [solid]);

    const doToggleMap = () => {
        dispatch(toggleMap());
    };

    const transparent = !solid && isHomepage;

    return (
        <StyledHeader transparent={transparent} fullscreen={fullscreen}>
            <Flex
                justifyContent="space-between"
                alignItems="center"
                width="100%">
                <NavLink to="/">
                    <img
                        src={`${process.env.PUBLIC_URL}/images/JR_${
                            transparent ? 'white' : 'color'
                        }.png`}
                        style={{ paddingLeft: 30, height: 44 }}
                    />
                </NavLink>
                <Flex>
                    <HeaderMenu label="View" transparent={transparent}>
                        <HeaderLink as="a" onClick={doToggleMap}>
                            Map
                        </HeaderLink>
                        <HeaderLink to="/properties/?status=available,soon">
                            Properties
                        </HeaderLink>
                        {isNeighborhoodsEnabled ? (
                            <HeaderLink to={ROUTE_CONST.NEIGHBORHOODS}>
                                Neighborhoods
                            </HeaderLink>
                        ) : (
                            <span />
                        )}
                    </HeaderMenu>
                    <HeaderMenu label="About" transparent={transparent}>
                        <HeaderLink to={ROUTE_CONST.BRIEF_INTRO}>
                            Brief Intro
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.PROFILE}>
                            Agency Profile
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.TESTIMONIALS}>
                            Testimonials
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.TRUSTED_BY}>
                            Trusted by
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.PRICING}>
                            Pricing Policy
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.OUR_SERVICE}>
                            Our Services
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.ABOUT_CL}>
                            Custom Listing
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.HOW_WE_WORK}>
                            How We Work
                        </HeaderLink>
                        <HeaderLink to={ROUTE_CONST.WHY_WORK_WITH_US}>
                            Work with us
                        </HeaderLink>
                    </HeaderMenu>
                    {/*
                    <HeaderMenu label="Info & Tips" transparent={transparent}>
                        <HeaderLink to={ROUTE_CONST.QA}>
                            Questions &amp; Answers
                        </HeaderLink>
                         <HeaderLink to="/internet">
                            Internet Management Services
                        </HeaderLink> 
                    </HeaderMenu>*/}
                    <Box mr={[2, 4]} mt={2} ml={[0, 4]} minWidth={100}>
                        <ContactModal light={transparent} bordered />
                    </Box>
                    {user && user.isAdmin ? (
                        <Box mt={2} mx={4}>
                            <HeaderLink
                                to={ROUTE_CONST.ADMIN}
                                transparent={transparent ? 1 : 0}>
                                Admin
                            </HeaderLink>
                        </Box>
                    ) : undefined}
                    {!isMobile ? (
                        <Box mx="20px" my="5px">
                            <CurrenciesSwitch transparent={transparent} />
                        </Box>
                    ) : undefined}
                </Flex>
            </Flex>
        </StyledHeader>
    );
};
export default withRouter(Header);
