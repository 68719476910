import {
    LOADING,
    FETCHING,
    IDLE,
    GET_CURRENCIES_SUCCESS,
    SEND_NEWSLETTER_SUCCESS,
    FETCH_BANNER_IMAGE_SUCCESS,
    SEND_NEWSLETTER_RESET,
    FETCHING_PROPERTIES,
} from 'constants';

const initial = {
    loading: false,
    fetchingProperties: false,
};

const app = (state = initial, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case LOADING:
            newState.loading = true;
            return newState;
        case IDLE:
            newState.loading = false;
            return newState;
        case FETCHING:
            newState.fetching = action.fetching;
            return newState;
        case '@@router/LOCATION_CHANGE':
            newState.showMap = false;
            return newState;
        case 'CLOSE_HEADER':
            newState.headerClosed = true;
            return newState;
        case GET_CURRENCIES_SUCCESS:
            newState.currenciesReady = true;
            return newState;
        case SEND_NEWSLETTER_SUCCESS:
            newState.isNewsletterSent = true;
            return newState;
        case SEND_NEWSLETTER_RESET:
            newState.isNewsletterSent = false;
            return newState;
        case FETCH_BANNER_IMAGE_SUCCESS:
            newState.bannerImage = action.url;
            return newState;
        case FETCHING_PROPERTIES:
            newState.fetchingProperties = action.fetching;
            return newState;
        default:
            return state || {};
    }
};

export default app;
