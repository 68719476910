import React from 'react';
import { getIn } from 'formik';

// eslint-disable-next-line import/prefer-default-export
export const FormikHOC = React.forwardRef(
    ({ field, form, uiComponent, attr, ...props }, ref) => {
        const { name, ...fieldProps } = field;

        const { errors, touched, submitCount, setFieldValue } = form;
        const Component = uiComponent;

        const handleOnChange = value => {
            if (value?.target) value = value.target.value;
            setFieldValue(name, value);
            if (props.onChange) props.onChange(value);
        };

        const errorText = getIn(errors, name);
        const isTouched = getIn(touched, name);
        const showError =
            (errorText && isTouched) || (errorText && submitCount > 0);

        const propsToPass = {
            ...fieldProps,
            ...props,
            ref,
            name,
            label: props.label,
            onChange: handleOnChange,
            error: showError ? errorText : undefined,
            disabled: props.disabled || form.isSubmitting,
        };
        return <Component {...propsToPass} />;
    }
);
