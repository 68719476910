import * as constants from '../constants';

export const fetchNeighborhoods = () => ({
    type: constants.FETCH_NEIGHBORHOODS,
});

export function fetchNeighborhoodsSuccess(neighborhoods) {
    return {
        type: constants.FETCH_NEIGHBORHOODS_SUCCESS,
        payload: neighborhoods,
    };
}

export function fetchNeighborhoodsFailure(error) {
    return {
        type: constants.FETCH_NEIGHBORHOODS_FAILURE,
        payload: error,
    };
}

export const getNeighborhood = id => ({
    type: constants.GET_NEIGHBORHOOD,
    id,
});

export function getNeighborhoodSuccess(neighborhood) {
    return {
        type: constants.GET_NEIGHBORHOOD_SUCCESS,
        neighborhood,
    };
}

export function getNeighborhoodFailure(error) {
    return {
        type: constants.GET_NEIGHBORHOOD_FAILURE,
        error,
    };
}

export function resetNeighborhood() {
    return {
        type: constants.RESET_NEIGHBORHOOD,
    };
}

export const saveNeighborhood = neightborhood => ({
    type: constants.SAVE_NEIGHBORHOOD,
    neightborhood,
});

export function saveNeighborhoodSuccess(neighborhood) {
    return {
        type: constants.SAVE_NEIGHBORHOOD_SUCCESS,
        neighborhood,
    };
}

export function saveNeighborhoodFailure(error) {
    return {
        type: constants.SAVE_NEIGHBORHOOD_FAILURE,
        payload: error,
    };
}
