import axios from 'axios';
import * as constants from '../constants';

export function sendNewsletter(recipients, subject, html) {
    const request = axios({
        method: 'post',
        headers: {
            'Content-Type': 'text/plain',
        },
        data: {
            html,
        },
        url: `${constants.PATH}newsletter/?recipients=${recipients}&subject=${subject}`,
    });

    return {
        type: constants.SEND_NEWSLETTER,
        payload: request,
    };
}

export function sendNewsletterSuccess(recipients) {
    return {
        type: constants.SEND_NEWSLETTER_SUCCESS,
        recipients: recipients,
    };
}

export function sendNewsletterFailure(error) {
    return {
        type: constants.SEND_NEWSLETTER_FAILED,
        payload: error,
    };
}
