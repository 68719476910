import { all, takeLatest, select, put } from 'redux-saga/effects';
import { SORT_PROPERTIES_SUCCESS, SORT_PROPERTIES } from 'constants';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';

function getSortedProperties(properties, by, direction) {
    let sorted;
    if (by === 'price') {
        const newProps = properties.map(p => {
            p.basePrice =
                p.price && p.currency && p.currency !== 'USD'
                    ? window.fx(p.price).from(p.currency).to('USD')
                    : p.price;
            return p;
        });
        sorted = sortBy(newProps, 'basePrice');
    } else {
        sorted = sortBy(properties, by);
    }
    return direction === 'dsc' ? reverse(sorted) : sorted;
}

/**
 *
 *
 *  Show all
 *
 *
 */
function* sortProps({ by, direction }) {
    const props = yield select(state => state.visibilityFilter.filtered);
    yield put({
        type: SORT_PROPERTIES_SUCCESS,
        sortBy: by,
        sortDirection: direction,
        filtered: getSortedProperties(props, by, direction),
    });
}

export default function* () {
    yield all([takeLatest(SORT_PROPERTIES, sortProps)]);
}
