import * as constants from 'constants';

const places = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_PLACES_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_PLACE_SUCCESS:
            const place = action.place;
            const found = newState.find(p => p.id === place.id);
            if (found) {
                return newState.map(p => (p.id === place.id ? place : p));
            } else {
                return newState.concat(place);
            }
        case constants.DELETE_PLACE_SUCCESS:
            return newState.filter(p => p.id !== Number(action.id));
        default:
            return newState;
    }
};

export default places;
