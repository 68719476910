import * as constants from 'constants';

const featured = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.GET_FEATURED_PROPERTIES_SUCCESS:
            newState = action.featured;
            return newState;

        default:
            return state || {};
    }
};

export default featured;
