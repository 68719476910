import { all, takeLatest, put, takeEvery, select } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, CURRENT_USER } from 'constants';
import { getCurrentUserSuccess, getCurrentUserFailure } from 'actions/users';

/***********************************
 * Get User
 **********************************/
function* getUser() {
    try {
        const userResponse = yield Axios.get(`${ROOT_URL}/user`);

        yield put(getCurrentUserSuccess(userResponse));
    } catch (e) {
        console.log(e);
        yield put(getCurrentUserFailure(e));
    }
}

export default function* () {
    yield all([takeLatest(CURRENT_USER, getUser)]);
}
