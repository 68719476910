import { all, takeLatest, put, takeEvery, select } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, GET_CURRENCIES } from 'constants';
import { getCurrenciesSuccess } from 'actions/currencies';

/***********************************
 * Get Currencies
 **********************************/
function* getCurrencies() {
    try {
        const currenciesResponse = yield Axios.get(`${ROOT_URL}/currency`);

        yield put(getCurrenciesSuccess(currenciesResponse));
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield all([takeLatest(GET_CURRENCIES, getCurrencies)]);
}
