import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { ReactComponent as Icon } from 'components/map/resources/direction_icon.svg';
import Axios from 'axios';

const Wrapper = styled(Flex)`
    background: #e0d3b633;
    border: 1px solid #d9caa4;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px !important;
`;
const Value = styled.div`
    color: #555555;
    font-size: 12px;
    margin-bottom: 5px;
    white-space: nowrap;
`;

const Label = styled.div`
    font-size: 11px;
    color: #acacac;
`;

function Distance(props) {
    const { name, distances = {}, from, to } = props;

    const [distance, setDistance] = useState(distances);
    const [fetching, setFetching] = useState(true);

    useEffect(() => {
        if (from && to) {
            Axios.get(
                `/api/distance?origin=${from.lat},${from.lng}&destination=${to.lat},${to.lng}`
            )
                .then(response => {
                    setDistance(response.data);
                    setFetching(false);
                })
                .finally(e => setFetching(false));
        }
    }, []);

    const { walking, driving, transit, biking } = distance;

    return (
        <Wrapper>
            <Box>
                <Box fontWeight="bold" fontSize="11px" color="#C29320" mb={2}>
                    Distance to {name}:
                </Box>
                {fetching ? <Box>Calculating distance....</Box> : undefined}
                <Flex>
                    {walking && walking.status === 'OK' ? (
                        <Box mr={4}>
                            <Value>
                                <b>{walking?.duration?.text}</b> (
                                {walking?.distance?.text})
                            </Value>
                            <Label>Walk</Label>
                        </Box>
                    ) : undefined}
                    {driving && driving.status === 'OK' ? (
                        <Box mr={4}>
                            <Value>
                                <b>{driving?.duration?.text}</b> (
                                {driving?.distance?.text})
                            </Value>
                            <Label>Drive</Label>
                        </Box>
                    ) : undefined}
                    {transit && transit.status === 'OK' ? (
                        <Box mr={4}>
                            <Value>
                                <b>{transit?.duration?.text}</b> (
                                {transit?.distance?.text})
                            </Value>
                            <Label>Transportation</Label>
                        </Box>
                    ) : undefined}
                    {biking && biking.status === 'OK' ? (
                        <Box mr={4}>
                            <Value>
                                <b>{biking?.duration?.text}</b> (
                                {biking?.distance?.text})
                            </Value>
                            <Label>Biking</Label>
                        </Box>
                    ) : undefined}
                </Flex>
            </Box>
            <Box width={66} ml="auto" mr={2}>
                <Icon />
            </Box>
        </Wrapper>
    );
}

export default React.memo(Distance);
