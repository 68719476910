import {
    CURRENT_USER,
    CURRENT_USER_SUCCESS,
    CURRENT_USER_FAILURE,
} from 'constants';

const INITIAL_STATE = { user: null, status: null, error: null, loading: false };

export default function (state = INITIAL_STATE, action) {
    let error;
    switch (action.type) {
        case CURRENT_USER:
            return {
                ...state,
                user: null,
                status: 'not_authenticated',
                isAdmin: false,
                error: null,
                loading: true,
            };
        case CURRENT_USER_SUCCESS:
            return {
                ...state,
                user: action.payload.data.user,
                status: 'authenticated',
                isAdmin: action.payload.data.isAdmin,
                error: null,
                loading: false,
            };
        case CURRENT_USER_FAILURE:
            error = action.payload.data || { message: action.payload.message };
            return {
                ...state,
                user: null,
                status: 'not_authenticated',
                isAdmin: false,
                error: error,
                loading: false,
            };

        default:
            return state;
    }
}
