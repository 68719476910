import { placeTypes } from '../places/constants';

export const allowed = [
    'landmark',
    'school',
    'sport',
    'park',
    'restaurant',
    'supermarket',
    'commercial',
    // 'misc',
    // 'culture',
    // 'carRental',
    // 'hotels',
    // 'medical',
    // 'ngo',
    // 'other',
];

export const getPlacesOptions = () => {
    return allowed.map(a => ({ value: a, label: placeTypes[a] }));
};

export const isAllowed = p => p?.types?.some(r => allowed.indexOf(r) >= 0);
