import React from 'react';
import {
    qualityConst,
    floorConst,
    furnitureConst,
    durationConst,
} from '../property/constants';
import { Box, Flex } from 'reflexbox';
import { Tooltip } from 'react-tippy';
import styled from 'styled-components';
import QualityStars from './QualityStars';
import 'react-tippy/dist/tippy.css';

export const Flagged = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    height: 40px;
    background: rgb(204, 198, 161);
    background: linear-gradient(
        145deg,
        rgba(204, 198, 161, 1) 0%,
        rgba(172, 166, 131, 1) 100%
    );
    color: #fff;
    border-radius: 5px 0px 0px 5px;
    font-weight: bold;
    padding: 4px 10px;
    width: 150px;
    position: relative;
    &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        width: 0;
        height: 100%;
        border: 20px solid transparent;
        border-right-color: #fff;
    }
`;

const Feature = ({ caption, value, tip }) => {
    return (
        <Box mb={20}>
            <Box
                fontSize="16px"
                fontWeight="bold"
                lineHeight="30px"
                color="#CB9A22"
                style={{ textTransform: 'capitalize' }}>
                {value}
                {tip ? (
                    <Tooltip trigger="click" arrow html={tip}>
                        <Box
                            className="material-icons-outlined"
                            color="#ACACAC"
                            ml={1}
                            style={{ fontSize: '16px', cursor: 'pointer' }}>
                            info
                        </Box>
                    </Tooltip>
                ) : undefined}
            </Box>

            <Box fontSize="14px" fontWeight="500" color="#ACACAC">
                {caption}
            </Box>
        </Box>
    );
};

const Details = ({
    quality,
    floor,
    floors,
    duration,
    furnitureStatus,
    optionalFurniture,
    furniture,
    exclusive,
    recommended,
    apartments,
    availability,
    isBuilding,
}) => {
    const Tip = (
        <>
            {optionalFurniture && <div>Can be furnished upon request</div>}
            {furniture ? (
                <>
                    <Box fontSize="14px">Furniture includes:</Box>
                    <Flex maxWidth="500px" flexWrap="wrap">
                        {furniture.map(item => (
                            <span
                                key={item.id}
                                className="additional-info-item">
                                {item.title}
                            </span>
                        ))}
                    </Flex>
                </>
            ) : undefined}
        </>
    );

    const durationString = () => {
        let str = '';
        duration.map((s, i) => {
            str += s;
            if (i < duration.length - 1) str += ', ';
        });
        return str;
    };

    return (
        <>
            <Box
                fontSize="22px"
                lineHeight="35px"
                fontWeight="bold"
                mb={30}
                color="#707070">
                More Details
            </Box>
            <Box
                bg="#F7F7F7"
                p="23px 28px"
                style={{ borderRadius: '20px' }}
                mb={20}>
                {!isBuilding && furnitureStatus ? (
                    <Feature
                        caption="Furniture"
                        value={furnitureConst[furnitureStatus]}
                        tip={furniture || optionalFurniture ? Tip : undefined}
                    />
                ) : undefined}
                {!isBuilding && floor !== undefined ? (
                    <Feature caption="Floor" value={floorConst[floor]} />
                ) : undefined}
                {isBuilding && floors ? (
                    <Feature caption="Floors" value={floors} />
                ) : undefined}
                {isBuilding && apartments ? (
                    <Feature caption="Apartments" value={apartments} />
                ) : undefined}
                {quality ? (
                    <Feature
                        caption="Quality"
                        value={<QualityStars stars={quality} />}
                    />
                ) : undefined}
                {duration ? (
                    <Feature caption="Duration" value={durationString()} />
                ) : undefined}
            </Box>
            <div className="features">
                {/* { propertyType ? <Feature caption="Property type" value={propertyType.title}/> : undefined } */}
                {/* floors !== undefined ? <Feature caption="Floors" value={floors}/> : undefined */}
            </div>
            {recommended ? (
                <Flagged my={10} ml={30}>
                    <Box fontSize="12px" lineHeight="15px">
                        HIGHLY
                    </Box>
                    <Box fontSize="12px" lineHeight="15px">
                        RECOMMENDED
                    </Box>
                </Flagged>
            ) : undefined}
            {exclusive ? (
                <Flagged my={10} ml={30}>
                    <Flex>
                        <Box className="material-icons">grade</Box>
                        <Box fontSize="16px">EXCLUSIVE</Box>
                    </Flex>
                </Flagged>
            ) : undefined}
        </>
    );
};

export default Details;
