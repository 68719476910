export const PropertyFilters = [
    {
        id: 'propId',
        label: 'Propery ID',
        isString: true,
        order: 0,
    },
    {
        id: 'status',
        label: 'Property Status',
        options: [
            { id: 'available', title: 'Available' },
            { id: 'soon', title: 'Available soon' },
            { id: 'unavailable', title: 'Unavailable' },
        ],
        order: 1,
    },
    {
        id: 'availableOn',
        label: 'Available between',
        isDate: true,
        isRange: true,
        order: 2,
    },
    {
        id: 'published',
        label: 'Published',
        order: 3,
        isAdmin: true,
        isBoolean: true,
        options: [
            { id: true, title: 'Published' },
            { id: false, title: 'Not published' },
        ],
    },
    {
        id: 'durations',
        label: 'Duration',
        options: [
            { id: 'long term', title: 'Long Term' },
            { id: 'short term', title: 'Short Term' },
        ],
        order: 4,
    },

    {
        id: 'quality',
        label: 'Quality',
        options: [
            { id: 3, title: '3' },
            { id: 4, title: '4' },
            { id: 5, title: '5' },
        ],
        order: 5,
    },

    {
        id: 'furnitureStatus',
        label: 'Furniture',
        options: [
            { id: 'fully', title: 'Fully furnished' },
            { id: 'semi', title: 'Semi furnished' },
            { id: 'unfurnished', title: 'Unfurnished' },
        ],
        order: 6,
    },
    {
        id: 'bedrooms',
        label: 'Bedrooms',
        options: [
            { id: 1, title: '1' },
            { id: 2, title: '2' },
            { id: 3, title: '3' },
            { id: 4, title: '4' },
            { id: '5~', title: '5+' },
        ],
        order: 7,
    },
    {
        id: 'bathrooms',
        label: 'Bathrooms',
        options: [
            { id: 1, title: '1' },
            { id: 2, title: '2' },
            { id: 3, title: '3' },
            { id: 4, title: '4' },
            { id: '5~', title: '5+' },
        ],
        order: 8,
    },

    {
        id: 'floor',
        label: 'Floors',
        options: [
            { id: 0, title: 'Ground' },
            { id: 1, title: '1st' },
            { id: 2, title: '2nd' },
            { id: 3, title: '3rd' },
            { id: '4~', title: '4th+' },
        ],
        order: 9,
    },
    {
        id: 'size',
        label: 'Size',
        isRange: true,
        options: [],
        from: 0,
        to: 0,
        order: 10,
    },
    {
        id: 'price',
        label: 'Price Range',
        isRange: true,
        options: [],
        from: 0,
        to: 0,
        order: 11,
    },
    {
        id: 'neighborhood',
        label: 'Neighborhood',
        options: [],
        isFetch: true,
        sort: true,
        order: 12,
    },
    {
        id: 'proptype',
        label: 'Property type',
        options: [],
        isFetch: true,
        order: 13,
        sort: true,
    },

    {
        id: 'amenities',
        label: 'Amenities',
        options: [],
        order: 14,
        sort: true,
        isFetch: true,
    },
    // {
    //     id: 'nearby',
    //     label: 'Near by',
    //     options: [],
    //     order: 13,
    //     sort: true,
    //     isFetch: true,
    // },
];
