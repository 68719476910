import * as constants from 'constants';

const testimonial = (state, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.FETCH_TESTIMONIAL_SUCCESS:
            newState = action.testimonial;
            return newState;

        case constants.SAVE_TESTIMONIAL_SUCCESS:
            newState = action.testimonial;
        case constants.DELETE_TESTIMONIAL_SUCCESS:
        case constants.RESET_TESTIMONIAL:
            newState = {};

        default:
            return state || {};
    }
};

export default testimonial;
