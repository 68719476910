import React from 'react';
import { Box, Flex } from 'reflexbox';
import Quality from './Quality';

function QualityStars({ stars, withLabel, ...rest }) {
    let arr = [];
    for (let i = 0; i < 5; i++) {
        arr.push(
            <Box
                key={i}
                className="material-icons"
                fontSize="18px"
                color={i >= stars ? '#CECCCC' : '#FD9D27'}>
                star
            </Box>
        );
    }
    return <Box>{Quality(stars)}</Box>;
    // return (
    //     <Flex {...rest} alignItems="center">
    //         {withLabel ? (
    //             <Box
    //                 fontSize="13px"
    //                 lineHeight="22px"
    //                 color="#555555"
    //                 fontWeight="bold"
    //                 textTransform="capitalize"
    //                 mr={3}>
    //                 Quality
    //             </Box>
    //         ) : undefined}
    //         {arr}
    //     </Flex>
    // );
}

export default QualityStars;
