import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Tooltip } from 'react-tippy';
import ToolbarButton from './ToolbarButton';

const getRGB = (hex, opacity) => {
    var arr = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const rgba = {
        r: parseInt(arr[1], 16),
        g: parseInt(arr[2], 16),
        b: parseInt(arr[3], 16),
        a: opacity,
    };
    return rgba;
};

export const ColorPicker = ({ color, opacity, onChange, icon }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => {
        setOpen(!open);
    };
    const ref = useDetectClickOutside({ onTriggered: toggle });

    const handleColorChange = c => {
        onChange(c.hex, c.rgb.a);
    };

    return (
        <Tooltip
            trigger="click"
            arrow
            position="left"
            theme="light"
            interactive
            html={
                <SketchPicker
                    color={getRGB(color, opacity)}
                    onChange={handleColorChange}
                />
            }>
            <ToolbarButton color={color}>{icon}</ToolbarButton>
        </Tooltip>
    );
};
