import * as constants from '../constants';

export const fetchProperties = force => ({
    type: constants.FETCH_PROPERTIES,
    force,
});

export const fetchPropertiesSuccess = properties => ({
    type: constants.FETCH_PROPERTIES_SUCCESS,
    payload: properties,
});

export const fetchPropertiesFailure = error => ({
    type: constants.FETCH_PROPERTIES_FAILURE,
    payload: error,
});

export const getFeaturedProperties = () => ({
    type: constants.GET_FEATURED_PROPERTIES,
});

export const getFeaturedPropertiesSuccess = featured => ({
    type: constants.GET_FEATURED_PROPERTIES_SUCCESS,
    featured,
});
export const getFeaturedPropertiesFailure = error => ({
    type: constants.GET_FEATURED_PROPERTIES_FAILURE,
    error,
});
