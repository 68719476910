import { contains } from 'utils/array';

let converter = window.fx;

/**
 * Special filter for price
 * @param {*} object
 * @param {*} globalCurrency
 */
export function checkPriceRange(object, value, currencies) {
    const globalCurrency = currencies.currency;
    const rates = currencies.rates;

    const range = value.split(',');
    let priceToFilter = object.price;

    if (object.currency && object.currency !== globalCurrency) {
        priceToFilter = window
            .fx(object.price)
            .from(object.currency)
            .to(globalCurrency);
    }
    return priceToFilter >= range[0] && priceToFilter <= range[1];
}
/**
 * Special filter to check for published
 * @param {*} object
 */
export function checkPublished(property, value) {
    if (!value || value.split(',').length > 1) return true;

    const boolVal = value === 'true';
    return property.published === boolVal;
}
/**
 * Handle special type of filters: Range
 *
 */
export function getRangeOption(currentFilters, filter, option) {
    if (option == '0,0') {
        if (currentFilters[filter.id] !== undefined) {
            delete currentFilters[filter.id];
        }
        return currentFilters;
    }

    let values = stringToArr(option);

    let valuesStr = values[0] + ',' + values[1];
    currentFilters[filter.id] = valuesStr;
    return currentFilters;
}
/**
 *
 * Convert a string to array
 *
 * @param  {String} value
 * @return {Array}
 */
export function stringToArr(value) {
    value = value + '';
    let valueArr = value.split(',');
    return valueArr;
}

/**
 * Check if field has value
 *
 */
export function checkFieldValue(fieldName, fieldValues, paramValue) {
    if (fieldValues && fieldValues.id) fieldValues = fieldValues.id;
    let result = contains(stringToArr(paramValue), fieldValues, fieldName);

    return result;
}
