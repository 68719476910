import React, { useState, useEffect, useCallback } from 'react';
import GoogleMap from '../GoogleMap';
import { Box } from 'reflexbox';
import { MODES, SHAPE_OPTIONS } from './constants';
import Toolbar from './Toolbar';
import ActionsToolbar from './ActionsToolbar';
import { BaseSearchComponent } from '../PlacesSearch';
import { FullScreenButton } from '../../../components/map/Styled';

let drawingManager = null;
let mapsApi = null;
let mapObj = null;
let marker = null;

function polygonCenter(poly) {
    const bounds = new mapsApi.LatLngBounds();

    poly.getPath().forEach(path => {
        bounds.extend(path);
    });

    mapObj.fitBounds(bounds);
}

export default function Draw({ shp }) {
    const [shapeOptions, setShapeOptions] = useState(SHAPE_OPTIONS);
    const [shape, setShape] = useState(shp);
    const [mode, setMode] = useState(MODES.POLYGON);
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        if (drawingManager) {
            drawingManager.setOptions({
                polygonOptions: shapeOptions,
                rectangleOptions: shapeOptions,
                circleOptions: shapeOptions,
                polylineOptions: shapeOptions,
            });
            if (shape) {
                const newShape = shape;
                newShape.setOptions(shapeOptions);
                setShape(newShape);
            }
        }
    }, [shapeOptions]);

    useEffect(() => {
        if (drawingManager) {
            drawingManager.setDrawingMode(mode);
        }
    }, [mode]);

    const changeMode = mode => {
        clearSelection();
        setMode(mode);
    };
    const clearSelection = () => {
        if (shape) {
            shape.setEditable(false);
            setShape();
        }
    };

    const handleDelete = () => {
        if (shape) {
            shape.setMap();
            setShape();
        }
    };

    const setSelection = shape => {
        clearSelection();
        shape.setEditable(true);
        setShape(shape);
    };

    const handleNewShape = (s, shapeType) => {
        if (shapeType === 'polygon') {
            const newShape = new mapsApi.Polygon(s);
            newShape.setMap(mapObj);
            polygonCenter(newShape);
        }
    };

    const handleOverlayComplete = shape => {
        if (shape.type !== 'marker') {
            //stop drawing
            drawingManager.setDrawingMode(null);

            var newShape = shape.overlay;
            newShape.type = shape.type;
            newShape.addListener('click', function (e) {
                // Removing vertex from polygon and polyline
                if (shape.vertex !== undefined) {
                    if (newShape.type === mapsApi.drawing.OverlayType.POLYGON) {
                        var path = newShape.getPaths().getAt(shape.path);
                        path.removeAt(shape.vertex);
                        if (path.length < 3) {
                            newShape.setMap(null);
                        }
                    }
                    if (
                        newShape.type === mapsApi.drawing.OverlayType.POLYLINE
                    ) {
                        var path = newShape.getPath();
                        path.removeAt(e.vertex);
                        if (path.length < 2) {
                            newShape.setMap(null);
                        }
                    }
                }
                setSelection(newShape);
            });
            setSelection(newShape);
        }
    };
    const mapsLoaded = (map, mapApi) => {
        const manager = new mapApi.drawing.DrawingManager({
            drawingControl: false,
            drawingMode: mode,
            polygonOptions: shapeOptions,
            circleOptions: shapeOptions,
            rectangleOptions: shapeOptions,
            polylineOptions: shapeOptions,
        });
        mapApi.event.addListener(
            manager,
            'overlaycomplete',
            handleOverlayComplete
        );
        mapApi.event.addListener(
            manager,
            'drawingmode_changed',
            clearSelection
        );
        mapApi.event.addListener(map, 'click', clearSelection);
        manager.setMap(map);
        drawingManager = manager;
        mapsApi = mapApi;
        mapObj = map;
    };

    const handleSearch = place => {
        if (place) {
            const bounds = new mapsApi.LatLngBounds();
            marker = new mapsApi.Marker({
                map: mapObj,
                title: place.name,
                position: place.geometry.location,
            });

            if (place?.geometry?.viewport) {
                bounds.extend(place.geometry.location);
                mapObj.fitBounds(bounds);
            }
            mapObj.setCenter(place.geometry.location);
        } else {
            marker.setMap(null);
            marker = null;
        }
    };

    return (
        <Box
            height={fullScreen ? '100%' : 'calc(100vh - 200px)'}
            style={{
                position: fullScreen ? 'fixed' : 'relative',
                width: '100%',
                zIndex: 999,
                top: 1,
            }}>
            <Box
                style={{
                    position: 'absolute',
                    top: 15,
                    left: 180,
                    zIndex: 1,
                    width: 200,
                }}>
                <BaseSearchComponent onSelect={handleSearch} />
            </Box>
            <Box
                style={{
                    position: 'absolute',
                    top: 15,
                    right: 0,
                    zIndex: 1,
                }}>
                <FullScreenButton
                    className="material-icons"
                    onClick={() => setFullScreen(!fullScreen)}>
                    {fullScreen ? 'close' : 'fullscreen'}
                </FullScreenButton>
            </Box>
            <Box width={1} height="100%">
                <Toolbar
                    mode={mode}
                    format={shapeOptions}
                    onChangeFormat={setShapeOptions}
                    onChangeMode={changeMode}
                />
                <ActionsToolbar
                    shape={shape}
                    onDelete={handleDelete}
                    onChange={handleNewShape}
                />
                <GoogleMap onMapsLoad={mapsLoaded} withDrawing />
            </Box>
        </Box>
    );
}
