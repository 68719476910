import * as constants from 'constants';

const place = (state = {}, action) => {
    let newState = Object.assign({}, state);

    switch (action.type) {
        case constants.GET_PLACE_SUCCESS:
            newState = action.place;
            return newState;
        case constants.RESET_PLACE:
            newState = {};
            return newState;
        default:
            return newState;
    }
};

export default place;
