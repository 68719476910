export const truncate = (source, size, trailing) => {
    if (source) {
        return source.length > size
            ? source.slice(0, size - 1) + (trailing ? trailing : '…')
            : source;
    }
};

export const trim = string => {
    if (string) {
        return string
            .toString()
            .replace(/(\r\n|\n|\r)/gm, '')
            .trim();
    } else {
        return string;
    }
};

export const capitalize = string =>
    string.charAt(0).toUpperCase() + string.slice(1);
