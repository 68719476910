import * as constants from 'constants';

const property = (state = {}, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case constants.SAVE_PROPERTY_SUCCESS:
            return {};

        case constants.FETCH_PROPERTY_SUCCESS:
            newState = action.payload;
            return newState;

        case constants.DELETE_PROPERTY_SUCCESS:
        case constants.RESET_PROPERTY:
            return {};

        case constants.FETCH_RELATED_PROPERTIES_SUCCESS:
            newState.related = action.relatedProperties;
            return newState;
        default:
            return state;
    }
};

export default property;
