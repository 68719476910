export const qualityConst = {
  1: 'Reasonable Quality',
  2: 'Good Quality',
  3: 'Very Good Quality',
  4: 'High Quality',
  5: 'Top Quality'
};
export const floorConst = {
  0: 'Ground Floor',
  1: '1st Floor',
  2: '2nd Floor',
  3: '3rd Floor',
  4: '4th Floor',
  5: '5th Floor',
  6: '6th Floor',
  7: '7th Floor',
  8: '8th Floor',
  9: '9th Floor',
  10: '10th Floor',
  11: '11th Floor',
  12: '12th Floor',
  13: '13th Floor',
  14: '14th Floor',
  15: '15th Floor',
  16: '16th Floor',
  17: '17th Floor',
  18: '18th Floor',
  19: '19th Floor',
  20: '20th Floor'
};

export const furnitureConst = {
  fully: 'Fully furnished',
  semi: 'Semi furnished',
  unfurnished: 'Unfurnished'
};

export const durationConst = {
  long: 'Long term',
  short: 'Short term'
};
