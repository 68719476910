import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import {
    ROOT_URL,
    FETCH_PLACES,
    SAVE_PLACE,
    GET_PLACE,
    DELETE_PLACE,
} from 'constants';
import {
    fetchPlaces,
    fetchPlacesSuccess,
    fetchPlacesFailure,
    savePlaceSuccess,
    savePlaceFailure,
    getPlaceSuccess,
    getPlaceFailure,
    deletePlaceSuccess,
    deletePlaceFailure,
    setBusy,
    setIdle,
} from 'actions';
import { push } from 'connected-react-router';

/***********************************
 *
 * Get places
 *
 **********************************/
function* getPlaces() {
    const current = yield select(state => state.places);
    if (current.length) {
        yield put(fetchPlacesSuccess(current));
    } else {
        try {
            const response = yield Axios.get(`${ROOT_URL}/place`);

            yield put(fetchPlacesSuccess(response.data));
        } catch (e) {
            console.log(e);
            yield put(fetchPlacesFailure(e));
        }
    }
}

/***********************************
 *
 * Get place
 *
 **********************************/
function* getPlace({ id, duplicate }) {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/place/${id}`);
        const obj = response.data;

        if (duplicate) {
            obj.id = undefined;
            obj.name = '';
        }
        yield put(
            getPlaceSuccess({
                ...obj,
            })
        );
    } catch (e) {
        console.log(e);
        yield put(getPlaceFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 *
 * Delete Place
 *
 **********************************/
function* deletePlace({ id }) {
    try {
        const response = yield Axios.delete(`${ROOT_URL}/place/${id}`);

        yield put(deletePlaceSuccess(id));
        yield put(push('/admin/places'));
    } catch (e) {
        console.log(e);
        yield put(deletePlaceFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_PLACES, getPlaces),
        takeLatest(GET_PLACE, getPlace),
        takeLatest(DELETE_PLACE, deletePlace),
    ]);
}
