import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ContactForm from './ContactForm';
import styled from 'styled-components';
import { Box } from 'reflexbox';

const Trigger = styled(Box)`
    padding: 3px 20px;
    color: ${({ light, theme }) => (light ? '#FFF' : theme.grey)};
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    border: 1px solid
        ${({ light, bordered }) =>
            bordered ? (light ? '#FFF' : '#999') : 'transparent'};
    border-radius: 50px;

    &:hover {
        background: ${({ light, theme }) => theme.gold};
        color: #fff;
        border-color: transparent;
    }
`;

const ContactModal = props => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            {props.trigger ? (
                React.cloneElement(props.trigger, { onClick: handleShow })
            ) : (
                <Trigger
                    onClick={handleShow}
                    fontSize={['12px', '13px']}
                    {...props}>
                    {props.label || 'Contact us'}
                </Trigger>
            )}

            <Modal
                show={show}
                onHide={handleClose}
                scrollable
                animation={false}
                className="contact-modal">
                <Modal.Header
                    style={{
                        borderBottom: '2px solid #CEC4AA',
                        background: '#FCFBF9',
                    }}>
                    <Box
                        className="material-icons"
                        style={{
                            cursor: 'pointer',
                            position: 'absolute',
                            right: 15,
                            top: 15,
                        }}
                        onClick={handleClose}>
                        close
                    </Box>
                    <Box p="5px 0px" width={1} textAlign="center">
                        <Box
                            fontSize="18px"
                            lineHeight="25px"
                            fontWeight="bold"
                            color="#808080"
                            mb={14}
                            mt={25}>
                            Interested in a property that suites your needs?
                        </Box>
                        <Box
                            fontSize="14px"
                            lineHeight="22px"
                            fontWeight="500"
                            color="#9F9F9F">
                            Contact us today and let us be at your disposal
                            <br />
                            Tel +972 (0)2 656 4322
                        </Box>
                    </Box>
                </Modal.Header>
                <Modal.Body>
                    <Box mt={3}>
                        <ContactForm handleClose={handleClose} />
                    </Box>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ContactModal;
