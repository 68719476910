import { all, takeLatest, select, put } from 'redux-saga/effects';
import {
    FETCH_TESTIMONIALS,
    FETCH_TESTIMONIAL,
    SAVE_TESTIMONIAL,
    DELETE_TESTIMONIAL,
    ROOT_URL,
} from 'constants';
import {
    fetchTestimonialsSuccess,
    fetchTestimonialsFailure,
    fetchTestimonialSuccess,
    fetchTestimonialFailure,
    saveTestimonialSuccess,
    saveTestimonialFailure,
    deleteTestimonialSuccess,
    deleteTestimonialFailure,
    setBusy,
    setIdle,
} from 'actions';
import { sortBy } from 'lodash';
import Axios from 'axios';
import { history } from '../history';
import { push } from 'connected-react-router';

/**
 *
 *
 *  Fetch all testimonials
 *
 *
 */
function* getTestimonials() {
    const current = yield select(state => state.testimonials);
    if (current.length) {
        yield put(fetchTestimonialsSuccess(current));
    } else {
        try {
            const response = yield Axios.get(`${ROOT_URL}/testimonials`);

            const asc = sortBy(response.data, [t => t.createdDate]);
            const sorted = asc.reverse();

            yield put(fetchTestimonialsSuccess(sorted));
        } catch (e) {
            console.log(e);
            yield put(fetchTestimonialsFailure(e));
        }
    }
}

/**
 *
 *
 *  Fetch one testimonial
 *
 *
 */
function* getTestimonial({ id }) {
    yield put(setBusy());
    try {
        const response = yield Axios.get(`${ROOT_URL}/testimonials/${id}`);
        yield put(fetchTestimonialSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchTestimonialFailure(e));
    }
    yield put(setIdle());
}

/**
 *
 *
 *  Save testimonial
 *
 *
 */
function* saveTestimonial({ testimonial }) {
    yield put(setBusy());

    try {
        const response = yield Axios.post(
            `${ROOT_URL}/testimonials/save`,
            testimonial
        );
        yield put(saveTestimonialSuccess(response.data));
        yield put(push('/admin/testimonials'));
    } catch (e) {
        console.log(e);
        yield put(saveTestimonialFailure(e));
    }
    yield put(setIdle());
}
/**
 *
 *
 *  Delete testimonial
 *
 *
 */
function* deleteTestimonial({ id }) {
    yield put(setBusy());
    try {
        yield Axios.delete(`${ROOT_URL}/testimonials/${id}`);
        yield put(deleteTestimonialSuccess(id));
        history.goBack();
    } catch (e) {
        console.log(e);
        yield put(deleteTestimonialFailure(e));
    }
    yield put(setIdle());
}

export default function* () {
    yield all([
        takeLatest(FETCH_TESTIMONIALS, getTestimonials),
        takeLatest(FETCH_TESTIMONIAL, getTestimonial),
        takeLatest(SAVE_TESTIMONIAL, saveTestimonial),
        takeLatest(DELETE_TESTIMONIAL, deleteTestimonial),
    ]);
}
