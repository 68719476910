import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import appSagas from './sagas';
import { history } from './history';
import appReducer from './reducers';

// load state from storage
const initState = {};

export default function configureStore() {
    /**
     * Initiate Saga middleware
     */
    const sagaMiddleware = createSagaMiddleware();

    /**
     * Create and enhancer that uses Redux Dev Tools in dev mode
     */
    const composeEnhancer =
        (process.env.NODE_ENV === 'development' &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
        compose;

    /**
     * Create store with reducers, initial state and middleware
     */
    const store = createStore(
        appReducer(null, initState),
        initState,
        composeEnhancer(
            applyMiddleware(routerMiddleware(history), sagaMiddleware)
        )
    );

    sagaMiddleware.run(appSagas);

    if (module.hot) {
        // Reducers hot reloading
        module.hot.accept('./reducers', () => {
            // eslint-disable-next-line global-require
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer, store.getState());
        });
    }

    return store;
}
