import * as constants from '../constants';

export const fetchArticles = () => ({
    type: constants.FETCH_ARTICLES,
});

export function fetchArticlesSuccess(articles) {
    return {
        type: constants.FETCH_ARTICLES_SUCCESS,
        payload: articles,
    };
}

export function fetchArticlesFailure(error) {
    return {
        type: constants.FETCH_ARTICLES_FAILURE,
        payload: error,
    };
}

export const getArticle = id => ({
    type: constants.GET_ARTICLE,
    id,
});

export function getArticleSuccess(article) {
    return {
        type: constants.GET_ARTICLE_SUCCESS,
        article,
    };
}

export function getArticleFailure(error) {
    return {
        type: constants.GET_ARTICLE_FAILURE,
        error,
    };
}

export function resetArticle() {
    return {
        type: constants.RESET_ARTICLE,
    };
}

export const saveArticle = article => ({
    type: constants.SAVE_ARTICLE,
    article,
});

export function saveArticleSuccess(article) {
    return {
        type: constants.SAVE_ARTICLE_SUCCESS,
        article,
    };
}

export function saveArticleFailure(error) {
    return {
        type: constants.SAVE_ARTICLE_FAILURE,
        payload: error,
    };
}

export const deleteArticle = id => ({
    type: constants.DELETE_ARTICLE,
    id,
});

export function deleteArticleSuccess() {
    return {
        type: constants.DELETE_ARTICLE_SUCCESS,
    };
}

export function deleteArticleFailure(error) {
    return {
        type: constants.DELETE_ARTICLE_FAILURE,
        payload: error,
    };
}

export const getArticleTags = () => ({
    type: constants.GET_ARTICLE_TAGS,
});

export function getArticleTagsSuccess(tags) {
    return {
        type: constants.GET_ARTICLE_TAGS_SUCCESS,
        tags,
    };
}

export function getArticleTagsFailure(error) {
    return {
        type: constants.GET_ARTICLE_TAGS_FAILURE,
        error,
    };
}
