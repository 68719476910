export const STATUSES = [
    { value: 'listed', label: 'Newly Listed' },
    { value: 'toVisit', label: 'To visit' },
    { value: 'underChecking', label: 'Under checking' },
    { value: 'Visited', label: 'Visited' },
    { value: 'irrelevant', label: 'Irrelevant' },
    { value: 'available', label: 'Available' },
    { value: 'onHold', label: 'On Hold' },
    { value: 'underCoordination', label: 'Under Coordination' },
    { value: 'toVisitLater', label: 'To Visit Later' },
    { value: 'pending', label: 'Pending' },
    { value: 'underNegotiation', label: 'Under Negotiation' },
];

export const RELEVANCE = [
    {
        id: 'highlyInteresting',
        title: 'Highly interesting',
    },
    { id: 'potential', title: 'Potential' },
    { id: 'worthVisiting', title: 'Worth visiting' },
    {
        id: 'notInteresting',
        title: 'Not interesting',
    },
];
