import * as constants from 'constants';

const article = (state = {}, action) => {
    let newState = Object.assign({ tags: [] }, state);

    switch (action.type) {
        case constants.GET_ARTICLE_SUCCESS:
            newState = action.article;
            return newState;
        case constants.RESET_ARTICLE:
            return {};
        default:
            return newState;
    }
};

export default article;
