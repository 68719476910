import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { setMapMarker } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { Box } from 'reflexbox';

const GroupHeading = props => <Box p="12px" color="#c69528" {...props} />;

const Styles = {
    control: (provided, state) => {
        return {
            ...provided,
            minHeight: 0,
            minWidth: 280,
            boxShadow: null,
            color: '#666',
            cursor: 'text',
            borderColor: '#E8E8E8',
            borderRadius: 5,
            height: '46px',
            backgroundColor: '#F7F7F7',
            fontFamily: 'Open Sans',
            paddingLeft: 30,
        };
    },
    placeholder: (provided, state) => ({
        ...provided,
        color: '#848073',
        fontSize: '12px',
        fontFamily: 'Open Sans',
    }),
    loadingMessage: provided => ({
        ...provided,
        textAlign: 'left',
    }),
    menu: provided => ({
        ...provided,
        marginTop: 19,
        marginLeft: '1px',
        width: 'calc(100% - 2px)',
        borderRadius: 10,
        overflow: 'hidden',
        textAlign: 'left',
    }),
    option: (provided, { isSelected, isFocused, isActive }) => {
        return {
            ...provided,
            background: isSelected
                ? '#FBC92E'
                : isFocused || isActive
                ? '#ccc'
                : '#FFF',
            color: isSelected || isFocused ? '#FFF' : 'inherit',
            margin: '2px 7px',
            width: 'auto',
            borderRadius: '5px',
            cursor: 'pointer',
        };
    },
    multiValue: provided => ({
        ...provided,
        backgroundColor: '#FFF',
        borderRadius: `50px`,
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: '#676767',
    }),
    multiValueRemove: provided => ({
        ...provided,
        color: '#ccc',
        cursor: 'pointer',
        ':hover': {
            color: '#333',
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: '5px',
        background: 'none',
        cursor: 'pointer',
    }),
};

export const BaseSearchComponent = props => {
    const {
        styles,
        value,
        placeholder = 'Search...',
        onSelect,
        withIcon,
    } = props;
    const { properties, neighborhoods, places } = useSelector(state => state);

    const handleSearch = (input, cb) => {
        if (input && input.length >= 2) {
            Axios.get(`/api/placesSearch?query=${encodeURI(input)}`).then(
                response => {
                    cb([
                        {
                            label: 'Neighborhoods',
                            options: neighborhoods
                                .map(p => {
                                    if (
                                        p.location &&
                                        p.title
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    ) {
                                        return {
                                            name: p.title,
                                            markerType: 'neighborhood',
                                            ...p,
                                        };
                                    }
                                })
                                .filter(Boolean),
                        },
                        {
                            label: 'Surroundings',
                            options: places
                                .map(p => {
                                    if (
                                        p.title
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >=
                                            0 &&
                                        !p.type?.includes('misc')
                                    ) {
                                        return {
                                            name: p.title,
                                            markerType: 'place',
                                            ...p,
                                        };
                                    }
                                })
                                .filter(Boolean),
                        },
                        {
                            label: 'Google',
                            options: response.data,
                        },
                        {
                            label: 'Properties',
                            options: properties
                                .map(p => {
                                    if (
                                        p.published &&
                                        (p.title
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >=
                                            0 ||
                                            `${p.propId}`.indexOf(input) >= 0)
                                    ) {
                                        return {
                                            name: `ID${p.propId}-${p.title}`,
                                            markerType: 'property',
                                            ...p,
                                        };
                                    }
                                })
                                .filter(Boolean),
                        },
                    ]);
                }
            );
        }
    };
    return (
        <Box style={{ position: 'relative' }}>
            {withIcon ? (
                <Box
                    className="material-icons"
                    color="#CCC"
                    ml="8px"
                    style={{
                        position: 'absolute',
                        zIndex: 1,
                        top: '50%',
                        transform: 'translateY(-50%)',
                    }}>
                    search
                </Box>
            ) : undefined}
            <AsyncSelect
                cacheOptions
                isClearable
                loadOptions={handleSearch}
                noOptionsMessage={() => null}
                styles={styles}
                onChange={onSelect}
                getOptionLabel={option => {
                    return option.name;
                }}
                value={value}
                getOptionValue={option => {
                    return option.reference || option.id;
                }}
                components={{
                    DropdownIndicator: () => null,
                    DownChevron: () => {},
                    IndicatorSeparator: () => null,
                    GroupHeading: GroupHeading,
                }}
                placeholder={placeholder}
            />
        </Box>
    );
};

function PlacesSearch(props) {
    const { marker } = useSelector(state => state.map);

    const [selected, setSelected] = useState(marker);

    const dispatch = useDispatch();

    const handleSelect = option => {
        if (option && option.geometry && option.geometry.location)
            option.location = option.geometry.location;
        setSelected(option);
        dispatch(setMapMarker(option));
    };

    useEffect(() => {
        return () => {
            dispatch(setMapMarker());
        };
    }, []);
    return (
        <BaseSearchComponent
            value={selected}
            onSelect={handleSelect}
            styles={Styles}
            withIcon={props.withIcon}
        />
    );
}

export default React.memo(PlacesSearch);
