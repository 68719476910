export const OPTIONS = {
    STROKE_WEIGHT: 'strokeWeight',
    STROKE_COLOR: 'strokeColor',
    STROKE_OPACITY: 'strokeOpacity',
    FILL_COLOR: 'fillColor',
    FILL_OPACITY: 'fillOpacity',
};

export const SHAPE_OPTIONS = {
    [OPTIONS.FILL_COLOR]: '#ffff00',
    [OPTIONS.FILL_OPACITY]: 0.6,
    [OPTIONS.STROKE_WEIGHT]: 2,
    [OPTIONS.STROKE_COLOR]: '#999999',
    [OPTIONS.STROKE_OPACITY]: 1,
    draggable: true,
    editable: true,
    clickable: true,
};

export const MODES = {
    POLYGON: 'polygon',
    POLYLINE: 'polyline',
    CIRCLE: 'circle',
    RECTANGLE: 'rectangle',
};

export const SHAPES = [
    {
        value: 'polygon',
        label: 'Polygon',
    },
    {
        value: 'polyline',
        label: 'Polyline',
    },
    {
        value: 'circle',
        label: 'Circle',
    },
    {
        value: 'rectangle',
        label: 'Rectangle',
    },
];
