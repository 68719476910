import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense, useEffect } from 'react';
import './styles/main.scss';
// import 'react-tippy/dist/tippy.css';
// import 'tippy.js/dist/tippy.css';
// import 'tippy.js/themes/light.css';
import { getArticleTags, getCurrencies, getCurrentUser } from 'actions';
import AppLayout from 'layouts/AppLayout';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// eslint-disable-next-line import/no-cycle
import Routes from './Routes';
import { FloatingButton } from './components/FloatingButton/index';
import Map from './components/map';
import Loading from './elements/Loading';

const LANDING_PAGES = ['/whyjr', '/retal_east_jerusalem'];

// eslint-disable-next-line react/function-component-definition
const App = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.innerWidth <= 960) {
            // eslint-disable-next-line global-require
            require('styles/mobile.scss');
        }
        dispatch(getCurrentUser());
        dispatch(getCurrencies());
        dispatch(getArticleTags());
    }, []);

    const { pathname } = useLocation();

    const isLanding = LANDING_PAGES.includes(pathname);

    return (
        <Suspense fallback={<div className="suspense">loading....</div>}>
            <AppLayout isLanding={isLanding}>
                <Routes store={props.store} />
                <Loading />
                <Map />
                <FloatingButton />
            </AppLayout>
        </Suspense>
    );
};

export default App;
