import * as constants from '../constants';

export const saveTestimonial = testimonial => ({
    type: constants.SAVE_TESTIMONIAL,
    testimonial,
});

export const saveTestimonialSuccess = testimonial => ({
    type: constants.SAVE_TESTIMONIAL_SUCCESS,
    testimonial,
});

export const saveTestimonialFailure = error => ({
    type: constants.SAVE_TESTIMONIAL_FAILURE,
    error,
});

export const fetchTestimonial = id => ({
    type: constants.FETCH_TESTIMONIAL,
    id,
});

export const fetchTestimonialSuccess = testimonial => ({
    type: constants.FETCH_TESTIMONIAL_SUCCESS,
    testimonial,
});

export const fetchTestimonialFailure = error => ({
    type: constants.FETCH_TESTIMONIAL_FAILURE,
    error,
});

export const deleteTestimonial = id => ({
    type: constants.DELETE_TESTIMONIAL,
    id,
});

export const deleteTestimonialSuccess = id => ({
    type: constants.DELETE_TESTIMONIAL_SUCCESS,
    id,
});

export const deleteTestimonialFailure = error => ({
    type: constants.DELETE_TESTIMONIAL_FAILURE,
    error,
});

export const fetchTestimonials = () => ({
    type: constants.FETCH_TESTIMONIALS,
});

export const fetchTestimonialsSuccess = testimonials => ({
    type: constants.FETCH_TESTIMONIALS_SUCCESS,
    testimonials,
});

export const fetchTestimonialsFailure = error => ({
    type: constants.FETCH_TESTIMONIALS_FAILURE,
    error,
});

export const resetTestimonial = () => ({
    type: constants.RESET_TESTIMONIAL,
});
