import React from 'react';
import Button from 'react-bootstrap/Button';
import { Box } from 'reflexbox';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
    border-radius: 20px;
    font-size: 14px;
    width: 110px;
    box-shadow: none !important;
    border-color: transparent !important;
    &,
    &:focus {
        background: ${({ theme }) => theme.gold} !important;
        color: white;
    }
    cursor: pointer;
    font-weight: bold;
    width: ${({ big }) => (big ? '225px' : '100px')};
`;

export const StyledCancel = styled(Button)`
    background: #fff;
    font-size: 14px;
    font-weight: bold;
    color: ${({ theme }) => theme.gold} !important;
    width: 110px;
    border-color: transparent !important;
    &:hover,
    &:focus,
    &:active {
        background: #fff !important;
        text-decoration: underline;
    }
`;

export const StyledInput = styled.input`
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    width: 100%;
    border: 0px;
    border: 1px solid ${({ footer }) => (footer ? '#b1b1b1' : 'transparent')};
    border-bottom-color: ${({ footer, theme }) =>
        footer ? '#b1b1b1' : '#E2E2E2'};
    text-indent: ${({ footer }) => (footer ? '10px' : '0px')};
    border-radius: ${({ footer, radius }) => (!footer ? 0 : radius || 20)}px;
    height: ${({ height, footer }) => height || (footer ? '43px' : '24px')};
    outline: none;
    background: #fff;
    margin-bottom: 28px;
    color: ${({ error }) => (error ? 'red' : 'inherit')};
    &::placeholder {
        color: ${({ footer }) => (footer ? '#6D6756' : '#707070')};
        font-size: 14px;

        line-height: 26px;
    }

    ${({ error }) => {}}
`;

const StyledError = styled.span`
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: red;
    position: absolute;
    right: 0;
    top: 0;
`;

export const ContactFormInput = ({ error, comp, ...props }) => {
    return (
        <Box style={{ position: 'relative' }}>
            <StyledInput as={comp} {...props} />
            {error ? <StyledError>{error}</StyledError> : undefined}
        </Box>
    );
};
