import * as constants from 'constants';

const customMaps = (state = [], action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_CUSTOM_MAPS_SUCCESS:
            newState = action.payload;
            return newState;
        case constants.SAVE_CUSTOM_MAP_SUCCESS:
            const customMap = action.customMap;
            const found = newState.find(p => p.id === customMap.id);
            if (found) {
                return newState.map(p =>
                    p.id === customMap.id ? customMap : p
                );
            } else {
                return newState.concat(customMap);
            }
        case constants.DELETE_CUSTOM_MAP_SUCCESS:
            return newState.filter(p => p.id !== action.id);
        default:
            return newState;
    }
};

export default customMaps;
