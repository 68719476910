import React from 'react';
import styled from 'styled-components';
import Availability from 'components/common/tile/Availability.js';
import { Box, Flex } from 'reflexbox';
import Price from '../MarkerPrice';
import furniture from '../../../components/common/Furniture';
import Quality from '../../../components/common/Quality';
import { useSelector } from 'react-redux';

const Wrapper = styled(Box)`
    margin: 8px 0px;
`;

const Specs = styled(Flex)`
    padding: 10px 15px;
    border-radius: 0px 0px 10px 10px;
    background: #f7f7f7;
`;

const Specs2 = styled(Flex)`
    padding-left: 5px;
    padding-bottom: 10px;
    align-items: center;
`;

const Value = styled.div`
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 2px;
    white-space: nowrap;
`;
export const Label = styled.div`
    color: #acacac;
    font-size: 11px;
`;
const StyledPrice = styled(Box)`
    color: ${({ striked }) => (striked ? '#E33030' : '#19a99d')};
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    // align-self: center;
    text-decoration: ${({ striked }) => (striked ? 'line-through' : 'none')};
`;
const Additional = styled.div`
    font-size: 11px;
    line-height: 13px;
    color: #888888;
    font-weight: bold;
    text-transform: capitalize;
    &:not(:last-child):after {
        content: '.';
        padding: 8px;
    }
`;

function PropAttributes(props) {
    const {
        bedrooms,
        bathrooms,
        type,
        neighborhood,
        size,
        floors,
        apartments,
        furnitureStatus,
        durations = [],
        quality,
        price,
        priceComment,
        currency = 'USD',
        status,
        availableOn,
        globalCurrency,
        discountedPrice,
        negotiable,
    } = props;
    console.log(status);
    const isCustomMap =
        useSelector(state => state?.customMap?.object?.id) != null;

    const isBuilding =
        type && type.title && type.title.indexOf('Apartments Building') === 0;

    const getAttribute = (val, title, isList) => {
        if (val) {
            return (
                <Box mr={4}>
                    <Value isList={isList}>{val}</Value>
                    <Label isList={isList}>{title}</Label>
                </Box>
            );
        }
    };

    return (
        <Wrapper>
            <Specs alignItems="flex-start">
                {getAttribute(bedrooms, 'Beds')}
                {!isCustomMap ? getAttribute(bathrooms, 'Baths') : undefined}
                {getAttribute(size, 'Sqr')}
                {getAttribute(floors, 'Floors')}
                {getAttribute(apartments, 'Apartments')}
                {neighborhood
                    ? getAttribute(neighborhood.title, 'Neighborhood')
                    : undefined}
                {getAttribute(furniture(furnitureStatus), 'Furniture')}
                {!isBuilding ? (
                    <Availability status={status} availableOn={availableOn} />
                ) : undefined}
                <Flex ml="auto" flexDirection="column" alignItems="flex-end">
                    {price ? (
                        <StyledPrice striked={discountedPrice}>
                            <Price
                                amount={price}
                                currency={currency}
                                globalCurrency={globalCurrency}
                            />
                            /m
                        </StyledPrice>
                    ) : undefined}
                    {discountedPrice ? (
                        <StyledPrice>
                            <Price
                                amount={discountedPrice}
                                currency={currency}
                                globalCurrency={globalCurrency}
                            />
                            /m
                        </StyledPrice>
                    ) : undefined}
                    {!isCustomMap && priceComment ? (
                        <Box
                            fontSize="0.9em"
                            color="#acacac"
                            mt="2px"
                            style={{
                                textAlign: 'right',
                            }}>
                            {priceComment}
                        </Box>
                    ) : undefined}

                    {negotiable ? (
                        <Box
                            fontSize="0.9em"
                            color="#acacac"
                            mt="2px"
                            style={{
                                textAlign: 'right',
                            }}>
                            Negotiable
                        </Box>
                    ) : undefined}
                </Flex>
            </Specs>
            <Specs2 mt={3}>
                {!isBuilding && furnitureStatus && !isCustomMap ? (
                    <Additional>{furniture(furnitureStatus)}</Additional>
                ) : undefined}
                {quality ? (
                    <Additional>{Quality(quality)}</Additional>
                ) : undefined}
                {durations.length
                    ? durations.map(d => <Additional key={d}>{d}</Additional>)
                    : undefined}
            </Specs2>
        </Wrapper>
    );
}

export default PropAttributes;
