/**
 * Find in array
 *
 */
export function contains(arr, fieldValue, fieldName) {
    if (fieldValue === undefined || !fieldName) return;

    let nan = fieldValue !== fieldValue;

    if (
        fieldName === 'price' ||
        fieldName === 'size' ||
        fieldName === 'availableOn'
    ) {
        return fieldValue <= arr[1] && fieldValue >= arr[0];
    }

    let found = false;
    if (!nan && arr.length > 0) {
        for (var i = 0; i < arr.length; i++) {
            let comparator = arr[i];

            if (Array.isArray(fieldValue)) {
                // .id
                // ? fieldValue.findIndex(f => f.id === Number(comparator))
                // :
                const index = fieldValue.indexOf(
                    !isNaN(Number(comparator)) ? Number(comparator) : comparator
                );
                if ((index && index.id) || index >= 0) {
                    found = true;
                    continue;
                }
            }

            if (typeof fieldValue === 'number') {
                comparator = Number(
                    comparator.indexOf('~') > 0
                        ? comparator.replace('~', '')
                        : comparator
                );
            }

            if (comparator === fieldValue) found = true;
            continue;
        }
    }

    return found;
}
