import React from 'react';
import PropertyContent from './PropertyContent';
import LocationContent from './LocationContent';

const MarkerContent = props => {
    const isProperty = !!props.propId;

    return isProperty ? (
        <PropertyContent {...props} />
    ) : (
        <LocationContent {...props} />
    );
};

export default MarkerContent;
