import { all, select, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import {
    ROOT_URL,
    FETCH_ARTICLES,
    // SAVE_ARTICLE,
    GET_ARTICLE,
    DELETE_ARTICLE,
} from 'constants';
import {
    fetchArticlesSuccess,
    fetchArticlesFailure,
    // saveArticleSuccess,
    // saveArticleFailure,
    getArticleSuccess,
    getArticleFailure,
    deleteArticleSuccess,
    deleteArticleFailure,
    setBusy,
    setIdle,
} from 'actions';
import { push } from 'connected-react-router';
import moment from 'moment';
/***********************************
 *
 * Get articles
 *
 **********************************/
function* getArticles() {
    try {
        yield put(setBusy());
        const response = yield Axios.get(`${ROOT_URL}/articles`);

        yield put(fetchArticlesSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(fetchArticlesFailure(e));
    }
    yield put(setIdle());
}

/***********************************
 *
 * Get article
 *
 **********************************/
function* getArticle({ id }) {
    if (id === 'new') return {};
    try {
        const response = yield Axios.get(`${ROOT_URL}/articles/${id}`);
        const article = response.data;
        if (article.publishDate)
            article.publishDate = moment(article.publishDate).unix() * 1000;
        yield put(getArticleSuccess(article));
    } catch (e) {
        console.log(e);
        yield put(getArticleFailure(e));
    }
}

/***********************************
 *
 * Save article
 *
 **********************************/
// function* saveArticle() {
//     try {
//         const response = yield Axios.post(`${ROOT_URL}/articles/save`);

//         yield put(saveArticleSuccess(response.data));
//     } catch (e) {
//         console.log(e);
//         yield put(saveArticleFailure(e));
//     }
// }

/***********************************
 *
 * Delete article
 *
 **********************************/
function* deleteArticle({ id }) {
    try {
        const response = yield Axios.delete(`${ROOT_URL}/articles/${id}`);

        yield put(deleteArticleSuccess(response.data));
        yield put(push('/admin/articles'));
    } catch (e) {
        console.log(e);
        yield put(deleteArticleFailure(e));
    }
}

export default function* () {
    yield all([
        takeLatest(FETCH_ARTICLES, getArticles),
        takeLatest(GET_ARTICLE, getArticle),
        // takeLatest(SAVE_ARTICLE, saveArticle),
        takeLatest(DELETE_ARTICLE, deleteArticle),
    ]);
}
