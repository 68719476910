import React, { useState, useEffect } from 'react';
import ImageUpload from 'elements/ImageUpload';
import { useSelector, shallowEqual } from 'react-redux';
import { Button } from 'react-bootstrap';
import Axios from 'axios';
import { Box } from 'reflexbox';

function HomepageBannerEdit(props) {
    const bannerImage = useSelector(state => state.app.bannerUrl, shallowEqual);
    const [bannerUrl, setBannerUrl] = useState(bannerImage);
    const [saving, setSaving] = useState(false);
    const [changed, setChanged] = useState(false);

    useEffect(() => {
        Axios.get('/banneredit').then(res => setBannerUrl(res.data));
    }, []);

    const handleUpload = image => {
        setBannerUrl(image);
        setChanged(true);
    };

    const handleSave = async () => {
        setSaving(true);
        const res = await Axios.post('/banneredit', { imageUrl: bannerUrl });
        if (res.data === 'done') {
            setChanged(false);
        }
    };

    const handleRemove = () => setBannerUrl();

    return (
        <Box pt={6}>
            <Box minHeight={340} textAlign="center">
                {bannerUrl ? (
                    <>
                        <img
                            src={bannerUrl}
                            style={{
                                maxWidth: '800px',
                                maxHeight: '340px',
                                display: 'block',
                                margin: '20px auto',
                            }}
                        />
                        <Button
                            variation="secondary"
                            size="small"
                            onClick={handleRemove}>
                            Change
                        </Button>
                        {changed ? (
                            <Button onClick={handleSave} disabled={saving}>
                                Save
                            </Button>
                        ) : undefined}
                    </>
                ) : (
                    <ImageUpload
                        value={bannerUrl || ''}
                        onChange={handleUpload}
                        single
                        disabled={saving}
                    />
                )}
            </Box>
        </Box>
    );
}

export default HomepageBannerEdit;
