import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

const Droplist = ({
    label,
    error,
    options,
    value,
    valueKey,
    labelKey,
    ...props
}) => {
    const getValue = val => {
        if (!val) return null;

        if (Array.isArray(val)) {
            return val.map(v => getValue(v));
        }
        if (typeof val === 'string' || typeof val === 'number') {
            let optionsList = options;
            // Check if options are grouped
            if (options.length && options[0].options) {
                optionsList = options.reduce((arr, current) => {
                    const prev = arr.options
                        ? arr.options
                        : Array.isArray(arr)
                        ? arr
                        : [arr]; // first option might not be an array
                    const next = current.options || [current];
                    return [...prev, ...next];
                });
            }

            const valueFromOptions = optionsList.filter(
                option => `${option[valueKey]}` === `${val}`
            );

            if (valueFromOptions[0]) {
                const v = valueFromOptions[0];
                return { ...v, value: v[valueKey], label: v[labelKey] };
            }
        }

        return { ...val, value: val[valueKey], label: val[labelKey] };
    };

    return (
        <Form.Group>
            {label ? <Form.Label>{label}</Form.Label> : undefined}
            <Select
                {...props}
                options={options}
                value={getValue(value)}
                getOptionLabel={option => option[labelKey]}
                getOptionValue={option => option[valueKey]}
                closeMenuOnSelect={!props.isMulti}
                styles={{
                    menu: provided => ({
                        ...provided,
                        zIndex: 9,
                    }),
                    control: provided => ({
                        ...provided,
                        borderColor: error ? '#dc3545' : '#ced4da',
                    }),
                    ...props.styles,
                }}
            />
            <div
                className="text-danger"
                style={{ fontSize: '0.8em', marginTop: 2 }}>
                {error}
            </div>
        </Form.Group>
    );
};

Droplist.defaultProps = {
    valueKey: 'value',
    labelKey: 'label',
};
export default Droplist;
