import React from 'react';
import { Box, Flex } from 'reflexbox';
import { useDispatch } from 'react-redux';
import { messagesReply } from 'actions';
import MessagesDisplay from '../../../components/customMap/editComponents/MessagesDisplay';
import { DynamicHeightInput } from '../../../components/common/DynamicHeightInput';

const PropertyPopupMessages = ({ show, property }) => {
    const ref = React.useRef(null);

    const dispatch = useDispatch();

    const sendMessage = message => {
        dispatch(messagesReply(message, property.id));
    };

    return (
        <Flex
            width={270}
            pt={3}
            flexDirection="column"
            maxHeight={560}
            height="100%">
            <Box fontWeight="500" fontSize="18px" mb={3} pl={2} height={50}>
                Messages
            </Box>
            <Box
                flexGrow={1}
                maxHeightX={show ? 440 : 0}
                overflowY="auto"
                ref={ref}>
                <MessagesDisplay property={property} parentRef={ref} />
            </Box>
            <Box mt="auto">
                <DynamicHeightInput
                    placeholder="Reply to Tarek..."
                    onSend={sendMessage}
                />
            </Box>
        </Flex>
    );
};

export default PropertyPopupMessages;
