import * as constants from 'constants';

const customers = (state, action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_CUSTOMERS_SUCCESS:
            newState = action.customers;
            return newState;
        // case constants.SAVE_CUSTOMER_SUCCESS:
        //     const customer = action.customer;
        //     const found = newState.find(t => t.id === customer.id);
        //     if (found) {
        //         return newState.map(t => (t.id === customer.id ? customer : t));
        //     } else {
        //         return newState.concat(customer);
        //     }

        // case constants.DELETE_CUSTOMER_SUCCESS:
        //     return newState.filter(p => p.id !== action.id);

        default:
            return state || {};
    }
};

export default customers;
