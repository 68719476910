function RandomID(length){
    let text = "";
    let chars = "abcdefghijklmnopqrstuvwxyz0123456789";

    while(text.length < length){
      text += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return text;
  }

export default RandomID;