import * as constants from 'constants';

const testimonials = (state, action) => {
    let newState = Object.assign([], state);

    switch (action.type) {
        case constants.FETCH_TESTIMONIALS_SUCCESS:
            newState = action.testimonials;
            return newState;
        case constants.SAVE_TESTIMONIAL_SUCCESS:
            const testimonial = action.testimonial;
            const found = newState.find(t => t.id === testimonial.id);
            if (found) {
                return newState.map(t =>
                    t.id === testimonial.id ? testimonial : t
                );
            } else {
                return newState.concat(testimonial);
            }

        case constants.DELETE_TESTIMONIAL_SUCCESS:
            return newState.filter(p => p.id !== action.id);

        default:
            return state || {};
    }
};

export default testimonials;
