import React, { useState, useEffect } from 'react';
import { Box, Flex } from 'reflexbox';
import FormatToolbar from './FormatToolbar';
import { MODES } from './constants';
import ToolbarButton from './ToolbarButton';

export default function Toolbar({
    onChangeMode,
    onChangeFormat,
    format,
    mode,
}) {
    return (
        <Flex
            p={2}
            bg="#FFFFFFEE"
            flexDirection={'column'}
            justifyContent={'center'}
            style={{
                display: 'inline-flex',
                borderRadius: 50,
                position: 'absolute',
                zIndex: 1,
                top: 10,
                left: 10,
            }}>
            <ToolbarButton
                selected={!mode}
                onClick={() => onChangeMode()}
                mb={2}>
                pan_tool_alt
            </ToolbarButton>
            <ToolbarButton
                selected={mode === MODES.POLYGON}
                onClick={() => onChangeMode(MODES.POLYGON)}
                mb={2}>
                pentagon
            </ToolbarButton>
            <ToolbarButton
                selected={mode === MODES.RECTANGLE}
                onClick={() => onChangeMode(MODES.RECTANGLE)}
                mb={2}>
                rectangle
            </ToolbarButton>
            <ToolbarButton
                selected={mode === MODES.CIRCLE}
                onClick={() => onChangeMode(MODES.CIRCLE)}
                mb={2}>
                circle
            </ToolbarButton>
            <ToolbarButton
                selected={mode === MODES.POLYLINE}
                onClick={() => onChangeMode(MODES.POLYLINE)}>
                polyline
            </ToolbarButton>
            <FormatToolbar format={format} onChangeFormat={onChangeFormat} />
        </Flex>
    );
}
