import React from 'react';
import { Tooltip } from 'react-tippy';
import { Box } from 'reflexbox';

function Recommended() {
    return (
        <Box style={{ position: 'absolute', bottom: 5, right: 15 }}>
            <Tooltip
                title={'Highly recommended'}
                trigger="mouseenter"
                delay={600}>
                <Box style={{ position: 'relative' }}>
                    <Box
                        className="material-icons"
                        color="#ACA683"
                        fontSize="26px">
                        bookmark
                    </Box>
                    <Box
                        className="material-icons"
                        color="#FFFFFF"
                        fontSize="12px"
                        style={{
                            position: 'absolute',
                            left: '50%',
                            top: '35%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                        star
                    </Box>
                </Box>
            </Tooltip>
        </Box>
    );
}

export default Recommended;
