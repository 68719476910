export const Styles = {
    control: (provided, state) => {
        return {
            ...provided,
            minHeight: 0,
            width: '100%',
            minWidth: '300px',
            boxShadow: null,
            color: '#666',
            cursor: 'text',
            borderColor: '#D0D0D0',
            borderRadius: 50,
            height: '30px',
            fontFamily: 'Open Sans',
        };
    },
    placeholder: (provided, state) => ({
        ...provided,
        color: '#848073',
        fontSize: '12px',
        fontFamily: 'Open Sans',
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: '0px 8px',
    }),
    loadingMessage: provided => ({
        ...provided,
        textAlign: 'left',
    }),
    menu: provided => ({
        ...provided,
        marginTop: 0,
        marginLeft: '1px',
        width: 'calc(100% - 2px)',
        borderRadius: 10,
        overflow: 'hidden',
        textAlign: 'left',
    }),
    option: (provided, { isSelected, isFocused, isActive }) => {
        return {
            ...provided,
            background: isSelected
                ? '#FBC92E'
                : isFocused || isActive
                ? '#ccc'
                : '#FFF',
            color: isSelected || isFocused ? '#FFF' : 'inherit',
            margin: '2px 7px',
            width: 'auto',
            borderRadius: '5px',
            cursor: 'pointer',
        };
    },
    multiValue: provided => ({
        ...provided,
        backgroundColor: '#FFF',
        borderRadius: `50px`,
    }),
    multiValueLabel: provided => ({
        ...provided,
        color: '#676767',
    }),
    multiValueRemove: provided => ({
        ...provided,
        color: '#ccc',
        cursor: 'pointer',
        ':hover': {
            color: '#333',
        },
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: '5px',
        background: 'none',
        cursor: 'pointer',
    }),
};
