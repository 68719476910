import * as constants from '../constants';

export const saveProperty = property => ({
    type: constants.SAVE_PROPERTY,
    property,
});

export const savePropertySuccess = newProperty => ({
    type: constants.SAVE_PROPERTY_SUCCESS,
    property: newProperty,
});

export const savePropertyFailure = error => ({
    type: constants.SAVE_PROPERTY_FAILURE,
    error,
});

export const resetProperty = () => ({
    type: constants.RESET_PROPERTY,
});

export const fetchProperty = (id, isDuplicate) => ({
    type: constants.FETCH_PROPERTY,
    id,
    isDuplicate,
});

export const fetchPropertySuccess = activeProperty => ({
    type: constants.FETCH_PROPERTY_SUCCESS,
    payload: activeProperty,
});

export const fetchPropertyFailure = error => ({
    type: constants.FETCH_PROPERTY_FAILURE,
    payload: error,
});

export const deleteProperty = id => ({
    type: constants.DELETE_PROPERTY,
    id,
});

export const deletePropertySuccess = id => {
    return {
        type: constants.DELETE_PROPERTY_SUCCESS,
        id,
    };
};

export function deletePropertyFailure(error) {
    return {
        type: constants.DELETE_PROPERTY_FAILURE,
        error,
    };
}

export const updateFeaturedProperty = (id, location) => ({
    type: constants.UPDATE_FEATURED_PROPERTY,
    id,
    location,
});

export const updateFeaturedPropertySuccess = newProperty => ({
    type: constants.UPDATE_FEATURED_PROPERTY_SUCCESS,
    property: newProperty,
});

export const updateFeaturedPropertyFailure = error => ({
    type: constants.UPDATE_FEATURED_PROPERTY_FAILURE,
    error,
});
