import React, { useState } from 'react';
import axios from 'axios';
import { ProgressBar } from 'react-bootstrap';
import { trim } from '../utils/StringUtils';
import styled from 'styled-components';
import RandomID from '../utils/Random';

const UploadWrapper = styled.label`
    background: ${({ active }) => (active ? '#a8c1e3' : '#dde9fa')};
    height: 125px;
    width: 150px;
    display: inline-block;
    border: 2px dashed #ccc;
    font-size: 13px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin: 0 4px;

    input {
        position: absolute;
        visibility: hidden;
        outline: none;
        left: 0;
    }
`;

const FileUpload = props => {
    const [complete, setComplete] = useState(0);
    const [loading, setLoading] = useState(false);
    const uploadID = RandomID(6);
    const [isActive, setActive] = useState(false);

    const onDrop = async files => {
        if (props.startLoading) props.startLoading();

        let UploadUrl = await axios.get('/upload');

        setLoading(true);

        if (process.env.NODE_ENV === 'development') {
            UploadUrl = UploadUrl.data.replace('3001', '3000');
        } else {
            UploadUrl = UploadUrl.data;
        }

        var data = new FormData();
        for (let i = 0; i < files.length; i++) {
            data.append('imageUrl', files[i]);
        }

        var config = {
            onUploadProgress: function (progressEvent) {
                var percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setComplete(percentCompleted);
            },
        };

        const resp = await axios.post(UploadUrl, data, config);

        let urls = [];
        if (resp.data.indexOf(',') > 0) {
            //we got more than one
            resp.data.split(',').map(url => {
                urls.push(trim(url));
            });
        } else {
            urls.push(resp.data);
        }

        setLoading(false);

        props.onUpload(urls);
    };

    const handleDragEnter = event => {
        event.preventDefault();
        if (!props.disabled) {
            setActive(true);
        }
    };

    const handleDragLeave = event => {
        event.preventDefault();
        if (!props.disabled) {
            setActive(false);
        }
    };
    const handleDragOver = event => {
        if (!props.disabled) {
            event.preventDefault();
        }
    };
    const handleDrop = event => {
        event.preventDefault();
        if (!props.disabled) {
            setActive(false);
            const dropped = event.dataTransfer
                ? event.dataTransfer.files
                : null;
            onDrop(dropped);
        }
    };

    return (
        <UploadWrapper
            htmlFor={uploadID}
            onDrop={handleDrop}
            onDragLeave={handleDragLeave}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            active={isActive}>
            <input
                type="file"
                name="files"
                id={uploadID}
                onChange={e => {
                    onDrop(e.target.files);
                }}
                multiple={props.multi}
                accept={props.accept}
                disabled={props.disabled}
            />
            {loading ? (
                <ProgressBar now={complete} variation="info" />
            ) : (
                <div style={{ pointerEvents: 'none' }}>
                    <div
                        style={{
                            width: '30px',
                            height: '30px',
                            margin: '0 auto',
                            pointerEvents: 'none',
                        }}>
                        <svg
                            className="box__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 50 43"
                            style={{ pointerEvents: 'none', fill: '#81bcff' }}>
                            <path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path>
                        </svg>
                    </div>
                    Drag file here or Click to choose
                </div>
            )}
        </UploadWrapper>
    );
};

FileUpload.defaultProps = {
    accept: '*',
};
export default FileUpload;
