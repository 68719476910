import { all, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, GET_ARTICLE_TAGS } from 'constants';
import { getArticleTagsSuccess, getArticleTagsFailure } from 'actions';
/***********************************
 *
 * Get articles
 *
 **********************************/
function* getArticleTags() {
    try {
        const response = yield Axios.get(`${ROOT_URL}/articleTag`);
        yield put(getArticleTagsSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(getArticleTagsFailure(e));
    }
}

export default function* () {
    yield all([takeLatest(GET_ARTICLE_TAGS, getArticleTags)]);
}
