import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { styles } from './Styles';
import SearchMarker from './SearchMarker';
import MapMarker from './MapMarker';
import Searchbox from './Searchbox';
import DistanceCalculator from './distanceCalculator';
import MapContext from './MapContext';
import MessagesViewOnMap from '../../components/customMap/editComponents/MessagesViewOnMap';
import { BUTTONS_STATE } from 'constants';
import { RecenterButton } from './StyledComponents';
import { GoogleMapDrawing } from './GoogleMapDrawing';
import { SeparationLine } from '../../components/map/SeparationLine';

const GoogleMap = props => {
    const {
        onMapsLoad,
        onChange,
        zoom,
        formControl,
        markers,
        transit,
        traffic,
        bicycle,
        allowFullScreen,
        center,
        defaultCenter,
        recenter,
        centerToPoint,
        messages,
        noDistance,
        rebound,
        messagesEnabled,
        showMessages,
        mapShapes,
        withDrawing,
    } = props;

    const { map, mapsApi } = useContext(MapContext);

    const { highlightMarker, buttonsState } = useSelector(state => state.map);
    // const [map, setMap] = useState();
    // const [mapsApi, setMapsApi] = useState();
    const {
        marker: searchMarker,
        origin,
        destination,
        show,
        showSeparationLine,
    } = useSelector(state => state.map);
    const [formControlMarker, setFormControlMarker] = useState();

    const isSearchMarkerOnMap = markers.filter(
        m => m.object?.id === searchMarker?.id
    ).length;

    const isOriginShowing =
        markers.length &&
        origin &&
        markers.filter(p => p.object?.id === origin.id).length > 0;

    const isDestinationShowing =
        markers.length &&
        destination &&
        markers.filter(p => p.object?.id === destination.id).length > 0;

    useEffect(() => {
        if (map && highlightMarker && highlightMarker.geoPoint) {
            map.panTo({
                lat: highlightMarker.geoPoint.latitude,
                lng: highlightMarker.geoPoint.longitude,
            });
        }
    }, [map, highlightMarker]);

    useEffect(() => {
        if (map && searchMarker) {
            const lat =
                searchMarker?.geoPoint?.latitude ||
                searchMarker?.location?.latitude ||
                searchMarker?.location?.lat;
            const lng =
                searchMarker.geoPoint?.longitude ||
                searchMarker.location?.longitude ||
                searchMarker.location?.lng;

            if (lat && lng) {
                map.panTo({
                    lat,
                    lng,
                });
            }
        }
    }, [map, searchMarker]);

    useEffect(() => {
        if (formControl && map && mapsApi) {
            let point = markers[0] || center || defaultCenter;
            if (point.latitude) {
                point = { lat: point.latitude, lng: point.longitude };
            }
            map.panTo(point);

            if (formControlMarker) {
                formControlMarker.setMap(null); //remove previous one if exists
            }

            const mrkr = new mapsApi.Marker({
                map,
                draggable: true,
                animation: mapsApi.Animation.DROP,
                position: point,
            });

            mrkr.addListener('dragend', e => {
                onChange({
                    latitude: e.latLng.lat(),
                    longitude: e.latLng.lng(),
                });
            });
            setFormControlMarker(mrkr);
        }
    }, [formControl, markers, map, mapsApi]);

    useEffect(() => {
        if (center && map && mapsApi) {
            const c = center.latitude
                ? { lat: center.latitude, lng: center.longitude }
                : center;
            map.panTo(c);
        }
    }, [center, map, mapsApi]);

    const boundView = map => {
        /** bound */
        if (markers && markers.length > 1 && map) {
            const bounds = new window.google.maps.LatLngBounds();
            markers.forEach(location => {
                if (location && location.latitude && location.longitude) {
                    bounds.extend(
                        new window.google.maps.LatLng(
                            location.latitude,
                            location.longitude
                        )
                    );
                }
            });
            map.fitBounds(bounds);
        }
    };

    const handleApiLoaded = (map, maps) => {
        onMapsLoad(map, maps);
        boundView(map);
    };

    useEffect(() => {
        if ((map, markers.length && rebound)) {
            boundView(map);
        }
    }, [map, markers.length, rebound]);

    const mapOptions = {
        bootstrapURLKeys: {
            key: 'AIzaSyAziyq3pIHTz45KqgBrO58EpvtO2qzH1FU',
            libraries: ['places', withDrawing ? 'drawing' : undefined],
        },
        yesIWantToUseGoogleMapApiInternals: true,
        onGoogleApiLoaded: ({ map, maps }) => handleApiLoaded(map, maps),
        mapTypeControl: false,
        defaultCenter: defaultCenter,
        defaultZoom: zoom,
        options: {
            styles: styles,
            clickableIcons: false,
            streetViewControl: true,
            fullscreenControl: false,
        },
        layerTypes: [
            transit ? 'TransitLayer' : null,
            traffic ? 'TrafficLayer' : null,
            bicycle ? 'BicyclingLayer' : null,
        ].filter(Boolean),
    };

    return (
        <>
            {mapShapes.map((shape, index) => (
                <GoogleMapDrawing
                    key={index}
                    map={map}
                    mapsApi={mapsApi}
                    drawing={shape?.drawing}
                />
            ))}

            {recenter && (
                <RecenterButton>
                    <i
                        className="material-icons"
                        onClick={() => {
                            map.panTo(centerToPoint);
                        }}>
                        gps_fixed
                    </i>
                </RecenterButton>
            )}

            {/* <MapContext.Provider value={{ map, mapsApi }}> */}
            {formControl ? (
                <Searchbox map={map} mapsApi={mapsApi} onChange={onChange} />
            ) : undefined}

            {/* {showMessages ? <MessagesViewOnMap /> : undefined} */}
            {/* {!noDistance &&
            !formControl &&
            buttonsState === BUTTONS_STATE.DISTANCE ? (
                <DistanceCalculator
                    map={map}
                    mapApi={mapsApi}
                    recenter={recenter}
                    messages={messages}
                />
            ) : undefined} */}

            <GoogleMapReact {...mapOptions}>
                {!formControl &&
                    show &&
                    markers.map((m, i) => (
                        <MapMarker
                            key={i}
                            lat={m.latitude}
                            lng={m.longitude}
                            type={m.markerType}
                            object={m.object}
                            map={map}
                            mapsApi={mapsApi}
                        />
                    ))}
                {showSeparationLine ? (
                    <SeparationLine map={map} mapsApi={mapsApi} />
                ) : undefined}
                {searchMarker &&
                !searchMarker.reference &&
                !isSearchMarkerOnMap ? (
                    <MapMarker
                        lat={
                            searchMarker.geoPoint?.latitude ||
                            searchMarker.location?.latitude ||
                            searchMarker.location?.lat
                        }
                        lng={
                            searchMarker.geoPoint?.longitude ||
                            searchMarker.location?.longitude ||
                            searchMarker.location?.lng
                        }
                        type={
                            searchMarker.types
                                ? searchMarker.types[0]
                                : !searchMarker.propId
                                ? 'neighborhood'
                                : 'property'
                        }
                        object={searchMarker}
                    />
                ) : undefined}
                {searchMarker && searchMarker.reference ? (
                    <SearchMarker
                        map={map}
                        lat={
                            searchMarker.location.latitude ||
                            searchMarker.location.lat
                        }
                        lng={
                            searchMarker.location.longitude ||
                            searchMarker.location.lng
                        }
                    />
                ) : undefined}
                {origin && (!show || !isOriginShowing) ? (
                    <MapMarker
                        {...origin.point}
                        type={origin.markerType}
                        object={origin}
                    />
                ) : undefined}
                {destination && (!show || !isDestinationShowing) ? (
                    <MapMarker
                        {...destination.point}
                        type={destination.markerType}
                        object={destination}
                    />
                ) : undefined}

                {/* {polygonLabels?.map(p => (
                        <MapMarker
                            lat={p?.location?.latitude}
                            lng={p?.location?.longitude}
                            object={p}>
                            {p?.title}
                        </MapMarker>
                    ))} */}
            </GoogleMapReact>
            {/* </MapContext.Provider> */}
        </>
    );
};

GoogleMap.defaultProps = {
    defaultCenter: { lat: 31.7683, lng: 35.2137 },
    markers: [],
    onChange: () => {},
    zoom: 15,
    width: '100%',
    height: 500,
    mapShapes: [],
};
export default GoogleMap;
