import * as constants from '../constants';

export const fetchPlaces = () => ({
    type: constants.FETCH_PLACES,
});

export function fetchPlacesSuccess(places) {
    return {
        type: constants.FETCH_PLACES_SUCCESS,
        payload: places,
    };
}

export function fetchPlacesFailure(error) {
    return {
        type: constants.FETCH_PLACES_FAILURE,
        payload: error,
    };
}

export const getPlace = (id, duplicate) => ({
    type: constants.GET_PLACE,
    id,
    duplicate,
});

export function getPlaceSuccess(place) {
    return {
        type: constants.GET_PLACE_SUCCESS,
        place,
    };
}

export function getPlaceFailure(error) {
    return {
        type: constants.GET_PLACE_FAILURE,
        error,
    };
}

export function resetPlace() {
    return {
        type: constants.RESET_PLACE,
    };
}

export const savePlace = place => ({
    type: constants.SAVE_PLACE,
    place,
});

export function savePlaceSuccess(place) {
    return {
        type: constants.SAVE_PLACE_SUCCESS,
        place,
    };
}

export function savePlaceFailure(error) {
    return {
        type: constants.SAVE_PLACE_FAILURE,
        payload: error,
    };
}
export const deletePlace = id => ({
    type: constants.DELETE_PLACE,
    id,
});

export function deletePlaceSuccess(id) {
    return {
        type: constants.DELETE_PLACE_SUCCESS,
        id,
    };
}

export function deletePlaceFailure(error) {
    return {
        type: constants.DELETE_PLACE_FAILURE,
        payload: error,
    };
}
