import React from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import Price from 'components/currency/Price';
import Furniture from '../Furniture';
import Availability from './Availability';
import QualityStars from '../QualityStars';
// import TileCustomMapMessage from './TileCustomMapMessage';
import Recommended from './Recommended';

const Wrapper = styled(Box)`
    padding: ${({ isList }) => (isList ? '0px' : '0px 20px')};
    margin: ${({ isList }) => (isList ? '20px 0px' : '0px')};
    border-radius: ${({ isList }) => (isList ? '0px' : '0px 0px 20px 20px')};
    background: ${({ isList }) => (isList ? 'transparent' : '#F2F2F2')};
`;

const Specs = styled(Flex)`
    padding: ${({ isList }) => (isList ? '10px 45px' : '10px 0px')};
    border-radius: ${({ isList }) => (isList ? '50px' : '0px')};
    background: ${({ isList }) => (isList ? '#F2F2F2' : 'transparent')};
`;

const Specs2 = styled(Flex)`
    padding-left: ${({ isList }) => (isList ? '5px' : '0px')};
    padding-right: ${({ isList }) => (isList ? '30px' : '0px')};
    padding-bottom: ${({ isList }) => (isList ? '0px' : '10px')};
    & > div {
        padding-right: 10px;
        margin-right: 10px;
        position: relative;
    }
    & > div:not(:last-child):after {
        content: '';
        position: absolute;
        display: inline-block;
        width: 3px;
        height: 3px;
        border-radius: 4px;
        background: #555;
        right: 0px;
        top: 50%;
    }
`;

const Value = styled.div`
    font-size: ${({ isList }) => (isList ? '16px' : '14px')};
    line-height: ${({ isList }) => (isList ? '24px' : '22px')};
    font-weight: 600;
`;
export const Label = styled.div`
    color: ${({ horizontal }) => (horizontal ? '#666666' : '#acacac')};
    font-size: ${({ isList, horizontal }) =>
        isList || horizontal ? '13px' : '11px'};
    margin-left: ${({ horizontal }) => (horizontal ? '10px' : '0px')};
    white-space: nowrap;
`;
const StyledPrice = styled(Box)`
    color: ${({ striked }) => (striked ? '#E33030' : '#19a99d')};
    font-size: 17px;
    line-height: 20px;
    font-weight: bold;
    // align-self: center;
    text-decoration: ${({ striked }) => (striked ? 'line-through' : 'none')};
`;
const Additional = styled.div`
    font-size: ${({ isList }) => (isList ? '13px' : '11px')};
    line-height: ${({ isList }) => (isList ? '22px' : '16px')};
    color: ${({ isList }) => (isList ? '#555555' : '#888888')};
    font-weight: bold;
    text-transform: capitalize;
`;

const getAttribute = (val, title, isList) => {
    if (val) {
        return (
            <Box mr={4}>
                <Value isList={isList}>{val}</Value>
                <Label isList={isList}>{title}</Label>
            </Box>
        );
    }
};

function Attributes(props) {
    const {
        bedrooms,
        bathrooms,
        proptypeLabel,
        neighborhood,
        size,
        floors,
        apartments,
        furnitureStatus,
        durations = [],
        quality,
        price,
        priceComment,
        negotiable,
        discountedPrice,
        currency = 'USD',
        status,
        availableOn,
        isList,
        recommended,
        note,
    } = props;

    const isBuilding =
        proptypeLabel && proptypeLabel.indexOf('Apartments Building') === 0;

    return (
        <Wrapper isList={isList} width="100%">
            <Specs isList={isList} alignItems="flex-start">
                {isList && proptypeLabel
                    ? getAttribute(proptypeLabel, 'Property type', isList)
                    : undefined}
                {getAttribute(bedrooms, 'Beds', isList)}
                {getAttribute(bathrooms, 'Baths', isList)}
                {getAttribute(size, 'Sqr', isList)}
                {getAttribute(floors, 'Floors', isList)}
                {getAttribute(apartments, 'Apartments', isList)}
                {isList && neighborhood
                    ? getAttribute(neighborhood.title, 'Neighborhood', isList)
                    : undefined}
                {!isBuilding ? (
                    <Availability
                        status={status}
                        availableOn={availableOn}
                        isList={isList}
                    />
                ) : undefined}
                <Flex ml="auto" flexDirection="column" alignItems="flex-end">
                    {price ? (
                        <StyledPrice striked={discountedPrice}>
                            <Price amount={price} currency={currency} />
                            /m
                        </StyledPrice>
                    ) : undefined}
                    {discountedPrice ? (
                        <StyledPrice>
                            <Price
                                amount={discountedPrice}
                                currency={currency}
                            />
                            /m
                        </StyledPrice>
                    ) : undefined}
                    {/* {priceComment ? (
                        <Box
                            fontSize="0.9em"
                            mt="2px"
                            color="#acacac"
                            style={{ textAlign: 'right' }}>
                            {priceComment}
                        </Box>
                    ) : undefined} */}
                    {negotiable ? (
                        <Box
                            fontSize="0.9em"
                            mt="2px"
                            color="#acacac"
                            style={{ textAlign: 'right' }}>
                            Negotiable
                        </Box>
                    ) : undefined}
                </Flex>
            </Specs>
            <Specs2 mt={isList ? 4 : 2} isList={isList} alignItems="center">
                {!isBuilding && furnitureStatus ? (
                    <Additional isList={isList}>
                        {Furniture(furnitureStatus)}
                    </Additional>
                ) : undefined}
                {/* {!isBuilding && quality ? <Dot /> : undefined} */}
                {quality ? (
                    <Additional isList={isList}>
                        <QualityStars stars={quality} />
                    </Additional>
                ) : undefined}
                {/* {!isBuilding && furnitureStatus && durations.length ? (
                    <Dot />
                ) : undefined} */}
                {durations.length
                    ? durations.map(d => (
                          <Flex key={d}>
                              <Additional isList={isList} key={d}>
                                  {d}
                              </Additional>
                          </Flex>
                      ))
                    : undefined}
                {/* {note ? <TileCustomMapMessage {...props} /> : undefined} */}
            </Specs2>
            {recommended ? <Recommended /> : undefined}
        </Wrapper>
    );
}

export default Attributes;
