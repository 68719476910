import React from 'react';
import Symbols from '../../components/currency/Symbols';
import { useSelector } from 'react-redux';

const format = value => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const Price = props => {
    const globalCurrency = useSelector(state => state.currencies.currency);

    const { amount, currency } = props;

    if (!currency) {
        return <span>{amount}</span>;
    } else {
        const price = window.fx(amount).from(currency).to(globalCurrency);
        const symbol = Symbols[globalCurrency];

        let p = Math.floor(price);

        if (p.toString().slice(-2) == '99') {
            p = Math.ceil(p / 100) * 100;
        }

        return (
            <span className="price-currency">
                {symbol}
                {format(p)}
            </span>
        );
    }
};

export default Price;
