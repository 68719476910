import { all, takeLatest, put } from 'redux-saga/effects';
import Axios from 'axios';
import { ROOT_URL, GET_FEATURED_PROPERTIES } from 'constants';
import {
    getFeaturedPropertiesSuccess,
    getFeaturedPropertiesFailure,
} from 'actions';

export function* getFeatured() {
    try {
        const response = yield Axios.get(`${ROOT_URL}/featured`);

        yield put(getFeaturedPropertiesSuccess(response.data));
    } catch (e) {
        console.log(e);
        yield put(getFeaturedPropertiesFailure(e));
    }
}

export default function* () {
    yield all([takeLatest(GET_FEATURED_PROPERTIES, getFeatured)]);
}
