import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import styled from 'styled-components';
import { Flex } from 'reflexbox';

const StyledInput = styled.input`
    height: 43px;
    border-radius: 50px;
    border: 0;
    background: #ffffff1a;
    color: #fff;
    text-indent: 20px;
    width: 100%;
    margin-bottom: 17px;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Open Sans', sans-serif;

    &::placeholder {
        color: #ffffffcc;
        font-size: 14px;
    }
`;
const StyledButton = styled(Flex)`
    background: #fcc82e;
    border-radius: 50px;
    color: #676767 !important;
    font-size: 12px;
    display: inline-flex !important;
    align-items: center;
    font-weight: bold;
    padding: 5px 30px;

    &:hover {
        cursor: pointer;
        background: #fcc82ecc;
    }
`;

const SubscribeToMailingList = props => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [sent, setSent] = useState(false);

    const subscribe = () => {
        if (email === '' || name === '') return;

        const subject = 'Subscription to mailing-list';
        const message = `${name} has subscribed to the mailing list with email: ${email}`;

        axios
            .post(`/api/mail?subject=${subject}&message=${message}`)
            .then(response => {
                setSent(true);
            });
    };

    return (
        <div className="subscribe_to_mailing_list">
            {!sent ? (
                <span>
                    <StyledInput
                        name="name"
                        placeholder="Your name *"
                        value={name}
                        onChange={e => {
                            setName(e.target.value);
                        }}
                    />

                    <StyledInput
                        name="email"
                        placeholder="Your email *"
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                        }}
                    />

                    <StyledButton onClick={subscribe}>
                        Subscribe{' '}
                        <i className="material-icons">arrow_right_alt</i>
                    </StyledButton>
                </span>
            ) : (
                <div className="thankyou-message">Thank you</div>
            )}
        </div>
    );
};

export default SubscribeToMailingList;
