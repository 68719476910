import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Box } from 'reflexbox';

const CopyButton = ({ text }) => {
    const notify = () => {
        addToast(`Link copied: ${text}`, {
            appearance: 'info',
            autoDismiss: true,
            autoDismissTimeout: 2000,
        });
    };
    const { addToast } = useToasts();
    return (
        <CopyToClipboard text={text} onCopy={notify}>
            <Box
                className="material-icons"
                fontSize="18px"
                style={{ cursor: 'pointer' }}>
                content_copy
            </Box>
        </CopyToClipboard>
    );
};
function Copy(props) {
    return (
        <ToastProvider>
            <CopyButton {...props} />
        </ToastProvider>
    );
}

export default Copy;
