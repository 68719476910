import React, { useState } from 'react';
import Upload from './FileUpload.jsx';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import { Draggable, Droppable } from 'react-drag-and-drop';

const ImageUpload = ({ onChange, value, single, disabled, ...props }) => {
    const [loading, setLoading] = useState(false);

    const updateImageTitle = (index, title) => {
        const imgs = value;
        imgs[index].title = title;
        onChange(imgs);
    };

    const removeImage = index => {
        if (single) {
            onChange('');
        } else {
            const images = value;
            images.splice(index, 1);
            onChange(images);
        }
    };

    const getImageThumb = (img, idx) => {
        return (
            <div key={idx} className="image-thumb">
                {props.withCaption ? (
                    <span>
                        <img src={img.url} width="300" height="200" />
                        <span
                            style={{
                                display: 'inline-block',
                                height: '100%',
                                verticalAlign: 'middle',
                            }}></span>
                        <input
                            type="text"
                            value={img.title}
                            onChange={value =>
                                updateImageTitle(idx, value.target.value)
                            }
                        />
                    </span>
                ) : (
                    <span>
                        <span
                            style={{
                                display: 'inline-block',
                                height: '100%',
                                verticalAlign: 'middle',
                            }}></span>
                        <img src={img.url || img} width="300" height="200" />
                    </span>
                )}
                <span className="close-btn" onClick={() => removeImage(idx)}>
                    remove
                </span>
            </div>
        );
    };

    const renderPreview = () => {
        if (value) {
            if (single) {
                return (
                    <div className="preview-container">
                        {getImageThumb(value, 0)}
                    </div>
                );
            } else {
                let images = sortBy(value, 'order');

                return images.map((img, idx) => {
                    return (
                        <div key={idx} className="preview-container">
                            <Droppable
                                types={['photo']}
                                onDrop={data => {
                                    onDrop(data, idx);
                                }}
                                className="preview-container"></Droppable>
                            <Draggable
                                key={idx}
                                type="photo"
                                data={img.id}
                                className="photo-thumb">
                                {getImageThumb(img, idx)}
                            </Draggable>
                        </div>
                    );
                });
            }
        }
    };

    const onDrop = (img, index) => {
        let currentList = value;
        let target = find(currentList, { id: img.photo });
        if (!target) return;
        let currentIndex = currentList.indexOf(target);

        target.order = index;
        currentList.splice(currentIndex, 1);
        currentList.splice(index, 0, target);

        for (var i = 0; i < currentList.length; i++) {
            if (i != index) {
                currentList[i].order = i;
            }
        }
        onChange(currentList);
    };
    const handleUpload = urls => {
        let lastIndex =
            value && value.length ? value[value.length - 1].order : 0;

        urls.map(url => {
            lastIndex++;
            let image = { url: url, order: lastIndex };

            if (props.withCaption) {
                image.title = '';
                image.id = url.split('/')[url.split('/').length - 1];
            }

            if (!value) value = [];
            value.push(image);
        });

        if (onChange) {
            onChange(single ? value[0].url : value);
        }
    };

    const toggleLoading = () => {
        setLoading(!loading);
    };

    return (
        <div className="image-upload">
            {renderPreview()}
            {single && value && value.length ? (
                ''
            ) : (
                <Upload
                    onUpload={handleUpload}
                    onStartLoading={toggleLoading}
                    onFinishedLoading={toggleLoading}
                    disabled={disabled}
                    multi={!single}
                    accept={'image/*'}
                />
            )}

            {loading ? (
                <img className="loading" src="/dist/images/loading.svg" />
            ) : undefined}
        </div>
    );
};
export default ImageUpload;
