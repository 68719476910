import React, { useState, useEffect, useContext } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'reflexbox';
import Axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';
import { togglePins, setRouteType } from 'actions';
import { Direction } from './styled';
import MapContext from '../MapContext';
import { isEmpty } from 'lodash';

const Wrapper = styled(Flex)`
    ${({ minimize }) =>
        minimize
            ? null
            : css`
                  background: #efefef;
                  padding: 20px;
                  margin-top: 20px;
              `}
`;

function Distance(props) {
    const { map, mapsApi } = useContext(MapContext);
    const { minimize, directionsDisplay } = props;
    const { show, origin, destination, routeType } = useSelector(
        state => state.map
    );
    const dispatch = useDispatch();

    const [distance, setDistance] = useState({});
    const [fetching, setFetching] = useState(true);

    const directionsService = mapsApi
        ? new mapsApi.DirectionsService()
        : undefined;

    useEffect(() => {
        if (origin && destination && isEmpty(distance)) {
            Axios.get(
                `/api/distance?origin=${origin.point.lat},${origin.point.lng}&destination=${destination.point.lat},${destination.point.lng}`
            )
                .then(response => {
                    setDistance(response.data);
                    setFetching(false);
                })
                .finally(e => setFetching(false));
        }
        return () => {
            // map.setZoom(14);
            // if (directionsDisplay) {
            //     directionsDisplay.setMap(null);
            // }
        };
    }, []);

    useEffect(() => {
        if (routeType && origin && destination) {
            const startPoint = new mapsApi.LatLng(
                origin?.point?.lat,
                origin?.point?.lng
            );
            const endPoint = new mapsApi.LatLng(
                destination?.point?.lat,
                destination?.point?.lng
            );

            directionsDisplay.setMap(map);

            const request = {
                origin: startPoint,
                destination: endPoint,
                travelMode: routeType,
            };

            directionsService.route(request, function (response, status) {
                if (status === mapsApi.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                } else {
                    alert(
                        'Directions Request from ' +
                            startPoint.toUrlValue(6) +
                            ' to ' +
                            endPoint.toUrlValue(6) +
                            ' failed: ' +
                            status
                    );
                }
            });

            const bounds = new mapsApi.LatLngBounds();
            bounds.extend(startPoint);
            bounds.extend(endPoint);
            map.fitBounds(bounds);
        }
    }, [routeType, origin, destination]);

    const { walking, driving, transit, biking } = distance;

    const handleTogglePins = () => {
        dispatch(togglePins());
    };

    return (
        <Wrapper minimize={minimize}>
            <Box width="100%">
                {fetching ? <Box>Calculating distance....</Box> : undefined}
                {!fetching && !minimize && !isEmpty(distance) ? (
                    <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        mb={2}>
                        <Box>
                            Distance:{' '}
                            {distance[routeType?.toLowerCase()]?.distance?.text}
                        </Box>
                        <Box
                            color="#1155CC"
                            style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={handleTogglePins}>
                            {`${show ? 'Hide' : 'Show'} pins`}
                        </Box>
                    </Flex>
                ) : undefined}
                <Flex
                    justifyContent="space-between"
                    pt={minimize ? '0px' : '5px'}>
                    {driving && driving.status === 'OK' ? (
                        <Direction
                            mode={'car'}
                            time={driving?.duration?.text}
                            selected={routeType === mapsApi.TravelMode.DRIVING}
                            onClick={() =>
                                dispatch(
                                    setRouteType(mapsApi.TravelMode.DRIVING)
                                )
                            }
                        />
                    ) : undefined}
                    {walking && walking.status === 'OK' ? (
                        <Direction
                            mode={'walk'}
                            time={walking?.duration?.text}
                            selected={routeType === mapsApi.TravelMode.WALKING}
                            onClick={() =>
                                dispatch(
                                    setRouteType(mapsApi.TravelMode.WALKING)
                                )
                            }
                        />
                    ) : undefined}
                    {biking && biking.status === 'OK' ? (
                        <Direction
                            mode={'bike'}
                            time={biking?.duration?.text}
                            selected={
                                routeType === mapsApi.TravelMode.BICYCLING
                            }
                            onClick={() =>
                                dispatch(
                                    setRouteType(mapsApi.TravelMode.BICYCLING)
                                )
                            }
                        />
                    ) : undefined}
                    {transit && transit.status === 'OK' ? (
                        <Direction
                            mode={'bus'}
                            time={transit?.duration?.text}
                            selected={routeType === mapsApi.TravelMode.TRANSIT}
                            onClick={() =>
                                dispatch(
                                    setRouteType(mapsApi.TravelMode.TRANSIT)
                                )
                            }
                        />
                    ) : undefined}
                </Flex>
            </Box>
        </Wrapper>
    );
}

export default React.memo(Distance);
