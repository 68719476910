import React, { useState, useEffect } from 'react';
import { Checkbox } from '../common/Checkbox';

export const SeparationLine = ({ map, mapsApi }) => {
    let line = null;

    useEffect(() => {
        if (map && mapsApi) {
            if (!line) {
                line = new mapsApi.Polyline(polygon);
            }
            line.setMap(map);
        }

        return () => {
            if (line) line.setMap();
        };
    }, []);

    return null;
};

const polygon = {
    strokeColor: '#2f9822',
    strokeOpacity: 0.4,
    strokeWeight: 10,
    path: [
        { lat: 31.824901483032132, lng: 35.11490160790064 },
        { lat: 31.82504277939527, lng: 35.115550702482246 },
        { lat: 31.825243328700708, lng: 35.116323178678535 },
        { lat: 31.825380066613725, lng: 35.117095654874824 },
        { lat: 31.825462109264315, lng: 35.11800224152186 },
        { lat: 31.825459876209063, lng: 35.11892240130127 },
        { lat: 31.825405181115443, lng: 35.11960904680908 },
        { lat: 31.82520919009724, lng: 35.12031714998901 },
        { lat: 31.82502687249742, lng: 35.12095015131653 },
        { lat: 31.824703257871004, lng: 35.12184064470947 },
        { lat: 31.824347736269548, lng: 35.1227096804303 },
        { lat: 31.82415174300641, lng: 35.12311201178253 },
        { lat: 31.824028677256404, lng: 35.12337486826599 },
        { lat: 31.82306237751861, lng: 35.12528747590769 },
        { lat: 31.822556433527602, lng: 35.12607604535807 },
        { lat: 31.822055044855375, lng: 35.12680024179209 },
        { lat: 31.821576444037444, lng: 35.12749225171793 },
        { lat: 31.820993003494323, lng: 35.12826472791422 },
        { lat: 31.820838026480345, lng: 35.128447118127234 },
        { lat: 31.820505280836255, lng: 35.128645601594336 },
        { lat: 31.82011783576706, lng: 35.12908011945475 },
        { lat: 31.81976229650846, lng: 35.12948245080698 },
        { lat: 31.819488803839512, lng: 35.12977749379862 },
        { lat: 31.819201635665255, lng: 35.13010472329844 },
        { lat: 31.818923583090044, lng: 35.13046950372446 },
        { lat: 31.81864097141791, lng: 35.1308181908964 },
        { lat: 31.818372033700935, lng: 35.131177606904394 },
        { lat: 31.817715640052104, lng: 35.1320788291334 },
        { lat: 31.817602286420414, lng: 35.132241302564786 },
        { lat: 31.817513399312706, lng: 35.132367366388486 },
        { lat: 31.81743021001897, lng: 35.13249208910768 },
        { lat: 31.817302518840858, lng: 35.13268259437321 },
        { lat: 31.817210212696864, lng: 35.13282206924198 },
        { lat: 31.817142977299344, lng: 35.132925334289055 },
        { lat: 31.817057063671243, lng: 35.13308548189919 },
        { lat: 31.816977292722896, lng: 35.13322898008148 },
        { lat: 31.816906638396826, lng: 35.1333577261142 },
        { lat: 31.816753933700927, lng: 35.13363667585175 },
        { lat: 31.816643841566975, lng: 35.1338254848999 },
        { lat: 31.81652988253835, lng: 35.13403737941208 },
        { lat: 31.816426179700038, lng: 35.13421976962509 },
        { lat: 31.815661636015264, lng: 35.13580676018717 },
        { lat: 31.8150872749706, lng: 35.137212237710976 },
        { lat: 31.814485559568755, lng: 35.13857479989054 },
        { lat: 31.81398412707253, lng: 35.139765700693154 },
        { lat: 31.813592096133828, lng: 35.14128919541361 },
        { lat: 31.813236531750402, lng: 35.14288779198649 },
        { lat: 31.813072424650198, lng: 35.143724641199135 },
        { lat: 31.812917434343408, lng: 35.144733151788735 },
        { lat: 31.812716858266178, lng: 35.14592405259135 },
        { lat: 31.812507164628787, lng: 35.14691110550883 },
        { lat: 31.812242767624827, lng: 35.14776941239359 },
        { lat: 31.81193278396674, lng: 35.148466786737465 },
        { lat: 31.811413103147064, lng: 35.149453839654946 },
        { lat: 31.811267227303713, lng: 35.149700602884316 },
        { lat: 31.811013172116773, lng: 35.15111680924418 },
        { lat: 31.810621128567913, lng: 35.15426035820963 },
        { lat: 31.8102837874382, lng: 35.15666361748698 },
        { lat: 31.80988262341001, lng: 35.15781160294535 },
        { lat: 31.8092626392124, lng: 35.1591527074528 },
        { lat: 31.808800873170775, lng: 35.159853902554865 },
        { lat: 31.80842705528982, lng: 35.16175290653741 },
        { lat: 31.80845440786899, lng: 35.162085500455255 },
        { lat: 31.80857293561846, lng: 35.162611213422174 },
        { lat: 31.80876440320017, lng: 35.16296526501214 },
        { lat: 31.808937635432155, lng: 35.16325494358575 },
        { lat: 31.80882822563924, lng: 35.16417762348687 },
        { lat: 31.80868234571376, lng: 35.16527196476495 },
        { lat: 31.80863675818979, lng: 35.166323390698786 },
        { lat: 31.808591170643357, lng: 35.16739627430474 },
        { lat: 31.808600288154405, lng: 35.168351140714044 },
        { lat: 31.80842705528982, lng: 35.1694776685003 },
        { lat: 31.808299409813298, lng: 35.17052909443414 },
        { lat: 31.80813529394143, lng: 35.17193457195794 },
        { lat: 31.80804411833112, lng: 35.17379066059625 },
        { lat: 31.807980295350333, lng: 35.17499229023492 },
        { lat: 31.807907354746884, lng: 35.17616173336541 },
        { lat: 31.808016765630448, lng: 35.17852207729852 },
        { lat: 31.808299409813298, lng: 35.18061420033013 },
        { lat: 31.808819108150658, lng: 35.183285680508966 },
        { lat: 31.809251073068026, lng: 35.18555292016466 },
        { lat: 31.809488126370503, lng: 35.18751629716356 },
        { lat: 31.809606652793608, lng: 35.1886213672777 },
        { lat: 31.809779883446133, lng: 35.19101389771898 },
        { lat: 31.809925761638386, lng: 35.19251593476732 },
        { lat: 31.809943996396218, lng: 35.19424327737291 },
        { lat: 31.809943996396218, lng: 35.19531616097887 },
        { lat: 31.809962231150458, lng: 35.197172249617175 },
        { lat: 31.809962231150458, lng: 35.197451199354724 },
        { lat: 31.809789000839906, lng: 35.19853481179674 },
        { lat: 31.80961070645576, lng: 35.1991034401079 },
        { lat: 31.8092551267458, lng: 35.199650610746936 },
        { lat: 31.80903630778239, lng: 35.200144137205676 },
        { lat: 31.808890428185528, lng: 35.200895155729846 },
        { lat: 31.808644255843397, lng: 35.20177492028673 },
        { lat: 31.808516610666974, lng: 35.202440108122424 },
        { lat: 31.80845278801262, lng: 35.20279415971239 },
        { lat: 31.80832514257167, lng: 35.20324477082689 },
        { lat: 31.808206614504243, lng: 35.203663195433215 },
        { lat: 31.80788726119119, lng: 35.204655345697404 },
        { lat: 31.807340204710545, lng: 35.20595353486061 },
        { lat: 31.806875204154935, lng: 35.207090791482926 },
        { lat: 31.806410201258768, lng: 35.20826023461342 },
        { lat: 31.805936078249015, lng: 35.20917218567848 },
        { lat: 31.805525777526686, lng: 35.209891017694474 },
        { lat: 31.805179299942086, lng: 35.21080296875954 },
        { lat: 31.80519753563683, lng: 35.21156471611977 },
        { lat: 31.805270478379782, lng: 35.21291654946327 },
        { lat: 31.805361656727506, lng: 35.21382850052834 },
        { lat: 31.805361656727506, lng: 35.21419328095436 },
        { lat: 31.80516106424375, lng: 35.21518033387184 },
        { lat: 31.804878410459214, lng: 35.21640342118263 },
        { lat: 31.804541048358605, lng: 35.21743338944435 },
        { lat: 31.80424927473765, lng: 35.21824878098488 },
        { lat: 31.803756904662926, lng: 35.21921437623024 },
        { lat: 31.80289980975527, lng: 35.220791515131 },
        { lat: 31.8023709599949, lng: 35.22168200852394 },
        { lat: 31.801837607133077, lng: 35.22254031540871 },
        { lat: 31.801217568945955, lng: 35.22342007996559 },
        { lat: 31.801035203981446, lng: 35.22366684319496 },
        { lat: 31.800679591265542, lng: 35.223870691080094 },
        { lat: 31.80007778201324, lng: 35.22394579293251 },
        { lat: 31.799092994782754, lng: 35.22420328499794 },
        { lat: 31.797898470260904, lng: 35.224374946374894 },
        { lat: 31.797041321013168, lng: 35.22451442124367 },
        { lat: 31.7962024012856, lng: 35.22514742257118 },
        { lat: 31.795664394409147, lng: 35.22422474267006 },
        { lat: 31.795071671343297, lng: 35.22450369240761 },
        { lat: 31.794041236042403, lng: 35.225104507226945 },
        { lat: 31.79312021777655, lng: 35.22569459321022 },
        { lat: 31.792117118135756, lng: 35.226316865701676 },
        { lat: 31.79136538784677, lng: 35.22664807000422 },
        { lat: 31.790850150973405, lng: 35.22690019765162 },
        { lat: 31.789959398883205, lng: 35.22699697905535 },
        { lat: 31.789480631769464, lng: 35.22702916556353 },
        { lat: 31.788472932885817, lng: 35.22708817416186 },
        { lat: 31.787930321245046, lng: 35.227152547178214 },
        { lat: 31.78760201685724, lng: 35.226862868604606 },
        { lat: 31.78719847445001, lng: 35.22698088580126 },
        { lat: 31.787027481373293, lng: 35.227021118936484 },
        { lat: 31.7863754248717, lng: 35.22713913613314 },
        { lat: 31.785962757366985, lng: 35.227224966821616 },
        { lat: 31.785334575957393, lng: 35.227351030645316 },
        { lat: 31.785033621863867, lng: 35.2274207680797 },
        { lat: 31.784801065757065, lng: 35.227415403661674 },
        { lat: 31.784616388432024, lng: 35.22739662819857 },
        { lat: 31.784304032116836, lng: 35.227345666227286 },
        { lat: 31.784003074669076, lng: 35.227289339837974 },
        { lat: 31.783681596313272, lng: 35.227241060075706 },
        { lat: 31.78362231649443, lng: 35.22656514340395 },
        { lat: 31.78286763547892, lng: 35.226495405969565 },
        { lat: 31.782609993721962, lng: 35.22648199492449 },
        { lat: 31.78216538900183, lng: 35.22646053725237 },
        { lat: 31.782005786786023, lng: 35.22645249062533 },
        { lat: 31.78172762226577, lng: 35.22662415200228 },
        { lat: 31.78142209633667, lng: 35.22682263546938 },
        { lat: 31.781100609007964, lng: 35.227005025682395 },
        { lat: 31.780874883194414, lng: 35.227157911596244 },
        { lat: 31.780557954102104, lng: 35.2273483484363 },
        { lat: 31.780316266438437, lng: 35.227498552141135 },
        { lat: 31.780104219194477, lng: 35.22718741589541 },
        { lat: 31.77983744939052, lng: 35.226779720125144 },
        { lat: 31.779537861160506, lng: 35.22629749587721 },
        { lat: 31.77922552332035, lng: 35.225766348584344 },
        { lat: 31.77911835845623, lng: 35.22556786511724 },
        { lat: 31.778801423344348, lng: 35.225597369416406 },
        { lat: 31.7785072883479, lng: 35.225618827088525 },
        { lat: 31.778276995953586, lng: 35.22564296696966 },
        { lat: 31.77794796019013, lng: 35.22571565995525 },
        { lat: 31.777733627377106, lng: 35.22576662192653 },
        { lat: 31.77747141102609, lng: 35.22585781703304 },
        { lat: 31.77724477265126, lng: 35.225932918885455 },
        { lat: 31.77697799459926, lng: 35.22601606736492 },
        { lat: 31.776620716994607, lng: 35.22614213118862 },
        { lat: 31.77640638110589, lng: 35.226206504204974 },
        { lat: 31.77608943670063, lng: 35.2263191569836 },
        { lat: 31.775977707838454, lng: 35.22635670790981 },
        { lat: 31.77580669401249, lng: 35.226442538598285 },
        { lat: 31.77566974900888, lng: 35.22650606270458 },
        { lat: 31.775428048571115, lng: 35.226629444319265 },
        { lat: 31.775218270320718, lng: 35.226728686052816 },
        { lat: 31.774964530850088, lng: 35.226849385458486 },
        { lat: 31.774750191123562, lng: 35.22695399161007 },
        { lat: 31.77452901024676, lng: 35.22706396217968 },
        { lat: 31.774121493614167, lng: 35.22724264999986 },
        { lat: 31.773786299491405, lng: 35.227333845106365 },
        { lat: 31.77368368880282, lng: 35.2273579849875 },
        { lat: 31.773487588059147, lng: 35.22737944265962 },
        { lat: 31.772949448998997, lng: 35.227459908930065 },
        { lat: 31.77279211113996, lng: 35.22747868439317 },
        { lat: 31.772688842148437, lng: 35.227567714300655 },
        { lat: 31.772601844211867, lng: 35.227766904663376 },
        { lat: 31.772597853747257, lng: 35.22784535927706 },
        { lat: 31.77258702248526, lng: 35.22798751635485 },
        { lat: 31.772573910955824, lng: 35.22817325932913 },
        { lat: 31.772555668824882, lng: 35.22844282133513 },
        { lat: 31.772539706957357, lng: 35.22869494898253 },
        { lat: 31.772521464819672, lng: 35.22892964227133 },
        { lat: 31.772410232606912, lng: 35.22919238977792 },
        { lat: 31.772519685510748, lng: 35.228933556607984 },
        { lat: 31.77250372363701, lng: 35.229216529659055 },
        { lat: 31.772484341358027, lng: 35.229463292888425 },
        { lat: 31.772467239343804, lng: 35.229720784953855 },
        { lat: 31.772455837999235, lng: 35.22989512853982 },
        { lat: 31.772448997191823, lng: 35.22997425370576 },
        { lat: 31.772290518345052, lng: 35.2299447494066 },
        { lat: 31.772136599780737, lng: 35.22991792731645 },
        { lat: 31.771973559837008, lng: 35.22989512853982 },
        { lat: 31.771854985152018, lng: 35.22987098865869 },
        { lat: 31.77171132715695, lng: 35.22984282546403 },
        { lat: 31.771550566755128, lng: 35.22980393343332 },
        { lat: 31.771256408700854, lng: 35.22975565367105 },
        { lat: 31.771019257340477, lng: 35.229699327281736 },
        { lat: 31.770572030301402, lng: 35.22960603633337 },
        { lat: 31.76982864446556, lng: 35.2294665614646 },
        { lat: 31.768919221709965, lng: 35.22930221823305 },
        { lat: 31.768070925305018, lng: 35.2291305568561 },
        { lat: 31.767249985894903, lng: 35.228958895479145 },
        { lat: 31.7660094414113, lng: 35.22879796293825 },
        { lat: 31.765206727294498, lng: 35.228744318757954 },
        { lat: 31.764340153102076, lng: 35.22865848806948 },
        { lat: 31.763400414945107, lng: 35.228698668240554 },
        { lat: 31.76295343738709, lng: 35.228677210568435 },
        { lat: 31.762169565737995, lng: 35.22868786147543 },
        { lat: 31.761590311192656, lng: 35.228746870073756 },
        { lat: 31.761015614123867, lng: 35.228811243090114 },
        { lat: 31.759987269761528, lng: 35.22881660750814 },
        { lat: 31.759193625791053, lng: 35.22873077681967 },
        { lat: 31.758153668218288, lng: 35.22858057311483 },
        { lat: 31.757223169959296, lng: 35.22834453872152 },
        { lat: 31.75692825597819, lng: 35.228213682089354 },
        { lat: 31.756791416774, lng: 35.22784890166333 },
        { lat: 31.756809662012927, lng: 35.22725881568005 },
        { lat: 31.7573935077593, lng: 35.22601427069714 },
        { lat: 31.757466488218668, lng: 35.225413455877806 },
        { lat: 31.75718368861812, lng: 35.224941387091185 },
        { lat: 31.756152831146373, lng: 35.22450150481274 },
        { lat: 31.755705818585817, lng: 35.224437131796385 },
        { lat: 31.75352546107331, lng: 35.22413672438672 },
        { lat: 31.751299436411138, lng: 35.22376121512463 },
        { lat: 31.750551334139864, lng: 35.22423328391125 },
        { lat: 31.75021377381884, lng: 35.22552610865643 },
        { lat: 31.749597951143727, lng: 35.225268616591 },
        { lat: 31.74808499717874, lng: 35.22473161659245 },
        { lat: 31.747063161970434, lng: 35.22447412452702 },
        { lat: 31.746679970860203, lng: 35.22378747901921 },
        { lat: 31.746369767371466, lng: 35.22357290229802 },
        { lat: 31.745895336496385, lng: 35.2234441562653 },
        { lat: 31.74545739814665, lng: 35.22380893669133 },
        { lat: 31.745293170731536, lng: 35.22417371711735 },
        { lat: 31.744818734340818, lng: 35.22447412452702 },
        { lat: 31.74480048673879, lng: 35.225332431411786 },
        { lat: 31.744855229534114, lng: 35.22719924888615 },
        { lat: 31.74254134266106, lng: 35.225383358121434 },
        { lat: 31.7408807553192, lng: 35.224846916318455 },
        { lat: 31.73850843604172, lng: 35.224117355466404 },
        { lat: 31.738271200772257, lng: 35.224095897794285 },
        { lat: 31.73672003211357, lng: 35.22353799831919 },
        { lat: 31.735552076217648, lng: 35.222851352811375 },
        { lat: 31.734841714799913, lng: 35.22246672428487 },
        { lat: 31.73423947716954, lng: 35.22105051792501 },
        { lat: 31.733892732515564, lng: 35.219634311565144 },
        { lat: 31.734805215661027, lng: 35.21729542530416 },
        { lat: 31.73646591192246, lng: 35.21529986179708 },
        { lat: 31.739807163364343, lng: 35.21287314631795 },
        { lat: 31.744415439814084, lng: 35.21067300270865 },
        { lat: 31.746678124453812, lng: 35.21002927254508 },
        { lat: 31.747043068411543, lng: 35.209836153496006 },
        { lat: 31.7472985283262, lng: 35.209600119102696 },
        { lat: 31.74766346983887, lng: 35.2094928307421 },
        { lat: 31.748101934422674, lng: 35.20878941120636 },
        { lat: 31.748184045676002, lng: 35.208451452870484 },
        { lat: 31.748275280316516, lng: 35.20789891781342 },
        { lat: 31.748316335875423, lng: 35.20735711159241 },
        { lat: 31.748316335875423, lng: 35.206927958150025 },
        { lat: 31.748316335875423, lng: 35.20662218632233 },
        { lat: 31.748644779691322, lng: 35.20659536423218 },
        { lat: 31.748585477421855, lng: 35.2060052782489 },
        { lat: 31.74838932349073, lng: 35.205152959125385 },
        { lat: 31.748238786471088, lng: 35.20473989893709 },
        { lat: 31.74805175528758, lng: 35.20426783015047 },
        { lat: 31.747851038475257, lng: 35.20401033808504 },
        { lat: 31.747709624096235, lng: 35.20375284601961 },
        { lat: 31.747458727085828, lng: 35.20358118464266 },
        { lat: 31.74723520008576, lng: 35.20323786188875 },
        { lat: 31.747031065695605, lng: 35.20315874323212 },
        { lat: 31.746729986995277, lng: 35.202869064658515 },
        { lat: 31.746707177962918, lng: 35.2026866744455 },
        { lat: 31.74667068349949, lng: 35.20252037748658 },
        { lat: 31.74665243626239, lng: 35.20225752100312 },
        { lat: 31.746666121690573, lng: 35.201860554068915 },
        { lat: 31.7466615598814, lng: 35.20127046808564 },
        { lat: 31.746647874452556, lng: 35.20104819477268 },
        { lat: 31.7467619196311, lng: 35.20058685482212 },
        { lat: 31.746857717472555, lng: 35.20013624370762 },
        { lat: 31.747072121806173, lng: 35.19951933563419 },
        { lat: 31.74733216261328, lng: 35.1990579864489 },
        { lat: 31.747687980476435, lng: 35.19875757903923 },
        { lat: 31.748208019509242, lng: 35.19831769676079 },
        { lat: 31.74903626020781, lng: 35.19786802219061 },
        { lat: 31.749747881470125, lng: 35.19736376689581 },
        { lat: 31.750083260598885, lng: 35.19689049065249 },
        { lat: 31.750229232986392, lng: 35.196332591177395 },
        { lat: 31.749992027739832, lng: 35.19553865730899 },
        { lat: 31.748897226419256, lng: 35.19440140068667 },
        { lat: 31.74776591812202, lng: 35.19292082131045 },
        { lat: 31.74696304578389, lng: 35.192620413900784 },
        { lat: 31.746068929760057, lng: 35.19195522606509 },
        { lat: 31.74586820864979, lng: 35.19101108849185 },
        { lat: 31.745995940315748, lng: 35.19038881600039 },
        { lat: 31.74639738154752, lng: 35.18974508583682 },
        { lat: 31.746561607004384, lng: 35.18915816199825 },
        { lat: 31.747017787300813, lng: 35.188321312785604 },
        { lat: 31.747473965349965, lng: 35.187763413310506 },
        { lat: 31.74765643594039, lng: 35.18709822547481 },
        { lat: 31.747702053531796, lng: 35.18660469901607 },
        { lat: 31.747629065374745, lng: 35.186272105098226 },
        { lat: 31.74720938235518, lng: 35.185553273082235 },
        { lat: 31.746671090480472, lng: 35.1850061024432 },
        { lat: 31.745895579735894, lng: 35.1843945587878 },
        { lat: 31.743879221405592, lng: 35.182334622264364 },
        { lat: 31.743112811249578, lng: 35.18065019500301 },
        { lat: 31.74291208373207, lng: 35.17944856536434 },
        { lat: 31.742793471812703, lng: 35.178525885463216 },
        { lat: 31.742528875445135, lng: 35.177292069316366 },
        { lat: 31.74223690616229, lng: 35.17664833915279 },
        { lat: 31.741725957702556, lng: 35.17562909972713 },
        { lat: 31.741379241070977, lng: 35.175049742579915 },
        { lat: 31.741315372076215, lng: 35.17442747008846 },
        { lat: 31.741269751338706, lng: 35.173043450236776 },
        { lat: 31.741351868650025, lng: 35.17123027694271 },
        { lat: 31.74145223415389, lng: 35.17064019095943 },
        { lat: 31.74143398588855, lng: 35.16965313804195 },
        { lat: 31.741479606545166, lng: 35.16821547400997 },
        { lat: 31.741516103054273, lng: 35.167292794108846 },
        { lat: 31.74160734426411, lng: 35.16643448722408 },
        { lat: 31.74189019144344, lng: 35.16512556922481 },
        { lat: 31.742100045244122, lng: 35.164041956782796 },
        { lat: 31.742428511107978, lng: 35.16295834434078 },
        { lat: 31.742738727798667, lng: 35.16266866576717 },
        { lat: 31.743094563311427, lng: 35.16240044486568 },
        { lat: 31.744387242132042, lng: 35.16126318824337 },
        { lat: 31.744615337916798, lng: 35.16102715385006 },
        { lat: 31.744788690337497, lng: 35.160050829768636 },
        { lat: 31.744752195117993, lng: 35.15923543822811 },
        { lat: 31.744569718804783, lng: 35.158570250392415 },
        { lat: 31.744086154835923, lng: 35.158065995097616 },
        { lat: 31.743328870339745, lng: 35.157304247737386 },
        { lat: 31.742279610503083, lng: 35.1564781273608 },
        { lat: 31.741768662278915, lng: 35.15615626227901 },
        { lat: 31.741595304205305, lng: 35.15595241439388 },
        { lat: 31.74077412997412, lng: 35.155566176295736 },
        { lat: 31.740044191212178, lng: 35.15516920936153 },
        { lat: 31.739305122355006, lng: 35.15494390380428 },
        { lat: 31.738657291716727, lng: 35.154343088984945 },
        { lat: 31.738018512203787, lng: 35.15382161900407 },
        { lat: 31.73713343438392, lng: 35.15229812428361 },
        { lat: 31.736960067634953, lng: 35.151922615021526 },
        { lat: 31.736896195593022, lng: 35.15149346157914 },
        { lat: 31.73664983159019, lng: 35.15102139279252 },
        { lat: 31.736286553808874, lng: 35.14987836326569 },
        { lat: 31.73573907375432, lng: 35.148290495528876 },
        { lat: 31.73502734484634, lng: 35.14762530769318 },
        { lat: 31.734145354248614, lng: 35.146534891586775 },
        { lat: 31.733269364362425, lng: 35.14566585586595 },
        { lat: 31.732466366357496, lng: 35.144764633636946 },
        { lat: 31.731791112648395, lng: 35.14415308998155 },
        { lat: 31.73128923170358, lng: 35.143799038391585 },
        { lat: 31.730468556409598, lng: 35.143070171573484 },
        { lat: 31.729838914530312, lng: 35.14236206839355 },
        { lat: 31.72959253176073, lng: 35.14190072844299 },
        { lat: 31.72916364167315, lng: 35.141321371295774 },
        { lat: 31.72879862726901, lng: 35.14018827621513 },
        { lat: 31.728899006373485, lng: 35.13986641113334 },
        { lat: 31.72861611952777, lng: 35.138739883347085 },
        { lat: 31.72864349571188, lng: 35.138031780167154 },
        { lat: 31.72930052170401, lng: 35.136755048676065 },
        { lat: 31.730505717648068, lng: 35.13579116350435 },
        { lat: 31.73284174195104, lng: 35.13613448625826 },
        { lat: 31.73459372152718, lng: 35.13510451799654 },
        { lat: 31.73335273941638, lng: 35.13184295183443 },
        { lat: 31.732038740239958, lng: 35.129954676687944 },
        { lat: 31.731162730436115, lng: 35.12763724809908 },
        { lat: 31.72663654784631, lng: 35.1246331740024 },
        { lat: 31.72203713586411, lng: 35.12180076128267 },
        { lat: 31.720576957345354, lng: 35.12128577715181 },
        { lat: 31.718021589589927, lng: 35.12077079302095 },
        { lat: 31.715247110557655, lng: 35.12025580889009 },
        { lat: 31.713786825085723, lng: 35.11785254961275 },
        { lat: 31.714224913142232, lng: 35.113990168631304 },
        { lat: 31.71440744922153, lng: 35.11158690935396 },
        { lat: 31.71491854833174, lng: 35.109741549551714 },
        { lat: 31.715356631040898, lng: 35.10828242784761 },
        { lat: 31.714955055303218, lng: 35.1061366606357 },
        { lat: 31.713385242548885, lng: 35.10442004686617 },
        { lat: 31.711048727836694, lng: 35.10205970293306 },
        { lat: 31.709369321521674, lng: 35.10030017381929 },
        { lat: 31.70754384537906, lng: 35.09854064470552 },
        { lat: 31.705069302899364, lng: 35.09687654086999 },
        { lat: 31.703316765240587, lng: 35.09537450382165 },
        { lat: 31.70130860846527, lng: 35.09365789005212 },
        { lat: 31.700578358859374, lng: 35.09335748264245 },
        { lat: 31.699263895082716, lng: 35.09099713870935 },
        { lat: 31.697547761566472, lng: 35.08842221805505 },
        { lat: 31.69561250909451, lng: 35.08537522861413 },
        { lat: 31.693604185548995, lng: 35.08425942966394 },
        { lat: 31.690646393545222, lng: 35.08524648258142 },
    ],
};
